<div
  id="div-_container-1"
  class="_container _hidden"
  [class._fullscreen]="isFullScreen$ | async"
  [class._visible]="isVisible$ | async"
>
	<div class="w-full h-full flex flex-col justify-center items-center">
	  <svg class="animate-pulse" height="200" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
	    <g clip-path="url(#clip0_510_56811)">
	      <path class="dark:fill-white" d="M23.2581 8.00781H17.4238V9.7895H23.2581C25.4978 9.7895 27.3124 11.6041 27.3124 13.8438C27.3124 16.0835 25.4978 17.8982 23.2581 17.8982H17.4238V23.4583C17.4238 23.9337 18.0033 24.1679 18.3346 23.8261L22.3334 19.6781H23.2581C26.4797 19.6781 29.0924 17.0654 29.0924 13.8438C29.0924 10.6205 26.4815 8.00781 23.2581 8.00781Z" fill="#1C274C" />
	      <path d="M15.5 8H9.50089C6.18649 8 3.5 10.6869 3.5 14C3.5 17.3131 6.18649 20 9.49911 20H15.4982V8H15.5Z" fill="#3970FF" />
	    </g>
	    <defs>
	      <clipPath id="clip0_510_56811">
	        <rect width="27" height="17.0985" fill="white" transform="translate(2.5 7.44922)"/>
	      </clipPath>
	    </defs>
	  </svg>
	  <p class="text-2xl font-bold text-gray-900 dark:text-white">{{ message$ | async }}</p>
	</div>
</div>
