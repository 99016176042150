<div class="main-request-offer-history-discussion-messages">
	<div class="absolute flex flex-col overflow-y-auto h-full w-full px-6">
		@for (message of room().messages; track $index) {
			<app-main-request-offer-history-discussion-message [message]="message" />
		}

		@empty {
			<div class="flex flex-col justify-center items-center h-full gap-3">
				<app-mhp-svg-icon icon="empty-chat" />
				<p class="text-gray-400">There are currently no comments</p>
			</div>
		}
	</div>
</div>

<hr class="-mx-6 border-gray-200 dark:border-gray-700" />

<div class="main-request-offer-history-discussion-input">
	<img
		class="size-10 rounded-full"
		[src]="this.getGravatarUrl(this.otherParticipant()?.displayName!)"
		[alt]="this.otherParticipant()?.displayName"
		inert
	/>

	<div class="message-field">
		<input
			class="message-input"
			placeholder="Enter a comment..."
			type="text"
			[disabled]="false"
			[(ngModel)]="this.draftMessage"
			(keydown.enter)="this.sendMessage()"
		/>

		<button
			class="send-button"
			[disabled]="!this.draftMessage() || this.draftMessage()!.length < 0"
			(click)="this.sendMessage()"
		>
			<ng-icon class="text-blue-600 hover:text-blue-700" name="saxSend2Bold" />
		</button>
	</div>
</div>
