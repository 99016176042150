import { Component, computed, effect, inject, signal } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { bootstrapPencilSquare } from '@ng-icons/bootstrap-icons';
import { saxInfoCircleOutline, saxTrashOutline } from '@ng-icons/iconsax/outline';

import { SITEMAP } from 'src/app/_common/sitemap';
import { Company } from 'src/app/_common/models/company.model';
import { ExporterStock } from 'src/app/_common/models/exporter-stock.model';
import { Package } from '../../_common/models/package.model';
import { User } from 'src/app/_common/models/user.model';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { ApiCompanyService } from 'src/app/_common/services/api/api-company/api-company.service';
import { ApiExporterService } from 'src/app/_common/services/api/api-exporter/api-exporter.service';
import { ApiPackageService } from '../../_common/services/api/api-package/api-package.service';
import { MhpButtonComponent } from '../../_common/components/mhp-button/mhp-button.component';
import { MhpSvgIconComponent } from '../../_common/components/mhp-svg-icon/mhp-svg-icon.component';
import { MhpTabsComponent } from '../../_common/components/mhp-tabs/mhp-tabs.component';
import { MainStockOfferEnumTabs } from 'src/app/_common/enums/main-stock-offer-tabs.enum';
import { MHPTab } from 'src/app/_common/components/mhp-tabs/mhp-tab.interface';
import { UserFunctionEnum } from 'src/app/_common/enums/user-function.enum';
import { MhpPackageComponent } from "../../_common/components/mhp-package/mhp-package.component";
import { derivedAsync } from "ngxtension/derived-async";
import { CompanyService } from "../../_common/services/company/company.service";
import { ExporterQuota } from "../../_common/models/exporter-quota.model";
import { ExporterStockEntry } from "../../_common/models/exporter-stock-entry.model";
import { sortByLocale } from "../../_common/utils/sorting.util";

@Component({
	selector: 'app-main-stock-offer',
	standalone: true,
	imports: [
		RouterModule,
		CommonModule,
		MhpButtonComponent,
		MhpSvgIconComponent,
		CurrencyPipe,
		MhpTabsComponent,
		MhpPackageComponent,
	],
	providers: [
		provideIcons({
			bootstrapPencilSquare,
			saxInfoCircleOutline,
			saxTrashOutline,
		}),
		provideNgIconsConfig({ size: '1.5rem' }),
	],
	templateUrl: './main-stock-offer.component.html',
	styleUrls: ['./main-stock-offer.component.scss'],
})
export class MainStockOfferComponent {
	private readonly _accountSvc = inject(AccountService);
	private _companySvc = inject(CompanyService);
	private readonly _apiCompanySvc = inject(ApiCompanyService);
	private readonly _apiExporterSvc = inject(ApiExporterService);
	private readonly _apiPackageSvc = inject(ApiPackageService);

	public readonly sitemap = SITEMAP;

	public readonly tabs: MHPTab<MainStockOfferEnumTabs>[] = [
		{ text: 'All', value: MainStockOfferEnumTabs.All },
		{ text: 'My stock', value: MainStockOfferEnumTabs.MyStock },
		{ text: 'My quotas', value: MainStockOfferEnumTabs.MyQuotas },
	];

	public readonly user = computed<User | null>(this._accountSvc.user);

	public readonly stockQuotas = derivedAsync<ExporterQuota[]>(async () => {
		const exporterId = this._companySvc.currentCompany()?.id;

		if (!exporterId) {
			return [];
		}

		return (await this._apiExporterSvc.getQuotas(exporterId)).body || [];
	})

	public readonly stockItems = derivedAsync<ExporterStockEntry[]>(async () => {
		const exporterId = this._companySvc.currentCompany()?.id;

		if (!exporterId) {
			return [];
		}

		return (await this._apiExporterSvc.getStock(exporterId)).body || [];
	})

	public mergedStockItems = computed<ExporterStockEntry[]>(() => {
		const stockItems = this.stockItems() ?? [];
		const stockQuotas = this.stockQuotas() ?? [];

		return [
			...stockItems.map(item => {
				item.quota = stockQuotas.find(q => q.packageCip13 === item.packageCip13)?.quota || null;
				return item;
			}),
			...stockQuotas.filter(q => !stockItems
				.some(i => i.packageCip13 === q.packageCip13))
				.map(q => q as any as ExporterStockEntry) // Short-circuit mapping: All props are named the same between stock & quota.
		].sort((a, b) => sortByLocale(a.packageShortName, b.packageShortName));
	});

	public tabSelected = signal(this.tabs[0]);

	public readonly quotasVisible = computed<boolean>(() => {
		return this.tabSelected() == this.tabs[0] || this.tabSelected() == this.tabs[2];
	});

	public readonly stocksVisible = computed<boolean>(() => {
		return this.tabSelected() == this.tabs[0] || this.tabSelected() == this.tabs[1];
	});


	public viewItems = computed<ExporterStockEntry[]>(() => {
		const items = this.mergedStockItems();

		if (!this.quotasVisible()) {
			return items.filter(i => i.quantity);
		}

		if (!this.stocksVisible()) {
			return items.filter(i => i.quota);
		}

		return items;
	})

	public userFunctions = UserFunctionEnum;

	constructor() {

	}
}
