import { Observable } from 'rxjs';
import { ChatMessage } from '../../models/chat-message.model';

/**
 * This class is used to dispatch chat events to the chat components
 */
export class ChatRoomEventDispatcher {
	messageHistory: ChatMessage[] = [];
	newRoomMessage$: Observable<ChatMessage>;
	updatedRoomMessage$: Observable<ChatMessage>;
	deletedRoomMessage$: Observable<ChatMessage>;
	userRoomWriting$: Observable<ChatMessage>; // todo: replace with a (User, Room) tuple
	constructor(
		newMessage$: Observable<ChatMessage>,
		updatedMessage$: Observable<ChatMessage>,
		deletedMessage$: Observable<ChatMessage>,
		userWriting$: Observable<ChatMessage>,
		messageHistory: ChatMessage[]
	) {
		this.newRoomMessage$ = newMessage$;
		this.updatedRoomMessage$ = updatedMessage$;
		this.deletedRoomMessage$ = deletedMessage$;
		this.userRoomWriting$ = userWriting$;
		this.messageHistory = messageHistory;
	}
}
