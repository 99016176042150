import { Company } from '../models/company.model';

export namespace CompanyExtension {
	/**
	 * Get the exporter by ID
	 */
	export function getExporterById(exporters: Company[], id: number): Company | undefined {
		return exporters.find(exporter => exporter.id === id);
	}
}
