import { Component, computed, inject, signal } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import {
	saxArrowDown1Outline,
	saxArrowUp2Outline,
	saxBuildingsOutline,
	saxMoreOutline,
} from '@ng-icons/iconsax/outline';

import { SITEMAP } from 'src/app/_common/sitemap';
import { Company } from 'src/app/_common/models/company.model';
import { ApiPackageService } from '../../../_common/services/api/api-package/api-package.service';
import { Package } from '../../../_common/models/package.model';
import { CompanyService } from 'src/app/_common/services/company/company.service';

@Component({
	selector: 'app-main-dashboard-products',
	standalone: true,
	imports: [CommonModule, RouterModule],
	providers: [
		provideIcons({
			saxArrowDown1Outline,
			saxArrowUp2Outline,
			saxBuildingsOutline,
			saxMoreOutline,
		}),
		provideNgIconsConfig({ size: '1.25rem' }),
	],
	templateUrl: './main-dashboard-products.component.html',
})
export class MainDashboardProductsComponent {
	private readonly _apiPackageSvc = inject(ApiPackageService);
	private readonly _companySvc = inject(CompanyService);

	public readonly sitemap = SITEMAP;

	public readonly exporters = computed<Company[]>(this._companySvc.allCompanies);
	public readonly products = signal<Package[]>([]);

	public requests: number[] = [...Array(4).keys()];
	public expanses: boolean[] = [];

	constructor() {
		// this.products().map(() => this.expanses.push(false));
		// this._apiPackageSvc.getByCIP13s([ 3400926784102, 3400930151099 ])
		// 	.then(response => {
		// 		this.products.set(response.body!);
		// 	})
		// 	.catch(console.error);
	}
}
