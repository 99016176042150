@switch (this.icon()) {
  @case ('close') {
    <svg class="h-6 w-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="this.class()" d="M4.99805 5L18.998 19" stroke="#3D4754" stroke-width="1.5" stroke-linecap="round"/>
      <path [class]="this.class()" d="M18.998 5L4.99805 19" stroke="#3D4754" stroke-width="1.5" stroke-linecap="round"/>
    </svg>
  }

  @case ('jar-of-pills') {
    <svg width="1.5rem" height="1.5rem" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="this.class()" opacity="0.5" d="M6 3.5C6 3.03406 6 2.80109 6.07612 2.61732C6.17761 2.37229 6.37229 2.17761 6.61732 2.07612C6.80109 2 7.03406 2 7.5 2H16.5C16.9659 2 17.1989 2 17.3827 2.07612C17.6277 2.17761 17.8224 2.37229 17.9239 2.61732C18 2.80109 18 3.03406 18 3.5C18 3.96594 18 4.19891 17.9239 4.38268C17.8224 4.62771 17.6277 4.82239 17.3827 4.92388C17.1989 5 16.9659 5 16.5 5H7.5C7.03406 5 6.80109 5 6.61732 4.92388C6.37229 4.82239 6.17761 4.62771 6.07612 4.38268C6 4.19891 6 3.96594 6 3.5Z" fill="black"/>
      <path [class]="this.class()" fill-rule="evenodd" clip-rule="evenodd" d="M12 11.25C12.4142 11.25 12.75 11.5858 12.75 12V13.25H14C14.4142 13.25 14.75 13.5858 14.75 14C14.75 14.4142 14.4142 14.75 14 14.75H12.75V16C12.75 16.4142 12.4142 16.75 12 16.75C11.5858 16.75 11.25 16.4142 11.25 16V14.75H10C9.58579 14.75 9.25 14.4142 9.25 14C9.25 13.5858 9.58579 13.25 10 13.25H11.25V12C11.25 11.5858 11.5858 11.25 12 11.25Z" fill="black"/>
      <path [class]="this.class()" opacity="0.5" d="M15.8782 15.6671L13.6671 17.8782C12.7513 18.794 12.7822 20.3098 13.7362 21.2638C14.6902 22.2178 16.206 22.2487 17.1218 21.3329L19.3329 19.1218C20.2487 18.206 20.2178 16.6902 19.2638 15.7362C18.3098 14.7822 16.794 14.7513 15.8782 15.6671Z" fill="black"/>
      <path [class]="this.class()" d="M9.00021 5L5.50143 7.79902C4.79757 8.36211 4.31189 9.13989 4.108 10H19.8958C19.6956 9.14182 19.2152 8.3645 18.517 7.79931L16.1597 5.89102L15.0184 5H9.00021Z" fill="black"/>
      <path [class]="this.class()" d="M4.92732 20.1125C4.42455 19.5092 4.11265 18.774 4.02539 18H11.8042C11.2442 19.3096 11.4686 20.8608 12.3847 22H8.95713C8.38603 22 8.10047 22 7.82977 21.9628C7.02682 21.8526 6.27629 21.5011 5.67759 20.9548C5.47575 20.7706 5.29293 20.5513 4.92732 20.1125Z" fill="black"/>
      <path [class]="this.class()" opacity="0.5" d="M4 17.5518V10.9225C4 10.6087 4.03683 10.2994 4.10779 10H19.8955C19.9643 10.295 20 10.5995 20 10.9083V14.3842C18.4889 13.1693 16.2529 13.1712 14.8177 14.6064L12.6067 16.8175C12.2534 17.1707 11.987 17.5725 11.8042 18H4.02518C4.00847 17.8518 4 17.7022 4 17.5518Z" fill="black"/>
      <path [class]="this.class()" d="M18.8208 19.6339C18.7777 19.5243 18.7225 19.3974 18.6525 19.2566C18.4263 18.8018 18.0462 18.2044 17.4208 17.5791C16.7955 16.9537 16.198 16.5736 15.7432 16.3473C15.6025 16.2773 15.4758 16.2222 15.3663 16.1791L14.1401 17.4052L14.6094 17.5048L14.6207 17.5078C14.636 17.5119 14.6646 17.5202 14.705 17.5338C14.7858 17.5611 14.9133 17.6098 15.0751 17.6903C15.3978 17.8508 15.8595 18.139 16.3602 18.6397C16.8608 19.1404 17.149 19.602 17.3095 19.9247C17.3899 20.0864 17.4386 20.2139 17.4659 20.2947C17.4795 20.335 17.4878 20.3637 17.4919 20.3789L17.4948 20.3902L17.5946 20.8602L18.8208 19.6339Z" fill="black"/>
    </svg>
  }

  @case ('from-to') {
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame 27">
        <path id="Vector 1" d="M28.3536 19.6464C28.5488 19.8417 28.5488 20.1583 28.3536 20.3536L25.1716 23.5355C24.9763 23.7308 24.6597 23.7308 24.4645 23.5355C24.2692 23.3403 24.2692 23.0237 24.4645 22.8284L27.2929 20L24.4645 17.1716C24.2692 16.9763 24.2692 16.6597 24.4645 16.4645C24.6597 16.2692 24.9763 16.2692 25.1716 16.4645L28.3536 19.6464ZM11 20.5C10.7239 20.5 10.5 20.2761 10.5 20C10.5 19.7239 10.7239 19.5 11 19.5V20.5ZM26.9375 20.5C26.6614 20.5 26.4375 20.2761 26.4375 20C26.4375 19.7239 26.6614 19.5 26.9375 19.5V20.5ZM24.8125 19.5C25.0886 19.5 25.3125 19.7239 25.3125 20C25.3125 20.2761 25.0886 20.5 24.8125 20.5V19.5ZM22.6875 20.5C22.4114 20.5 22.1875 20.2761 22.1875 20C22.1875 19.7239 22.4114 19.5 22.6875 19.5V20.5ZM20.5625 19.5C20.8386 19.5 21.0625 19.7239 21.0625 20C21.0625 20.2761 20.8386 20.5 20.5625 20.5V19.5ZM18.4375 20.5C18.1614 20.5 17.9375 20.2761 17.9375 20C17.9375 19.7239 18.1614 19.5 18.4375 19.5V20.5ZM16.3125 19.5C16.5886 19.5 16.8125 19.7239 16.8125 20C16.8125 20.2761 16.5886 20.5 16.3125 20.5V19.5ZM14.1875 20.5C13.9114 20.5 13.6875 20.2761 13.6875 20C13.6875 19.7239 13.9114 19.5 14.1875 19.5V20.5ZM12.0625 19.5C12.3386 19.5 12.5625 19.7239 12.5625 20C12.5625 20.2761 12.3386 20.5 12.0625 20.5V19.5ZM28 20.5H26.9375V19.5H28V20.5ZM24.8125 20.5H22.6875V19.5H24.8125V20.5ZM20.5625 20.5H18.4375V19.5H20.5625V20.5ZM16.3125 20.5H14.1875V19.5H16.3125V20.5ZM12.0625 20.5H11V19.5H12.0625V20.5Z" fill="#696F96"/>
      </g>
    </svg>
  }

  @case('spinner') {
    <svg class="animate-spin mr-4 h-5 w-5 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  }

  @case('pill') {
    <svg [style]="{ height: this.size()+'px', width: this.size()+'px' }" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path class="fill-gray-900 dark:fill-white" fill-rule="evenodd" clip-rule="evenodd" d="M14.1322 3.46024C11.1852 0.513254 6.40722 0.513254 3.46024 3.46024C0.513254 6.40722 0.513254 11.1852 3.46024 14.1322L9.86778 20.5398C12.8148 23.4867 17.5928 23.4867 20.5398 20.5398C23.4867 17.5928 23.4867 12.8148 20.5398 9.86778L14.1322 3.46024ZM4.5209 4.5209C6.8821 2.1597 10.7104 2.1597 13.0716 4.5209L15.9689 7.41825C15.9653 7.42974 15.9616 7.44148 15.9578 7.45347C15.8679 7.73472 15.7151 8.15053 15.468 8.66312C14.9744 9.68744 14.1032 11.101 12.6019 12.6023C11.1005 14.1037 9.68709 14.9747 8.66292 15.4683C8.1504 15.7153 7.73465 15.868 7.45346 15.9578C7.44149 15.9616 7.42977 15.9654 7.41829 15.9689L4.5209 13.0716C2.1597 10.7104 2.1597 6.8821 4.5209 4.5209ZM8.58965 17.1403L10.9284 19.4791C13.2896 21.8403 17.1179 21.8403 19.4791 19.4791C21.8403 17.1179 21.8403 13.2896 19.4791 10.9284L17.1402 8.58958C17.0521 8.80835 16.9461 9.05118 16.8193 9.31437C16.2594 10.4761 15.2939 12.0316 13.6625 13.663C12.0312 15.2943 10.4757 16.2598 9.31411 16.8195C9.05105 16.9463 8.80833 17.0522 8.58965 17.1403Z" />
    </svg>
  }

  @case('void-circle') {
    <svg width="1.75rem" height="1.75rem" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="vuesax/linear/tick-circle">
        <g id="tick-circle">
          <path
            class="stroke-blue-600"
            id="Vector"
            d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </g>
    </svg>
  }

	@case('file') {
		<svg width="2.75rem" height="3.5rem" viewBox="0 0 44 56" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g id="file">
				<path
					class="fill-[url(#paint0_linear_3308_104356)] dark:fill-[#54c7e340]"
					id="Vector"
					d="M40.5816 55.1122H3.0051C2.3407 55.1122 1.70352 54.8482 1.23373 54.3784C0.763929 53.9086 0.5 53.2715 0.5 52.6071V2.5051C0.5 1.8407 0.763929 1.20352 1.23373 0.733726C1.70352 0.263929 2.3407 0 3.0051 0H35.5714V5.0102C35.5714 5.67459 35.8353 6.31177 36.3051 6.78157C36.7749 7.25137 37.4121 7.51529 38.0765 7.51529H43.0867V52.6071C43.0867 53.2715 42.8227 53.9086 42.3529 54.3784C41.8831 54.8482 41.246 55.1122 40.5816 55.1122Z"
				/>
				<path
					class="fill-[#54c7e3] dark:fill-[url(#paint0_linear_3308_104356)]"
					id="Vector_2"
					d="M32.7916 49.0655C31.5675 49.0655 30.4166 48.5621 29.551 47.648C29.0027 47.0691 28.2737 46.7502 27.4982 46.7502C26.7228 46.7502 25.9938 47.0691 25.4455 47.648C25.1175 47.9945 24.5856 47.9944 24.2577 47.6481C23.9297 47.3018 23.9297 46.7402 24.2577 46.394C25.1233 45.4799 26.2742 44.9766 27.4984 44.9766C28.7225 44.9766 29.8734 45.4799 30.739 46.394C31.2873 46.9729 32.0163 47.2919 32.7917 47.2919C33.5672 47.2919 34.2963 46.9729 34.8445 46.3939C35.1726 46.0476 35.7044 46.0476 36.0324 46.3939C36.3604 46.7403 36.3604 47.3018 36.0324 47.6481C35.1667 48.5621 34.0158 49.0655 32.7916 49.0655Z"
				/>
				<path
					class="fill-[#54c7e3] dark:fill-[url(#paint0_linear_3308_104356)]"
					id="Vector_3"
					d="M43.0739 7.51529H38.0637C37.3993 7.51529 36.7621 7.25137 36.2923 6.78157C35.8225 6.31177 35.5586 5.67459 35.5586 5.0102V0L43.0739 7.51529Z"
				/>
				<path
					class="fill-[#54c7e3] dark:fill-[url(#paint0_linear_3308_104356)]"
					id="Vector_4"
					d="M20.5425 22.5442H10.5221C10.1899 22.5442 9.87129 22.4122 9.63639 22.1773C9.4015 21.9424 9.26953 21.6238 9.26953 21.2916C9.26953 20.9594 9.4015 20.6408 9.63639 20.4059C9.87129 20.171 10.1899 20.0391 10.5221 20.0391H20.5425C20.8747 20.0391 21.1933 20.171 21.4282 20.4059C21.6631 20.6408 21.795 20.9594 21.795 21.2916C21.795 21.6238 21.6631 21.9424 21.4282 22.1773C21.1933 22.4122 20.8747 22.5442 20.5425 22.5442Z"
				/>
				<path
					class="fill-[#54c7e3] dark:fill-[url(#paint0_linear_3308_104356)]"
					id="Vector_5"
					d="M33.068 28.8098H10.5221C10.1899 28.8098 9.87129 28.6778 9.63639 28.4429C9.4015 28.208 9.26953 27.8894 9.26953 27.5572C9.26953 27.225 9.4015 26.9064 9.63639 26.6715C9.87129 26.4367 10.1899 26.3047 10.5221 26.3047H33.068C33.4002 26.3047 33.7188 26.4367 33.9536 26.6715C34.1885 26.9064 34.3205 27.225 34.3205 27.5572C34.3205 27.8894 34.1885 28.208 33.9536 28.4429C33.7188 28.6778 33.4002 28.8098 33.068 28.8098Z"
				/>
				<path
					class="fill-[#54c7e3] dark:fill-[url(#paint0_linear_3308_104356)]"
					id="Vector_6"
					d="M29.3103 35.0715H10.5221C10.1899 35.0715 9.87129 34.9395 9.63639 34.7046C9.4015 34.4697 9.26953 34.1512 9.26953 33.819C9.26953 33.4868 9.4015 33.1682 9.63639 32.9333C9.87129 32.6984 10.1899 32.5664 10.5221 32.5664H29.3103C29.6425 32.5664 29.9611 32.6984 30.196 32.9333C30.4309 33.1682 30.5629 33.4868 30.5629 33.819C30.5629 34.1512 30.4309 34.4697 30.196 34.7046C29.9611 34.9395 29.6425 35.0715 29.3103 35.0715Z"
				/>
			</g>
			<defs>
				<linearGradient id="paint0_linear_3308_104356" x1="7" y1="5.58003e-07" x2="36.2778" y2="55.2" gradientUnits="userSpaceOnUse">
					<stop offset="0" stop-color="#CBF4FE" />
					<stop offset="1" stop-color="#B5E6F3"/>
				</linearGradient>
			</defs>
		</svg>
	}

	@case	('search') {
		<svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g id="search" clip-path="url(#clip0_3314_92638)">
				<path id="Vector" d="M30.3984 32.7697L31.6271 33.9989L33.0637 35.4279L33.6172 35.9854L36.4834 33.1172L33.2696 29.8984L30.3984 32.7697Z" fill="#3263DF" />
				<path
					id="Vector_2"
					d="M39.4101 33.0938L49.3011 42.9843C50.0451 43.7263 50.5068 44.7562 50.5038 45.8891C50.5038 47.0238 50.045 48.0528 49.3011 48.7979C48.5591 49.5389 47.5315 49.9961 46.3968 49.9961C45.2625 49.9961 44.236 49.5389 43.492 48.7953L33.6016 38.9038L39.4101 33.0938Z"
					fill="url(#paint0_linear_3314_92638)"
				/>
				<g id="Group">
					<path
						id="Vector_3"
						d="M33.5994 38.909L39.4084 33.0984L38.9752 32.6642C38.7137 32.4017 38.3562 32.2422 37.9587 32.2422C37.5587 32.2422 37.2002 32.401 36.9412 32.6622L33.1637 36.4417C32.9042 36.6992 32.7422 37.0577 32.7422 37.4582C32.7422 37.8527 32.9039 38.2157 33.1637 38.4717L33.5994 38.909Z"
						fill="#3971FF"
					/>
					<path
						id="Vector_4"
						d="M0.5 18.5702C0.5 8.31572 8.81475 0 19.0713 0C29.3288 0 37.6431 8.31621 37.6431 18.5702C37.6431 28.8277 29.3278 37.142 19.0713 37.1444C8.81475 37.142 0.5 28.8274 0.5 18.5702Z"
						fill="#5786FF"
					/>
				</g>
				<path
					id="Vector_5"
					d="M19.0691 35.1293C28.2132 35.1293 35.6299 27.7153 35.6299 18.5671C35.6299 9.42412 28.2134 2.00781 19.0691 2.00781C9.92363 2.00801 2.50781 9.42383 2.50781 18.5673C2.50781 27.7153 9.92363 35.1293 19.0691 35.1293Z"
					fill="url(#paint1_linear_3314_92638)"
				/>
				<g id="Group_2">
					<path
						id="Vector_6"
						d="M19.474 5.83461C19.2145 5.44965 18.7685 5.20864 18.2777 5.23657C16.1747 5.3643 14.1872 5.95659 12.371 7.01108C11.7315 7.3851 11.5122 8.20932 11.8848 8.8518C12.2572 9.48627 13.0825 9.71206 13.72 9.33452C15.1687 8.49878 16.7545 8.02397 18.4355 7.92153C19.174 7.88305 19.7421 7.24604 19.6963 6.50805C19.6878 6.25229 19.602 6.02231 19.474 5.83461Z"
						fill="#E0E9FF"
					/>
					<path
						id="Vector_7"
						d="M10.8446 10.25C10.7731 10.1428 10.6799 10.0433 10.5776 9.95852C10.0009 9.48674 9.15518 9.57854 8.68887 10.1548C7.31641 11.8398 6.37188 13.8614 5.95489 16.0128C5.46163 18.5681 5.71963 21.1843 6.69942 23.5816C6.98497 24.2726 7.7709 24.5971 8.45645 24.3146C9.14395 24.0361 9.47149 23.2531 9.18995 22.562C8.4087 20.6503 8.20596 18.5625 8.60274 16.5231C8.92725 14.8056 9.68174 13.1931 10.7765 11.8484C11.1571 11.3802 11.1672 10.7233 10.8446 10.25Z"
						fill="#E0E9FF"
					/>
				</g>
			</g>
			<defs>
				<linearGradient id="paint0_linear_3314_92638" x1="34.5" y1="39" x2="51.5" y2="47" gradientUnits="userSpaceOnUse">
					<stop stop-color="#3971FF"/>
					<stop offset="1" stop-color="#3263DF"/>
				</linearGradient>
				<linearGradient id="paint1_linear_3314_92638" x1="19.0688" y1="2.00781" x2="19.0688" y2="35.1293" gradientUnits="userSpaceOnUse">
					<stop stop-color="#C8D7FE"/>
					<stop offset="1" stop-color="#ACC4FE"/>
				</linearGradient>
				<clipPath id="clip0_3314_92638">
					<rect width="50" height="50" fill="white" transform="translate(0.5)"/>
				</clipPath>
			</defs>
		</svg>
	}

	@case	('cardboardBox') {
		<svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M59 42.8149V17.2827C58.9991 16.4987 58.5263 15.7921 57.8021 15.4924L31.6168 4.65547C31.1422 4.45859 30.6078 4.45859 30.1332 4.65547L3.94795 15.4924C3.22373 15.7921 2.75088 16.4987 2.75 17.2827V42.8149C2.75 43.5998 3.22285 44.3073 3.94795 44.607L30.1332 55.4422C30.6078 55.6391 31.1422 55.6391 31.6168 55.4422L57.8021 44.607C58.5271 44.3073 59 43.5998 59 42.8149Z"
				class="fill-[#92dbed] dark:fill-[#54c7e330]"
			/>
			<path
				d="M3.125 16.1358L30.8721 27.6152L58.6191 16.1358C58.4108 15.8458 58.1252 15.6199 57.7947 15.4854L31.6095 4.65293C31.1393 4.44902 30.6049 4.44902 30.1347 4.65293L3.94941 15.4863C3.61895 15.6208 3.3333 15.8458 3.125 16.1358Z"
				class="fill-[#cbf4fe] dark:fill-[#54c7e360]"
			/>
			<path
				d="M30.875 27.6183V55.5912C31.129 55.5886 31.3795 55.5358 31.6124 55.4356L57.7977 44.6031C58.5245 44.3034 58.9991 43.595 59 42.8084V17.2832C58.9991 16.8719 58.8673 16.4711 58.6221 16.1406L30.875 27.6183Z"
				class="fill-[#b5e6f3] dark:fill-[#54c7e340]"
			/>
			<path
				d="M14.4688 11.1363L40.4308 23.6757L47.3267 20.8148L21.3734 8.28516L14.4688 11.1363Z"
				class="fill-[#54c7e3] dark:fill-[#b5e6f3]"
			/>
			<path
				d="M38.9727 22.9749L40.4176 23.6728H40.4272L47.3231 20.8119L46.0329 20.1914C43.637 21.1608 41.184 22.1312 38.9727 22.9749Z"
				class="fill-[#1d92af] dark:fill-[#92dbed]"
			/>
			<path
				d="M40.5723 23.6036V34.8765C40.5696 35.4108 41.0003 35.845 41.5347 35.8477C41.6692 35.8485 41.8019 35.8213 41.9249 35.7677L46.7738 33.6899C47.1307 33.5379 47.3627 33.1872 47.3618 32.7987V20.8008L40.5723 23.6036Z"
				class="fill-[#1d92af] dark:fill-[#92dbed]"
			/>
			<path
				d="M40.5723 23.6036V24.922C42.6966 23.855 45.0433 22.7977 47.3618 21.8186V20.8008L40.5723 23.6036Z"
				class="fill-[#54c7e3] dark:fill-[#92dbed]"
			/>
			<path
				d="M36.695 45.0756C36.818 45.0756 36.9402 45.0528 37.0545 45.0062L41.9034 43.0664C42.4009 42.8678 42.6434 42.3035 42.4439 41.8061C42.2453 41.3086 41.681 41.066 41.1836 41.2647L36.3346 43.2044C35.8354 43.3925 35.5832 43.9506 35.7713 44.4498C35.9154 44.833 36.2854 45.0835 36.695 45.0756Z"
				class="fill-[#1d92af] dark:fill-[#92dbed]"
			/>
			<path
				d="M36.6927 49.9287C36.8157 49.9287 36.9379 49.9058 37.0521 49.8592L46.7509 45.9797C47.2483 45.7811 47.49 45.2169 47.2914 44.7194C47.0928 44.2219 46.5285 43.9802 46.031 44.1789L36.3323 48.0584C35.8331 48.2482 35.5826 48.8063 35.7724 49.3055C35.9175 49.687 36.2848 49.9357 36.6927 49.9287Z"
				class="fill-[#1d92af] dark:fill-[#92dbed]"
			/>
		</svg>
	}

	@case	('pieChart') {
		<svg width="53" height="49" viewBox="0 0 53 49" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g id="Group">
				<path id="Vector" d="M46.3052 25.0622C46.3052 23.0928 46.0581 21.1812 45.5929 19.3565C45.5569 19.2154 45.4112 19.1314 45.2705 19.1692L23.2773 25.0622L25.1238 37.3241L34.7932 45.0083C41.6757 41.026 46.3052 33.5839 46.3052 25.0622Z" fill="url(#paint0_linear_3459_53464)"/>
				<path id="Vector_2" d="M34.5667 4.98993C31.2315 3.11097 27.381 2.03906 23.2802 2.03906L19.0938 15.9204L23.2801 25.0668L34.6639 5.34957C34.7366 5.2236 34.6935 5.06135 34.5667 4.98993Z" fill="url(#paint1_linear_3459_53464)"/>
				<path id="Vector_3" d="M23.2778 2.03906C10.5599 2.03906 0.25 12.3489 0.25 25.0668C0.25 37.7848 10.5599 48.0946 23.2778 48.0946C27.4733 48.0946 31.4063 46.9727 34.7937 45.0128L23.2778 25.0668V2.03906Z" fill="url(#paint2_linear_3459_53464)"/>
				<path id="Vector_4" d="M19.0945 46.0002C16.229 46.0002 13.4858 45.4768 10.9551 44.5204C14.519 46.783 18.7469 48.0934 23.2809 48.0934C27.4763 48.0934 31.4094 46.9715 34.7967 45.0116L32.7705 41.502C28.9496 44.3276 24.2214 46.0002 19.0945 46.0002Z" fill="#3263DF"/>
				<path id="Vector_5" d="M52.2542 13.6325C50.6432 7.87236 46.8486 3.02404 41.8191 0.0363897C41.6939 -0.0379764 41.5317 0.00550541 41.4589 0.131582L30.3914 19.3011C30.2747 19.5031 30.4606 19.7454 30.686 19.6851L52.0688 13.9555C52.2094 13.9179 52.2934 13.7726 52.2542 13.6325Z" fill="url(#paint3_linear_3459_53464)"/>
			</g>
			<defs>
				<linearGradient id="paint0_linear_3459_53464" x1="23.5" y1="25.875" x2="38.5" y2="45" gradientUnits="userSpaceOnUse">
					<stop stop-color="#CCDAFE"/>
					<stop offset="1" stop-color="#A3BDFE"/>
				</linearGradient>
				<linearGradient id="paint1_linear_3459_53464" x1="26.8965" y1="2.03906" x2="26.8965" y2="25.0668" gradientUnits="userSpaceOnUse">
					<stop stop-color="#E0E9FF"/>
					<stop offset="1" stop-color="#CDDBFE"/>
				</linearGradient>
				<linearGradient id="paint2_linear_3459_53464" x1="0.499999" y1="15" x2="32.125" y2="56.625" gradientUnits="userSpaceOnUse">
					<stop stop-color="#3971FF"/>
					<stop offset="1" stop-color="#305ED3"/>
				</linearGradient>
				<linearGradient id="paint3_linear_3459_53464" x1="30.25" y1="19.5" x2="56.5" y2="3" gradientUnits="userSpaceOnUse">
					<stop stop-color="#6691FF"/>
					<stop offset="1" stop-color="#80A3FF"/>
				</linearGradient>
			</defs>
		</svg>
	}

	@case ('empty-request') {
		<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M62.9375 72.9141H17.9961C17.2015 72.9141 16.4394 72.5984 15.8775 72.0365C15.3157 71.4747 15 70.7126 15 69.918V9.99609C15 9.20148 15.3157 8.43941 15.8775 7.87754C16.4394 7.31566 17.2015 7 17.9961 7H56.9453V12.9922C56.9453 13.7868 57.261 14.5489 57.8228 15.1107C58.3847 15.6726 59.1468 15.9883 59.9414 15.9883H65.9336V69.918C65.9336 70.7126 65.6179 71.4747 65.0561 72.0365C64.4942 72.5984 63.7321 72.9141 62.9375 72.9141Z" fill="url(#paint0_linear_3111_121767)"/>
			<path d="M65.9258 15.9883H59.9336C59.139 15.9883 58.3769 15.6726 57.815 15.1107C57.2532 14.5489 56.9375 13.7868 56.9375 12.9922V7L65.9258 15.9883Z" fill="#6691FF"/>
			<path d="M38.9668 33.9648H26.9824C26.5851 33.9648 26.2041 33.807 25.9231 33.5261C25.6422 33.2451 25.4844 32.8641 25.4844 32.4668C25.4844 32.0695 25.6422 31.6885 25.9231 31.4075C26.2041 31.1266 26.5851 30.9688 26.9824 30.9688H38.9668C39.3641 30.9688 39.7451 31.1266 40.0261 31.4075C40.307 31.6885 40.4648 32.0695 40.4648 32.4668C40.4648 32.8641 40.307 33.2451 40.0261 33.5261C39.7451 33.807 39.3641 33.9648 38.9668 33.9648Z" fill="#6691FF"/>
			<path d="M53.9473 41.457H26.9824C26.5851 41.457 26.2041 41.2992 25.9231 41.0183C25.6422 40.7373 25.4844 40.3563 25.4844 39.959C25.4844 39.5617 25.6422 39.1806 25.9231 38.8997C26.2041 38.6188 26.5851 38.4609 26.9824 38.4609H53.9473C54.3446 38.4609 54.7256 38.6188 55.0065 38.8997C55.2875 39.1806 55.4453 39.5617 55.4453 39.959C55.4453 40.3563 55.2875 40.7373 55.0065 41.0183C54.7256 41.2992 54.3446 41.457 53.9473 41.457Z" fill="#6691FF"/>
			<path d="M49.4531 48.9492H26.9824C26.5851 48.9492 26.2041 48.7914 25.9231 48.5105C25.6422 48.2295 25.4844 47.8485 25.4844 47.4512C25.4844 47.0539 25.6422 46.6728 25.9231 46.3919C26.2041 46.111 26.5851 45.9531 26.9824 45.9531H49.4531C49.8504 45.9531 50.2315 46.111 50.5124 46.3919C50.7933 46.6728 50.9512 47.0539 50.9512 47.4512C50.9512 47.8485 50.7933 48.2295 50.5124 48.5105C50.2315 48.7914 49.8504 48.9492 49.4531 48.9492Z" fill="#6691FF"/>
			<path d="M62.9375 72.9141H17.9961C17.2015 72.9141 16.4394 72.5984 15.8775 72.0365C15.3157 71.4747 15 70.7126 15 69.918V9.99609C15 9.20148 15.3157 8.43941 15.8775 7.87754C16.4394 7.31566 17.2015 7 17.9961 7H56.9453V12.9922C56.9453 13.7868 57.261 14.5489 57.8228 15.1107C58.3847 15.6726 59.1468 15.9883 59.9414 15.9883H65.9336V69.918C65.9336 70.7126 65.6179 71.4747 65.0561 72.0365C64.4942 72.5984 63.7321 72.9141 62.9375 72.9141Z" fill="url(#paint1_linear_3111_121767)"/>
			<path d="M65.9258 15.9883H59.9336C59.139 15.9883 58.3769 15.6726 57.815 15.1107C57.2532 14.5489 56.9375 13.7868 56.9375 12.9922V7L65.9258 15.9883Z" fill="#6691FF"/>
			<path d="M38.9668 33.9648H26.9824C26.5851 33.9648 26.2041 33.807 25.9231 33.5261C25.6422 33.2451 25.4844 32.8641 25.4844 32.4668C25.4844 32.0695 25.6422 31.6885 25.9231 31.4075C26.2041 31.1266 26.5851 30.9688 26.9824 30.9688H38.9668C39.3641 30.9688 39.7451 31.1266 40.0261 31.4075C40.307 31.6885 40.4648 32.0695 40.4648 32.4668C40.4648 32.8641 40.307 33.2451 40.0261 33.5261C39.7451 33.807 39.3641 33.9648 38.9668 33.9648Z" fill="#6691FF"/>
			<path d="M53.9473 41.457H26.9824C26.5851 41.457 26.2041 41.2992 25.9231 41.0183C25.6422 40.7373 25.4844 40.3563 25.4844 39.959C25.4844 39.5617 25.6422 39.1806 25.9231 38.8997C26.2041 38.6188 26.5851 38.4609 26.9824 38.4609H53.9473C54.3446 38.4609 54.7256 38.6188 55.0065 38.8997C55.2875 39.1806 55.4453 39.5617 55.4453 39.959C55.4453 40.3563 55.2875 40.7373 55.0065 41.0183C54.7256 41.2992 54.3446 41.457 53.9473 41.457Z" fill="#6691FF"/>
			<path d="M49.4531 48.9492H26.9824C26.5851 48.9492 26.2041 48.7914 25.9231 48.5105C25.6422 48.2295 25.4844 47.8485 25.4844 47.4512C25.4844 47.0539 25.6422 46.6728 25.9231 46.3919C26.2041 46.111 26.5851 45.9531 26.9824 45.9531H49.4531C49.8504 45.9531 50.2315 46.111 50.5124 46.3919C50.7933 46.6728 50.9512 47.0539 50.9512 47.4512C50.9512 47.8485 50.7933 48.2295 50.5124 48.5105C50.2315 48.7914 49.8504 48.9492 49.4531 48.9492Z" fill="#6691FF"/>
			<defs>
				<linearGradient id="paint0_linear_3111_121767" x1="19.9688" y1="7.45312" x2="65.4688" y2="72.4531" gradientUnits="userSpaceOnUse">
					<stop stop-color="#CEDCFE"/>
					<stop offset="0.558831" stop-color="#C2D3FE"/>
					<stop offset="1" stop-color="#91B0FF"/>
				</linearGradient>
				<linearGradient id="paint1_linear_3111_121767" x1="19.9688" y1="7.45312" x2="65.4688" y2="72.4531" gradientUnits="userSpaceOnUse">
					<stop stop-color="#CEDCFE"/>
					<stop offset="0.558831" stop-color="#C2D3FE"/>
					<stop offset="1" stop-color="#91B0FF"/>
				</linearGradient>
			</defs>
		</svg>
	}

	@case ('empty-product') {
		<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M77.5022 11.2533C77.2107 10.5074 76.6805 9.8789 75.9945 9.46575L71.7641 6.92188C71.4107 8.36569 70.5857 10.2564 68.7266 12.1939C72.2007 14.0798 75.9787 14.9674 77.5022 11.2533Z" fill="#C5D5FF" stroke="#C5D5FF" stroke-width="0.0430307" stroke-miterlimit="10"/>
			<path d="M68.7198 12.1854C70.5761 10.248 71.4012 8.35795 71.7573 6.91344L67.1948 4.16812C66.6611 3.84472 66.0533 3.66381 65.4296 3.64271C64.806 3.62161 64.1873 3.76104 63.633 4.04762C63.0786 4.33421 62.6072 4.75836 62.2638 5.27943C61.9204 5.80049 61.7166 6.40101 61.6719 7.02345L61.7406 7.1087C61.7406 7.1087 65.0635 10.2033 68.7198 12.1854Z" fill="#5786FF" stroke="#5786FF" stroke-width="0.0430307" stroke-miterlimit="10"/>
			<path d="M68.7092 12.1827C68.4287 12.4742 68.1246 12.7667 67.7969 13.06L72.2101 15.716C72.8644 16.1106 73.626 16.29 74.3876 16.2287C75.1492 16.1674 75.8723 15.8686 76.455 15.3744C77.0377 14.8801 77.4506 14.2155 77.6353 13.4741C77.8201 12.7327 77.7675 11.9521 77.4849 11.2422C75.9613 14.9562 72.1833 14.0686 68.7092 12.1827Z" fill="#FDE5D0" stroke="#FDE5D0" stroke-width="0.0430307" stroke-miterlimit="10"/>
			<path d="M68.7092 12.1827C68.4287 12.4742 68.1246 12.7667 67.7969 13.06L72.2101 15.716C72.8644 16.1106 73.626 16.29 74.3876 16.2287C75.1492 16.1674 75.8723 15.8686 76.455 15.3744C77.0377 14.8801 77.4506 14.2155 77.6353 13.4741C77.8201 12.7327 77.7675 11.9521 77.4849 11.2422C75.9613 14.9562 72.1833 14.0686 68.7092 12.1827Z" fill="#99B5FF" stroke="#C5D5FF" stroke-width="0.0430307" stroke-miterlimit="10"/>
			<path d="M63.4273 10.4267L67.8069 13.062C68.1355 12.7691 68.4397 12.4767 68.7192 12.1847C65.0629 10.2026 61.7429 7.10869 61.7429 7.10869L61.6741 7.02344C61.6241 7.69721 61.7614 8.37167 62.0708 8.97228C62.3802 9.57289 62.8497 10.0763 63.4273 10.4267Z" fill="#EA695E" stroke="#EA695E" stroke-width="0.0430307" stroke-miterlimit="10"/>
			<path d="M63.4273 10.4267L67.8069 13.062C68.1355 12.7691 68.4397 12.4767 68.7192 12.1847C65.0629 10.2026 61.7429 7.10869 61.7429 7.10869L61.6741 7.02344C61.6241 7.69721 61.7614 8.37167 62.0708 8.97228C62.3802 9.57289 62.8497 10.0763 63.4273 10.4267Z" fill="#3263DF" stroke="#3263DF" stroke-width="0.0430307" stroke-miterlimit="10"/>
			<path opacity="0.53" d="M72.5885 11.6083L66.4997 8.09224C66.2272 7.9341 66.0284 7.67446 65.9469 7.37012C65.8653 7.06577 65.9076 6.74152 66.0645 6.46829C66.2229 6.19586 66.4827 5.99717 66.7871 5.91563C67.0915 5.83409 67.4158 5.87631 67.6892 6.03308L73.778 9.54843C74.0505 9.70657 74.2493 9.96622 74.3308 10.2706C74.4124 10.5749 74.3701 10.8992 74.2132 11.1724C74.0547 11.4448 73.795 11.6435 73.4907 11.7252C73.1863 11.8068 72.862 11.7648 72.5885 11.6083Z" fill="white" stroke="white" stroke-width="0.0430307" stroke-miterlimit="10"/>
			<path d="M30.1785 71.9385L52.3884 61.4526C54.6193 60.3952 55.5691 57.7407 54.5199 55.5158L33.6353 11.5126C32.5861 9.28767 29.9134 8.34041 27.6825 9.38679L5.4726 19.8726C3.24167 20.919 2.29187 23.5846 3.34107 25.8095L24.2257 69.8127C25.2749 72.0376 27.9476 72.9849 30.1785 71.9385Z" fill="url(#paint0_linear_3111_121768)"/>
			<g filter="url(#filter0_i_3111_121768)">
				<path d="M31.3537 18.0051C30.4039 16.0115 28.0183 15.1634 26.0083 16.0996C24.0093 17.0469 23.1478 19.437 24.0976 21.4306C25.0474 23.4353 27.444 24.2834 29.443 23.3472C31.4531 22.3999 32.3035 20.0098 31.3537 18.0051Z" fill="white"/>
			</g>
			<g filter="url(#filter1_i_3111_121768)">
				<path d="M36.7707 29.4396C35.832 27.446 33.4354 26.5868 31.4253 27.5341C29.4263 28.4813 28.5759 30.8715 29.5147 32.8651C30.4645 34.8698 32.8611 35.7179 34.8601 34.7706C36.8701 33.8234 37.7205 31.4443 36.7707 29.4396Z" fill="white"/>
			</g>
			<g filter="url(#filter2_i_3111_121768)">
				<path d="M42.2034 40.8615C41.2536 38.8678 38.857 38.0087 36.858 38.956C34.848 39.9032 33.9976 42.2934 34.9363 44.287C35.8861 46.2806 38.2827 47.1398 40.2928 46.1925C42.2918 45.2453 43.1422 42.8551 42.2034 40.8615Z" fill="white"/>
			</g>
			<path d="M47.6368 52.3086C46.6869 50.304 44.2904 49.4558 42.2914 50.4031C40.2813 51.3504 39.4309 53.7295 40.3807 55.7342C41.3195 57.7278 43.7161 58.5869 45.7261 57.6397C47.7251 56.6924 48.5866 54.3023 47.6368 52.3086Z" fill="url(#paint1_linear_3111_121768)"/>
			<g filter="url(#filter3_i_3111_121768)">
				<path d="M17.0706 24.7287C16.1208 22.735 13.7242 21.8759 11.7252 22.8231C9.71517 23.7704 8.86477 26.1606 9.80353 28.1542C10.7533 30.1589 13.1499 31.007 15.16 30.0597C17.159 29.1124 18.0094 26.7333 17.0706 24.7287Z" fill="white"/>
			</g>
			<g filter="url(#filter4_i_3111_121768)">
				<path d="M22.4883 36.1584C21.5385 34.1647 19.1419 33.3056 17.1429 34.2528C15.1329 35.2001 14.2825 37.5902 15.2323 39.5839C16.171 41.5775 18.5676 42.4366 20.5777 41.4894C22.5767 40.5421 23.4381 38.152 22.4883 36.1584Z" fill="white"/>
			</g>
			<g filter="url(#filter5_i_3111_121768)">
				<path d="M27.9102 47.5899C26.9604 45.5852 24.5638 44.7371 22.5648 45.6843C20.5547 46.6316 19.7043 49.0107 20.6541 51.0154C21.6039 53.009 23.9895 53.8682 25.9995 52.9209C27.9985 51.9737 28.86 49.5835 27.9102 47.5899Z" fill="white"/>
			</g>
			<g filter="url(#filter6_i_3111_121768)">
				<path d="M33.3321 59.0315C32.3823 57.0269 29.9857 56.1788 27.9867 57.115C25.9766 58.0623 25.1262 60.4524 26.076 62.4571C27.0258 64.4507 29.4114 65.2988 31.4214 64.3626C33.4204 63.4153 34.2819 61.0252 33.3321 59.0315Z" fill="white"/>
			</g>
			<path d="M43.8431 57.6055C43.4455 57.6055 43.0369 57.5394 42.6393 57.3962C41.7447 57.0768 41.0268 56.4269 40.6182 55.5678C40.4525 55.2263 40.3531 54.8739 40.2979 54.5104C40.2869 54.4113 40.3642 54.3011 40.4746 54.2901C40.5851 54.2791 40.6845 54.3562 40.6955 54.4663C40.7397 54.7857 40.8391 55.0942 40.9716 55.3916C41.3361 56.1626 41.9766 56.7353 42.7718 57.0217C43.578 57.3081 44.4395 57.264 45.2015 56.9006C45.4445 56.7794 45.6764 56.6362 45.8863 56.46C45.9636 56.3939 46.0961 56.4049 46.1624 56.493C46.2397 56.5701 46.2287 56.7023 46.1403 56.7684C45.9084 56.9666 45.6433 57.1319 45.3672 57.264C44.8813 57.4953 44.3622 57.6055 43.8431 57.6055Z" fill="#FFFFFC"/>
			<path d="M40.627 53.2351C40.6049 53.2351 40.5865 53.2314 40.5718 53.2241C40.4613 53.191 40.4061 53.0809 40.4392 52.9707C40.5276 52.6844 40.6601 52.409 40.8258 52.1446C40.881 52.0565 41.0025 52.0235 41.1019 52.0896C41.1902 52.1446 41.2234 52.2658 41.1682 52.3649C41.0135 52.5852 40.9031 52.8386 40.8147 53.0919C40.7927 53.18 40.7153 53.2351 40.627 53.2351Z" fill="#FFFFFC"/>
			<path d="M40.4091 64.9865C40.3649 64.9865 40.3207 64.9645 40.2986 64.9204L17.8678 17.6459C17.8458 17.5909 17.8678 17.5138 17.9231 17.4917C17.9893 17.4587 18.0556 17.4807 18.0887 17.5468L40.5195 64.8213C40.5526 64.8764 40.5195 64.9535 40.4643 64.9755C40.4496 64.9828 40.4311 64.9865 40.4091 64.9865Z" fill="#83A4FC"/>
			<path d="M10.8971 34.1592C10.8529 34.1592 10.8087 34.1371 10.7867 34.0931C10.7535 34.038 10.7867 33.9609 10.8419 33.9278L35.1723 22.4507C35.2385 22.4176 35.3048 22.4507 35.3379 22.5058C35.36 22.5608 35.3379 22.6379 35.2827 22.671L10.9413 34.1481C10.9265 34.1555 10.9118 34.1592 10.8971 34.1592Z" fill="#83A4FC"/>
			<path d="M16.6658 46.2902C16.6216 46.2902 16.5774 46.2572 16.5553 46.2242C16.5333 46.1581 16.5553 46.092 16.6106 46.059L40.952 34.5708C41.0072 34.5487 41.0735 34.5707 41.1066 34.6368C41.1397 34.6919 41.1066 34.758 41.0514 34.7911L16.721 46.2792C16.6989 46.2866 16.6805 46.2902 16.6658 46.2902Z" fill="#83A4FC"/>
			<path d="M22.0924 57.7158C22.0482 57.7158 22.0041 57.6938 21.982 57.6497C21.9488 57.5837 21.982 57.5176 22.0372 57.4845L46.3676 46.0074C46.4338 45.9743 46.5001 45.9964 46.5332 46.0624C46.5553 46.1175 46.5332 46.1946 46.478 46.2166L22.1476 57.7048C22.1255 57.7122 22.1071 57.7158 22.0924 57.7158Z" fill="#83A4FC"/>
			<g clip-path="url(#clip0_3111_121768)">
				<path d="M74.2562 35.9657C72.529 34.8093 69.2597 33.5623 69.4508 31.0361V28.4948L58.2618 26.2188L46.5282 28.4948V31.0361C46.6821 35.0722 39.8643 34.9517 40.0182 38.9878V43.2342L42.8729 57.8472L40.0182 68.9217V72.7273C40.0182 74.6976 41.6472 76.295 43.6566 76.295H72.3225C74.3319 76.295 75.961 74.6976 75.961 72.7273V53.6074V43.2342V38.9878C75.9608 37.7598 75.3169 36.6182 74.2562 35.9657Z" fill="url(#paint2_linear_3111_121768)"/>
				<path d="M48.8621 32.1768V32.0147C48.8621 31.3213 49.4354 30.7592 50.1425 30.7592H69.4578V28.4947L58.2683 26.2188L46.5341 28.4947V30.7592V31.0354C46.6762 33.293 44.2378 34.4385 42.5915 35.4346C42.5915 35.4345 42.5917 35.4344 42.5917 35.4343C41.1972 36.136 39.9729 37.3515 40.025 38.9874V43.2338L42.353 52.7026V40.1287C42.1987 36.092 49.0163 36.2135 48.8621 32.1768Z" fill="#91AEFC"/>
				<path d="M74.6565 74.0222H45.9896C43.9805 74.0222 42.3521 72.4254 42.3521 70.4543V58.0156L40.0241 68.9318V72.7371C39.984 74.6509 41.7094 76.3441 43.6616 76.3051H72.3285C73.9429 76.3051 75.3118 75.2744 75.7867 73.8466C75.4317 73.9606 75.0511 74.0222 74.6565 74.0222Z" fill="#91AEFC"/>
				<path d="M42.3438 52.7031V58.0057L42.9493 55.1662L42.3438 52.7031Z" fill="#F4D242"/>
				<path d="M75.9644 68.9218H39.9844L41.1862 56.3668L39.9844 43.2344H75.9644V68.9218Z" fill="#E0E9FF"/>
				<path d="M40.0234 43.2344H42.3514V68.9223H40.0234V43.2344Z" fill="#C2D4FF"/>
				<path d="M64.8428 53.837H60.7878C60.6592 53.837 60.555 53.7349 60.555 53.6087V49.6325C60.555 48.4535 59.6202 47.4469 58.4184 47.4075C57.162 47.3663 56.1302 48.3529 56.1302 49.5757V53.6087C56.1302 53.7349 56.026 53.837 55.8974 53.837H51.6844C50.482 53.837 49.4555 54.7536 49.4153 55.9321C49.3733 57.1641 50.3795 58.1758 51.6265 58.1758H56.1302V62.5352C56.1302 63.7143 57.065 64.7209 58.2668 64.7602C59.5232 64.8014 60.555 63.8148 60.555 62.5921V58.4041C60.555 58.278 60.6592 58.1758 60.7878 58.1758H64.9008C66.1478 58.1758 67.1539 57.1641 67.1119 55.9321C67.0717 54.7538 66.0452 53.837 64.8428 53.837Z" fill="#5786FF"/>
				<path d="M58.8085 64.6994C58.627 64.7382 58.4372 64.7542 58.2428 64.7462C57.0486 64.6949 56.1302 63.6825 56.1302 62.5114V58.391C56.1302 58.2649 56.026 58.1627 55.8974 58.1627H51.6267C50.3719 58.1627 49.3604 57.1378 49.4163 55.8948C49.4221 55.7784 49.4361 55.6654 49.4605 55.5547C49.8667 56.0329 50.4778 56.3365 51.1611 56.3365H57.7598C57.8884 56.3365 57.9926 56.4387 57.9926 56.5648V62.9679C57.9926 63.6539 58.3069 64.2851 58.8085 64.6994Z" fill="#3263DF"/>
				<path d="M58.0181 47.4219C58.0021 47.5262 57.9935 47.6326 57.9936 47.7415V53.6013C57.9936 53.7269 57.8889 53.8296 57.7608 53.8296H55.8984C56.0276 53.8296 56.1312 53.7269 56.1312 53.6013V49.5677C56.1258 48.857 56.5041 48.1634 57.1067 47.77C57.1067 47.77 57.1067 47.7689 57.1078 47.7689C57.3796 47.5879 57.6925 47.4697 58.0181 47.4219Z" fill="#3263DF"/>
				<path d="M70.3274 28.4706H66.9809L66.139 27.7283L65.2349 28.4706H61.5835L60.7415 27.7283L59.8375 28.4706H56.186L55.4834 27.7283L54.44 28.4706H50.7897L49.8368 27.7283L49.0437 28.4706H45.6484C45.0055 28.4706 44.4844 27.9596 44.4844 27.3292V20.7039C44.4844 20.0735 45.0055 19.5625 45.6484 19.5625H49.0437L49.8923 20.2097L50.7897 19.5625H54.44L55.2886 20.2097L56.186 19.5625H59.8375L60.686 20.2097L61.5835 19.5625H65.2349L66.0835 20.2097L66.9809 19.5625H70.3274C70.9703 19.5625 71.4914 20.0735 71.4914 20.7039V27.3292C71.4914 27.9595 70.9703 28.4706 70.3274 28.4706Z" fill="url(#paint3_linear_3111_121768)"/>
				<path d="M46.8202 20.7039V27.3296C46.8202 27.9596 47.3416 28.471 47.9842 28.471H45.6562C45.0137 28.471 44.4922 27.9596 44.4922 27.3296V20.7039C44.4922 20.0738 45.0137 19.5625 45.6562 19.5625H47.9842C47.3416 19.5625 46.8202 20.0738 46.8202 20.7039Z" fill="#3263DF"/>
				<path d="M49.0547 19.5625H50.8007V28.471H49.0547V19.5625Z" fill="#3263DF"/>
				<path d="M54.4453 19.5625H56.1913V28.471H54.4453V19.5625Z" fill="#3263DF"/>
				<path d="M59.8438 19.5625H61.5897V28.471H59.8438V19.5625Z" fill="#3263DF"/>
				<path d="M65.2422 19.5625H66.9882V28.471H65.2422V19.5625Z" fill="#3263DF"/>
			</g>
			<defs>
				<filter id="filter0_i_3111_121768" x="23.7109" y="15.7188" width="8.03125" height="12.0078" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
					<feFlood flood-opacity="0" result="BackgroundImageFix"/>
					<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
					<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
					<feOffset dy="4"/>
					<feGaussianBlur stdDeviation="2"/>
					<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
					<feColorMatrix type="matrix" values="0 0 0 0 0.756863 0 0 0 0 0.823529 0 0 0 0 1 0 0 0 0.3 0"/>
					<feBlend mode="normal" in2="shape" result="effect1_innerShadow_3111_121768"/>
				</filter>
				<filter id="filter1_i_3111_121768" x="29.1328" y="27.1484" width="8.02734" height="12.0078" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
					<feFlood flood-opacity="0" result="BackgroundImageFix"/>
					<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
					<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
					<feOffset dy="4"/>
					<feGaussianBlur stdDeviation="2"/>
					<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
					<feColorMatrix type="matrix" values="0 0 0 0 0.756863 0 0 0 0 0.823529 0 0 0 0 1 0 0 0 0.3 0"/>
					<feBlend mode="normal" in2="shape" result="effect1_innerShadow_3111_121768"/>
				</filter>
				<filter id="filter2_i_3111_121768" x="34.5547" y="38.5703" width="8.03125" height="12.0078" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
					<feFlood flood-opacity="0" result="BackgroundImageFix"/>
					<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
					<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
					<feOffset dy="4"/>
					<feGaussianBlur stdDeviation="2"/>
					<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
					<feColorMatrix type="matrix" values="0 0 0 0 0.756863 0 0 0 0 0.823529 0 0 0 0 1 0 0 0 0.3 0"/>
					<feBlend mode="normal" in2="shape" result="effect1_innerShadow_3111_121768"/>
				</filter>
				<filter id="filter3_i_3111_121768" x="9.42188" y="22.4375" width="8.03125" height="12.0078" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
					<feFlood flood-opacity="0" result="BackgroundImageFix"/>
					<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
					<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
					<feOffset dy="4"/>
					<feGaussianBlur stdDeviation="2"/>
					<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
					<feColorMatrix type="matrix" values="0 0 0 0 0.756863 0 0 0 0 0.823529 0 0 0 0 1 0 0 0 0.3 0"/>
					<feBlend mode="normal" in2="shape" result="effect1_innerShadow_3111_121768"/>
				</filter>
				<filter id="filter4_i_3111_121768" x="14.8438" y="33.8672" width="8.03125" height="12.0078" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
					<feFlood flood-opacity="0" result="BackgroundImageFix"/>
					<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
					<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
					<feOffset dy="4"/>
					<feGaussianBlur stdDeviation="2"/>
					<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
					<feColorMatrix type="matrix" values="0 0 0 0 0.756863 0 0 0 0 0.823529 0 0 0 0 1 0 0 0 0.3 0"/>
					<feBlend mode="normal" in2="shape" result="effect1_innerShadow_3111_121768"/>
				</filter>
				<filter id="filter5_i_3111_121768" x="20.2656" y="45.2969" width="8.03125" height="12.0078" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
					<feFlood flood-opacity="0" result="BackgroundImageFix"/>
					<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
					<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
					<feOffset dy="4"/>
					<feGaussianBlur stdDeviation="2"/>
					<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
					<feColorMatrix type="matrix" values="0 0 0 0 0.756863 0 0 0 0 0.823529 0 0 0 0 1 0 0 0 0.3 0"/>
					<feBlend mode="normal" in2="shape" result="effect1_innerShadow_3111_121768"/>
				</filter>
				<filter id="filter6_i_3111_121768" x="25.6875" y="56.7344" width="8.03125" height="12.0078" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
					<feFlood flood-opacity="0" result="BackgroundImageFix"/>
					<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
					<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
					<feOffset dy="4"/>
					<feGaussianBlur stdDeviation="2"/>
					<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
					<feColorMatrix type="matrix" values="0 0 0 0 0.756863 0 0 0 0 0.823529 0 0 0 0 1 0 0 0 0.3 0"/>
					<feBlend mode="normal" in2="shape" result="effect1_innerShadow_3111_121768"/>
				</filter>
				<linearGradient id="paint0_linear_3111_121768" x1="28.9305" y1="8.96094" x2="28.3363" y2="71.2427" gradientUnits="userSpaceOnUse">
					<stop stop-color="#CCDAFF"/>
					<stop offset="1" stop-color="#91B0FF"/>
				</linearGradient>
				<linearGradient id="paint1_linear_3111_121768" x1="44.0078" y1="50.0156" x2="44.0078" y2="58.0253" gradientUnits="userSpaceOnUse">
					<stop stop-color="white"/>
					<stop offset="1" stop-color="#AFC5FF"/>
				</linearGradient>
				<linearGradient id="paint2_linear_3111_121768" x1="64.1892" y1="51.3326" x2="84.7247" y2="26.2017" gradientUnits="userSpaceOnUse">
					<stop stop-color="#AFC5FF"/>
					<stop offset="1" stop-color="white"/>
				</linearGradient>
				<linearGradient id="paint3_linear_3111_121768" x1="57.9879" y1="19.5625" x2="32.5704" y2="51.8122" gradientUnits="userSpaceOnUse">
					<stop stop-color="#3970FF"/>
					<stop offset="1" stop-color="#224399"/>
				</linearGradient>
				<clipPath id="clip0_3111_121768">
					<rect width="36.3636" height="56.7273" fill="white" transform="translate(40 19.5547)"/>
				</clipPath>
			</defs>
		</svg>
	}

	@case ('empty-offer') {
		<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0_3231_136705)">
				<path d="M47.2562 28.411C45.529 27.2547 42.2597 26.0076 42.4508 23.4814V20.9401L31.2618 18.6641L19.5282 20.9401V23.4814C19.6821 27.5175 12.8643 27.397 13.0182 31.4331V35.6795L15.8729 50.2925L13.0182 61.367V65.1726C13.0182 67.1429 14.6472 68.7403 16.6566 68.7403H45.3225C47.3319 68.7403 48.961 67.1429 48.961 65.1726V46.0527V35.6795V31.4331C48.9608 30.2051 48.3169 29.0635 47.2562 28.411Z" fill="url(#paint0_linear_3231_136705)"/>
				<path d="M21.8621 24.6221V24.46C21.8621 23.7666 22.4354 23.2045 23.1425 23.2045H42.4578V20.94L31.2683 18.6641L19.5341 20.94V23.2045V23.4807C19.6762 25.7383 17.2378 26.8838 15.5915 27.8799C15.5915 27.8798 15.5917 27.8797 15.5917 27.8796C14.1972 28.5813 12.9729 29.7969 13.025 31.4327V35.6791L15.353 45.148V32.5741C15.1987 28.5373 22.0163 28.6588 21.8621 24.6221Z" fill="#91AEFC"/>
				<path d="M47.6565 66.4675H18.9896C16.9805 66.4675 15.3521 64.8707 15.3521 62.8996V50.4609L13.0241 61.3771V65.1825C12.984 67.0962 14.7094 68.7894 16.6616 68.7504H45.3285C46.9429 68.7504 48.3118 67.7197 48.7867 66.2919C48.4317 66.4059 48.0511 66.4675 47.6565 66.4675Z" fill="#91AEFC"/>
				<path d="M15.3438 45.1484V50.4511L15.9493 47.6115L15.3438 45.1484Z" fill="#F4D242"/>
				<path d="M48.9644 61.3671H12.9844L14.1862 48.8121L12.9844 35.6797H48.9644V61.3671Z" fill="#E0E9FF"/>
				<path d="M13.0234 35.6797H15.3514V61.3676H13.0234V35.6797Z" fill="#C2D4FF"/>
				<path d="M37.8428 46.2823H33.7878C33.6592 46.2823 33.555 46.1802 33.555 46.054V42.0778C33.555 40.8988 32.6202 39.8922 31.4184 39.8528C30.162 39.8116 29.1302 40.7982 29.1302 42.021V46.054C29.1302 46.1802 29.026 46.2823 28.8974 46.2823H24.6844C23.482 46.2823 22.4555 47.199 22.4153 48.3774C22.3733 49.6094 23.3795 50.6212 24.6265 50.6212H29.1302V54.9805C29.1302 56.1596 30.065 57.1662 31.2668 57.2056C32.5232 57.2468 33.555 56.2601 33.555 55.0374V50.8494C33.555 50.7233 33.6592 50.6212 33.7878 50.6212H37.9008C39.1478 50.6212 40.1539 49.6094 40.1119 48.3774C40.0717 47.1991 39.0452 46.2823 37.8428 46.2823Z" fill="#5786FF"/>
				<path d="M31.8085 57.1447C31.627 57.1835 31.4372 57.1995 31.2428 57.1915C30.0486 57.1402 29.1302 56.1278 29.1302 54.9567V50.8363C29.1302 50.7102 29.026 50.6081 28.8974 50.6081H24.6267C23.3719 50.6081 22.3604 49.5831 22.4163 48.3401C22.4221 48.2237 22.4361 48.1107 22.4605 48C22.8667 48.4782 23.4778 48.7818 24.1611 48.7818H30.7598C30.8884 48.7818 30.9926 48.884 30.9926 49.0101V55.4133C30.9926 56.0992 31.3069 56.7304 31.8085 57.1447Z" fill="#3263DF"/>
				<path d="M31.0181 39.8672C31.0021 39.9715 30.9935 40.0779 30.9936 40.1868V46.0466C30.9936 46.1722 30.8889 46.2749 30.7608 46.2749H28.8984C29.0276 46.2749 29.1312 46.1722 29.1312 46.0466V42.013C29.1258 41.3024 29.5041 40.6087 30.1067 40.2153C30.1067 40.2153 30.1067 40.2142 30.1078 40.2142C30.3796 40.0333 30.6925 39.915 31.0181 39.8672Z" fill="#3263DF"/>
				<path d="M43.3274 20.9159H39.9809L39.139 20.1736L38.2349 20.9159H34.5835L33.7415 20.1736L32.8375 20.9159H29.186L28.4834 20.1736L27.44 20.9159H23.7897L22.8368 20.1736L22.0437 20.9159H18.6484C18.0055 20.9159 17.4844 20.4049 17.4844 19.7746V13.1492C17.4844 12.5188 18.0055 12.0078 18.6484 12.0078H22.0437L22.8923 12.655L23.7897 12.0078H27.44L28.2886 12.655L29.186 12.0078H32.8375L33.686 12.655L34.5835 12.0078H38.2349L39.0835 12.655L39.9809 12.0078H43.3274C43.9703 12.0078 44.4914 12.5188 44.4914 13.1492V19.7746C44.4914 20.4048 43.9703 20.9159 43.3274 20.9159Z" fill="url(#paint1_linear_3231_136705)"/>
				<path d="M19.8202 13.1492V19.7749C19.8202 20.4049 20.3416 20.9163 20.9842 20.9163H18.6562C18.0137 20.9163 17.4922 20.4049 17.4922 19.7749V13.1492C17.4922 12.5192 18.0137 12.0078 18.6562 12.0078H20.9842C20.3416 12.0078 19.8202 12.5192 19.8202 13.1492Z" fill="#3263DF"/>
				<path d="M22.0547 12.0078H23.8007V20.9163H22.0547V12.0078Z" fill="#3263DF"/>
				<path d="M27.4453 12.0078H29.1913V20.9163H27.4453V12.0078Z" fill="#3263DF"/>
				<path d="M32.8438 12.0078H34.5897V20.9163H32.8438V12.0078Z" fill="#3263DF"/>
				<path d="M38.2422 12.0078H39.9882V20.9163H38.2422V12.0078Z" fill="#3263DF"/>
			</g>
			<path d="M69.8382 28.6127C69.5466 27.8668 69.0165 27.2383 68.3304 26.8251L64.1 24.2812C63.7466 25.7251 62.9216 27.6158 61.0625 29.5532C64.5366 31.4392 68.3146 32.3268 69.8382 28.6127Z" fill="#C5D5FF" stroke="#C5D5FF" stroke-width="0.0430307" stroke-miterlimit="10"/>
			<path d="M61.0557 29.5448C62.9121 27.6073 63.7371 25.7173 64.0932 24.2728L59.5308 21.5275C58.9971 21.2041 58.3893 21.0232 57.7656 21.0021C57.1419 20.981 56.5232 21.1204 55.9689 21.407C55.4146 21.6936 54.9431 22.1177 54.5997 22.6388C54.2563 23.1599 54.0525 23.7604 54.0078 24.3828L54.0766 24.4681C54.0766 24.4681 57.3994 27.5627 61.0557 29.5448Z" fill="#5786FF" stroke="#5786FF" stroke-width="0.0430307" stroke-miterlimit="10"/>
			<path d="M61.0452 29.5421C60.7647 29.8336 60.4605 30.126 60.1328 30.4194L64.5461 33.0753C65.2003 33.47 65.9619 33.6494 66.7235 33.5881C67.4851 33.5268 68.2083 33.2279 68.7909 32.7337C69.3736 32.2395 69.7865 31.5749 69.9713 30.8335C70.156 30.0921 70.1034 29.3114 69.8208 28.6016C68.2973 32.3156 64.5193 31.428 61.0452 29.5421Z" fill="#FDE5D0" stroke="#FDE5D0" stroke-width="0.0430307" stroke-miterlimit="10"/>
			<path d="M61.0452 29.5421C60.7647 29.8336 60.4605 30.126 60.1328 30.4194L64.5461 33.0753C65.2003 33.47 65.9619 33.6494 66.7235 33.5881C67.4851 33.5268 68.2083 33.2279 68.7909 32.7337C69.3736 32.2395 69.7865 31.5749 69.9713 30.8335C70.156 30.0921 70.1034 29.3114 69.8208 28.6016C68.2973 32.3156 64.5193 31.428 61.0452 29.5421Z" fill="#99B5FF" stroke="#C5D5FF" stroke-width="0.0430307" stroke-miterlimit="10"/>
			<path d="M55.7632 27.7861L60.1428 30.4214C60.4715 30.1285 60.7756 29.8361 61.0552 29.5441C57.3989 27.562 54.0788 24.4681 54.0788 24.4681L54.01 24.3828C53.9601 25.0566 54.0974 25.731 54.4068 26.3317C54.7162 26.9323 55.1856 27.4356 55.7632 27.7861Z" fill="#EA695E" stroke="#EA695E" stroke-width="0.0430307" stroke-miterlimit="10"/>
			<path d="M55.7632 27.7861L60.1428 30.4214C60.4715 30.1285 60.7756 29.8361 61.0552 29.5441C57.3989 27.562 54.0788 24.4681 54.0788 24.4681L54.01 24.3828C53.9601 25.0566 54.0974 25.731 54.4068 26.3317C54.7162 26.9323 55.1856 27.4356 55.7632 27.7861Z" fill="#3263DF" stroke="#3263DF" stroke-width="0.0430307" stroke-miterlimit="10"/>
			<path opacity="0.53" d="M64.9245 28.9677L58.8357 25.4516C58.5631 25.2935 58.3644 25.0338 58.2828 24.7295C58.2012 24.4251 58.2435 24.1009 58.4005 23.8277C58.5589 23.5552 58.8186 23.3565 59.123 23.275C59.4274 23.1935 59.7517 23.2357 60.0251 23.3925L66.1139 26.9078C66.3864 27.0659 66.5852 27.3256 66.6668 27.6299C66.7483 27.9343 66.706 28.2585 66.5491 28.5318C66.3907 28.8042 66.131 29.0029 65.8266 29.0845C65.5223 29.1662 65.198 29.1242 64.9245 28.9677Z" fill="white" stroke="white" stroke-width="0.0430307" stroke-miterlimit="10"/>
			<path d="M65 65C69.9706 60.0294 69.9706 51.9706 65 47C60.0294 42.0294 51.9706 42.0294 47 47C42.0294 51.9706 42.0294 60.0294 47 65C51.9706 69.9706 60.0294 69.9706 65 65Z" fill="#C9D8FE"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M56.0081 43.2695C63.0386 43.2695 68.7379 48.9689 68.7379 55.9994C68.7379 63.0299 63.0386 68.7292 56.0081 68.7292C55.7198 68.7292 55.4339 68.7187 55.1504 68.6998C61.7809 68.2586 67.0227 62.7416 67.0227 55.9994C67.0227 49.2572 61.7809 43.7402 55.1504 43.2989C55.4339 43.2801 55.7198 43.2695 56.0081 43.2695Z" fill="#91AEFC"/>
			<path d="M56.0095 65.1987C61.09 65.1987 65.2085 61.0802 65.2085 55.9997C65.2085 50.9193 61.09 46.8008 56.0095 46.8008C50.9291 46.8008 46.8105 50.9193 46.8105 55.9997C46.8105 61.0802 50.9291 65.1987 56.0095 65.1987Z" fill="#91AEFC"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M56.0095 46.8008C50.9291 46.8008 46.8105 50.9193 46.8105 55.9997C46.8105 61.0802 50.9291 65.1987 56.0095 65.1987C56.2592 65.1987 56.5067 65.1883 56.7515 65.1688C52.0179 64.791 48.2944 60.8303 48.2944 55.9997C48.2944 51.1691 52.0179 47.2084 56.7515 46.8307C56.5067 46.811 56.2592 46.8008 56.0095 46.8008Z" fill="#5786FF"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M59.7512 52.7734C59.9908 53.3448 59.722 54.0023 59.1506 54.242C58.5793 54.4816 57.9218 54.2128 57.682 53.6415C57.4541 53.0984 57.0217 52.7852 56.547 52.665C56.2344 52.5857 55.9087 52.5806 55.6067 52.6414C55.318 52.6995 55.0538 52.8156 54.8496 52.9818C54.6148 53.1728 54.4656 53.447 54.4821 53.7857C54.4964 54.0804 54.6539 54.2849 54.8737 54.4284C55.2236 54.657 55.7205 54.786 56.1967 54.8643C56.9481 54.9876 57.7707 55.1949 58.4663 55.6149C59.2929 56.1139 59.9164 56.866 60.057 58.024C60.0672 58.1091 60.0738 58.2149 60.0769 58.3405C60.1024 59.41 59.6162 60.2055 58.8856 60.7599C58.3784 61.1448 57.7568 61.3965 57.1307 61.5293V61.8234C57.1307 62.4431 56.6282 62.9456 56.0085 62.9456C55.3887 62.9456 54.8862 62.4431 54.8862 61.8234V61.5066C54.392 61.3877 53.9222 61.1901 53.5075 60.9141C52.7722 60.4243 52.2088 59.7013 51.9702 58.7442C51.8225 58.1438 52.1896 57.5373 52.79 57.3896C53.3904 57.2419 53.997 57.609 54.1445 58.2094C54.2357 58.5753 54.4557 58.8547 54.7438 59.0466C55.1198 59.297 55.6061 59.4163 56.1024 59.4045C56.1809 59.4027 56.2835 59.3941 56.4095 59.3788C56.8326 59.3275 57.2491 59.1976 57.5356 58.9803C57.7226 58.8385 57.8471 58.6438 57.8412 58.3932C57.841 58.3816 57.8373 58.3465 57.8302 58.2872C57.7878 57.9376 57.5827 57.7004 57.3091 57.5351C56.9049 57.291 56.3555 57.1589 55.8373 57.0737C55.1074 56.9539 54.3125 56.734 53.6551 56.3047C52.8674 55.7906 52.3017 55.0287 52.2465 53.8909C52.192 52.7669 52.6755 51.8659 53.4382 51.2459C53.853 50.9086 54.3519 50.6617 54.8865 50.5163V50.1808C54.8865 49.5611 55.389 49.0586 56.0087 49.0586C56.6285 49.0586 57.131 49.5611 57.131 50.1808V50.5011C58.2294 50.7908 59.2254 51.5208 59.7512 52.7734Z" fill="#D8E3FF"/>
			<defs>
				<linearGradient id="paint0_linear_3231_136705" x1="37.1892" y1="43.7779" x2="57.7247" y2="18.6471" gradientUnits="userSpaceOnUse">
					<stop stop-color="#AFC5FF"/>
					<stop offset="1" stop-color="white"/>
				</linearGradient>
				<linearGradient id="paint1_linear_3231_136705" x1="30.9879" y1="12.0078" x2="5.57035" y2="44.2575" gradientUnits="userSpaceOnUse">
					<stop stop-color="#3970FF"/>
					<stop offset="1" stop-color="#224399"/>
				</linearGradient>
				<clipPath id="clip0_3231_136705">
					<rect width="36.3636" height="56.7273" fill="white" transform="translate(13 12)"/>
				</clipPath>
			</defs>
		</svg>
	}

	@case ('empty-chat') {
		<svg width="80" height="81" viewBox="0 0 80 81" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M48.2602 24.5319C42.0761 24.7084 36.2139 27.3477 31.9589 31.8711C27.704 36.3946 25.4037 42.4329 25.5624 48.6621C25.8908 54.8851 28.6563 60.7229 33.2523 64.8949C37.8483 69.0669 43.8995 71.2326 50.0787 70.9169C52.5438 70.8174 54.9783 70.3297 57.2937 69.4716L62.8035 72.1823C63.3302 72.4415 63.9189 72.5454 64.5017 72.4818C65.0844 72.4182 65.6374 72.1899 66.0968 71.8231C66.5561 71.4563 66.903 70.9661 67.0974 70.4091C67.2918 69.8521 67.3257 69.2511 67.1952 68.6755L66.1082 63.836C68.3334 61.6158 70.0753 58.9534 71.2231 56.0181C72.3709 53.0828 72.8994 49.939 72.775 46.7867C72.6169 43.7039 71.8574 40.6826 70.5397 37.8955C69.222 35.1085 67.3721 32.6102 65.0955 30.5435C62.8189 28.4768 60.1603 26.8821 57.2717 25.8506C54.383 24.8191 51.3209 24.371 48.2602 24.5319Z" fill="url(#paint0_linear_3111_125019)"/>
			<path d="M32.0353 8.00154C25.5517 7.92861 19.304 10.4514 14.6621 15.0166C10.0203 19.5819 7.36318 25.817 7.27344 32.3549C7.27124 35.6741 7.95773 38.9571 9.28879 41.9929C10.6198 45.0286 12.566 47.75 15.0023 49.982L14.0056 55.3474C13.9226 55.7979 13.9388 56.2613 14.053 56.7048C14.1672 57.1483 14.3765 57.5611 14.6663 57.9141C14.9561 58.267 15.3192 58.5515 15.73 58.7473C16.1407 58.9432 16.5892 59.0457 17.0436 59.0476C17.5574 59.0467 18.0631 58.9175 18.5153 58.6715L24.3777 55.4957C26.8529 56.2963 29.436 56.7059 32.0353 56.7098C38.5192 56.7828 44.7672 54.2598 49.4091 49.6942C54.051 45.1286 56.7079 38.893 56.7972 32.3549C56.7075 25.817 54.0504 19.5819 49.4085 15.0166C44.7667 10.4514 38.519 7.92861 32.0353 8.00154Z" fill="url(#paint1_linear_3111_125019)"/>
			<path d="M42.6969 32.506H19.2406C18.895 32.506 18.5636 32.374 18.3192 32.139C18.0748 31.904 17.9375 31.5853 17.9375 31.253C17.9375 30.9207 18.0748 30.602 18.3192 30.367C18.5636 30.132 18.895 30 19.2406 30H42.6969C43.0425 30 43.3739 30.132 43.6183 30.367C43.8627 30.602 44 30.9207 44 31.253C44 31.5853 43.8627 31.904 43.6183 32.139C43.3739 32.374 43.0425 32.506 42.6969 32.506Z" fill="#6691FF"/>
			<path d="M29.6656 39.0216H19.2406C18.895 39.0216 18.5636 38.8896 18.3192 38.6546C18.0748 38.4197 17.9375 38.1009 17.9375 37.7686C17.9375 37.4363 18.0748 37.1176 18.3192 36.8826C18.5636 36.6476 18.895 36.5156 19.2406 36.5156H29.6656C30.0112 36.5156 30.3427 36.6476 30.5871 36.8826C30.8315 37.1176 30.9688 37.4363 30.9688 37.7686C30.9688 38.1009 30.8315 38.4197 30.5871 38.6546C30.3427 38.8896 30.0112 39.0216 29.6656 39.0216Z" fill="#6691FF"/>
			<defs>
				<linearGradient id="paint0_linear_3111_125019" x1="56.6178" y1="48.6262" x2="84.1359" y2="93.8631" gradientUnits="userSpaceOnUse">
					<stop stop-color="#3971FF"/>
					<stop offset="1" stop-color="#224499"/>
				</linearGradient>
				<linearGradient id="paint1_linear_3111_125019" x1="32.0353" y1="8" x2="32.0353" y2="59.0476" gradientUnits="userSpaceOnUse">
					<stop stop-color="#CAD9FE"/>
					<stop offset="1" stop-color="#96B4FF"/>
				</linearGradient>
			</defs>
		</svg>
	}
}
