import { HttpClient, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { ExporterStock } from 'src/app/_common/models/exporter-stock.model';
import { firstValueFrom } from 'rxjs';
import { ExporterStockEntry } from "../../../models/exporter-stock-entry.model";
import { ExporterManifestEntry } from "../../../models/exporter-manifest-entry.model";
import { StockOffer } from "../../../models/stock-offer.model";
import { ExporterQuotaEntry } from "../../../models/exporter-quota-entry.model";
import { ExporterQuota } from "../../../models/exporter-quota.model";

@Injectable({
	providedIn: 'root',
})
export class ApiExporterService {
	private readonly http = inject(HttpClient);

	private readonly API_URL: string = environment.API_URL;
	private readonly EXPORTER_ROUTE: string = 'exporter';

	constructor() {}

	public getById(exporterId: number): Promise<HttpResponse<ExporterStock | null>> {
		return firstValueFrom(
			this.http.get<ExporterStock | null>(`${this.API_URL}/${this.EXPORTER_ROUTE}/${exporterId}`, {
				observe: 'response',
			})
		);
	}

	public getStock(exporterId: number): Promise<HttpResponse<ExporterStockEntry[] | null>> {
		return firstValueFrom(
			this.http.get<ExporterStockEntry[] | null>(`${this.API_URL}/${this.EXPORTER_ROUTE}/${exporterId}/stock`, {
				observe: 'response',
			})
		);
	}

	public getQuotas(exporterId: number): Promise<HttpResponse<ExporterQuota[] | null>> {
		return firstValueFrom(
			this.http.get<ExporterQuota[] | null>(`${this.API_URL}/${this.EXPORTER_ROUTE}/${exporterId}/quota`, {
				observe: 'response',
			})
		);
	}

	// [TODO]: Make the DTO
	public postStock(dto: any): Promise<HttpResponse<ExporterManifestEntry[] | null>> {
		return firstValueFrom(
			this.http.post<ExporterManifestEntry[] | null>(`${this.API_URL}/${this.EXPORTER_ROUTE}/stock`, dto, {
				observe: 'response',
			})
		);
	}

	public getStockOffer(exporterId?: number, importerId?: number): Promise<HttpResponse<StockOffer[]>> {
		let uri = `${this.API_URL}/${this.EXPORTER_ROUTE}/stock/offer`;

		if (exporterId && importerId) {
			uri += `?exporterId=${exporterId}&importerId=${importerId}`;
		} else if (exporterId) {
			uri += `?exporterId=${exporterId}`;
		} else if (importerId) {
			uri += `?importerId=${importerId}`;
		}

		return firstValueFrom(this.http.get<StockOffer[]>(uri, { observe: 'response' }));
	}

	public postStockOffer(stockOffer: StockOffer): Promise<HttpResponse<StockOffer | null>> {
		return firstValueFrom(this.http.post<StockOffer | null>(
			`${this.API_URL}/${this.EXPORTER_ROUTE}/stock/offer`,
			stockOffer,
			{ observe: 'response' }
		));
	}

	public getAllManifests(exporterId: number): Promise<HttpResponse<string[] | null>> {
		return firstValueFrom(
			this.http.get<string[] | null>(`${this.API_URL}/${this.EXPORTER_ROUTE}/${exporterId}/manifest`, {
				observe: 'response',
			})
		);
	}

	public getManifestById(exporterId: number, manifestId: number): Promise<HttpResponse<ExporterStock | null>> {
		return firstValueFrom(
			this.http.get<ExporterStock | null>(
				`${this.API_URL}/${this.EXPORTER_ROUTE}/${exporterId}/manifest/${manifestId}`,
				{ observe: 'response' }
			)
		);
	}

	public putStockQuotas(stockQuotas: ExporterQuotaEntry[]): Promise<HttpResponse<ExporterQuotaEntry[] | null>> {
		return firstValueFrom(
			this.http.put<ExporterQuotaEntry[] | null>(
				`${this.API_URL}/${this.EXPORTER_ROUTE}/stock/quota`,
				stockQuotas,
				{ observe: 'response' }
			)
		);
	}
}
