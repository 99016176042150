import { Component, computed, inject, signal } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { saxTrashOutline } from '@ng-icons/iconsax/outline';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { NgIcon, provideIcons } from '@ng-icons/core';

import { MhpButtonComponent } from '../../_common/components/mhp-button/mhp-button.component';
import { MhpPackageComponent } from '../../_common/components/mhp-package/mhp-package.component';
import { SITEMAP } from '../../_common/sitemap';
import { TableHandlerService } from '../../_common/services/table-handler/table-handler.service';
import { ExporterStockEntry } from '../../_common/models/exporter-stock-entry.model';
import { MhpFileUploaderComponent } from '../../_common/components/mhp-file-uploader/mhp-file-uploader.component';
import { ApiExporterService } from 'src/app/_common/services/api/api-exporter/api-exporter.service';
import { ExporterQuotaEntry } from "../../_common/models/exporter-quota-entry.model";
import { ApiPackageService } from "../../_common/services/api/api-package/api-package.service";

interface UpdateStockProps {
	step: number;
}

@Component({
	selector: 'app-update-stock',
	standalone: true,
	imports: [
		MhpButtonComponent,
		RouterLink,
		MhpFileUploaderComponent,
		MhpPackageComponent,
		NgIcon,
		ReactiveFormsModule,
		FormsModule,
	],
	providers: [
		provideIcons({
			saxTrashOutline,
		}),
	],
	templateUrl: './update-stock.component.html',
	styleUrls: ['./update-stock.component.scss', '../update-stock-quota.component.scss'],
})
export class UpdateStockComponent {
	protected readonly _apiExporterSvc = inject(ApiExporterService);
	protected readonly _apiPackageSvc = inject(ApiPackageService);
	protected readonly _router = inject(Router);

	protected readonly _tableSvc = new TableHandlerService<ExporterStockEntry, UpdateStockProps>(
		'update-stock',
		row =>
			<ExporterStockEntry>{
				batch: {
					id: row['Batch number'],
					name: row['Product name'],
					expiration: row['Expiration date'],
				},
				packageCip13: +row['CIP 13'],
				quantity: +row['Quantities'] || null,
				price: +row['Price'] || null,
			}
	);

	protected readonly sitemap = SITEMAP;

	protected readonly props = this._tableSvc.props;
	protected readonly entries = this._tableSvc.items;

	protected readonly validCip13s = signal<number[]>([]);
	protected readonly file = signal<File | null>(null);

	constructor() {
		dayjs.extend(customParseFormat);
	}

	public setStep(step: number): void {
		this.props.update(props => {
			if (!props) {
				props = <UpdateStockProps>{};
			}

			props.step = step;
			return props;
		});
	}

	public nextStep(): void {
		if (this.props()?.step === 4) return;
		this.setStep((this.props()?.step || 0) + 1);
	}

	public previousStep(): void {
		if (this.props()?.step === 0) return;
		this.setStep((this.props()?.step || 0) - 1);
	}

	protected processFile(file: File | null) {
		if (file) {
			this._tableSvc.processFile(file, async () => {
				this.validCip13s.set((await this._apiPackageSvc.validateCip13s(this.entries()
					.map(entry => entry.packageCip13)
					.filter(cip13 =>!!cip13)
				)).body ?? []);
			});
		}
	}

	public checkErrorCell(entry: ExporterStockEntry, index: number): boolean {
		const entries = [...this.entries()];
		return entries.splice(index, 1).includes(entry);
	}

	public deleteItem(index: number): void {
		this.entries().splice(index, 1);
	}

	public async submit() {
		const entries = this.entries();

		if (entries.length) {
			const res = await this._apiExporterSvc.postStock(
				entries.map(entry => {
					if (entry.batch)
						entry.batch.expiration = dayjs(entry.batch.expiration, TableHandlerService.dateParsingFormats).format(
							'YYYY-MM-DD'
						);
					return entry;
				})
			);

			if (res.ok) {
				await this._router.navigate([this.sitemap.stockOffer.route]);
			}
		}
	}
}
