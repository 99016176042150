import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BehaviorSubject, catchError, of, Subject, takeUntil } from 'rxjs';
import { MhpBaseComponent } from '../mhp-base/mhp-base/mhp-base.component';
import { LoaderService } from 'src/app/_common/services/loader/loader.service';
import { AsyncPipe, CommonModule } from '@angular/common';

@Component({
	templateUrl: './mhp-loader.component.html',
	styleUrl: './mhp-loader.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CommonModule, AsyncPipe],
})
export class MhpLoaderComponent extends MhpBaseComponent implements OnInit {
	public isFullScreen$: Subject<boolean> = new BehaviorSubject<boolean>(true);
	public isVisible$: Subject<boolean> = new BehaviorSubject<boolean>(false);
	public message$: Subject<string> = new BehaviorSubject<string>('');

	constructor(private loaderService: LoaderService) {
		super();
	}

	/**
	 * On init lifecycle hook
	 */
	public ngOnInit(): void {
		this.subscribeToLoaderServicesObservables();
	}

	/**
	 * Subscribe to the loader service observables
	 */
	private subscribeToLoaderServicesObservables() {
		this.loaderService.isActivated$
			.pipe(
				catchError(() => {
					return of(false);
				}),
				takeUntil(this.subscription$)
			)
			.subscribe((value: boolean) => {
				if (value !== undefined || value !== null) {
					this.isVisible$.next(value);
				}
			});
		this.loaderService.onMessageChanged$.pipe(takeUntil(this.subscription$)).subscribe((value: string) => {
			if (value) {
				this.message$.next(value);
			}
		});
	}
}
