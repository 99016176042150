/**
 * Sorts the given array of objects by the given property in ascending order. Any null or undefined values will be placed at the end of the array.
 * @param a The first object to compare.
 * @param b The second object to compare.
 */
export function sortByLocale(a: string | null | undefined, b: string | null | undefined): number {
	if (!a && !b) {
		return 0;
	} else if (!a) {
		return 1;
	} else if (!b) {
		return -1;
	} else {
		return a.localeCompare(b);
	}
}
