@if(true) {
	<div class="main-request-item-per-company overflow-hidden">

		<div class="flex flex-row items-center p-4">
			<div class="flex flex-row items-center w-full space-x-4 cursor-pointer" (click)="this.expanded = !this.expanded">
				<app-mhp-company [company]="this.exporter()" />
			</div>

			<div class="flex flex-row items-center shrink-0 space-x-4 text-sm">
				<app-mhp-button prefix="saxBook1Outline" text="Access the stock offer" type="secondary" />
				@if (this.user()?.function === this.userFunctions.Importer) {
					@if (
						this.readyToPurchaseOffers().length
						&& this.selectedStatus() === OfferStatusEnum.review
					) {
						<!-- Book all button -->
						<app-mhp-button
							prefix="saxBookmark2Outline"
							text="Confirm purchases ({{ this.readyToPurchaseOffers().length }})"
							type="primary"
							(click)="this.purchaseOffersModal = this.readyToPurchaseOffers()"
						/>
					}

					@if (
						this.readyToOrderOffers().length
						&& this.selectedStatus() === OfferStatusEnum.booked
					) {
						<!-- Order all button -->
						<app-mhp-button
							class="shrink-0"
							prefix="saxTruckOutline"
							text="Order ({{ this.readyToOrderOffers().length }} products)"
							type="primary"
							(click)="this.orderOffersModal = true"
						/>
					}
				}

				<button class="inline-flex flex-row items-center text-gray-900 dark:text-white" (click)="this.expanded = !this.expanded">
					<span>Offer details ({{ this.offerCountDisplay }})</span>
					<ng-icon class="ml-2" [name]="this.expanded ? 'saxArrowUp2Outline' : 'saxArrowDown1Outline'" />
				</button>
			</div>
		</div>

		@if (this.expanded) {
			<table class="w-full">
				<thead class="border-t border-gray-200 dark:border-gray-700">
					<tr class="text-gray-500">
						<th class="p-4 text-sm text-left font-medium w-[30rem]">Product</th>
						<th class="p-4 text-sm text-left font-medium">Batch</th>
						<th class="p-4 text-sm text-left font-medium">Expiration date</th>
						<th class="p-4 text-sm text-left font-medium">Qty</th>
						<th class="p-4 text-sm text-left font-medium">Unit cost</th>
						<th class="p-4 text-sm text-left font-medium">Status</th>
						<th></th>
					</tr>
				</thead>
				<tbody class="border-t border-gray-200 dark:border-gray-700">

					@switch(this.selectedStatus()) {

						@case (OfferStatusEnum.review) {
							@for (offer of this.offersSortedByName(); track $index) {
								@let counterOffer = castToCounterOffer(offer);
								<tr
									class="border-t text-gray-900 border-gray-200 dark:text-white dark:border-gray-700"
									[ngClass]="($index % 2 == 0) ? 'bg-gray-50 dark:bg-[#1c2634]' : 'bg-white dark:bg-gray-800'"
									(click)="this.offerSelected = offer"
								>
									<td class="p-4 cursor-pointer inline-flex flex-row items-center w-full space-x-4" (click)="this.displayOfferHistoryPanel()">
										<app-mhp-package [package]="{ cip13: offer.packageCip13, shortName: offer.shortName }" />
									</td>

									<td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">{{ getOfferLabel(offer) }}</td>
									<td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">
										<!-- <ng-icon class="mr-1 text-red-600" name="saxArrowUp3Outline" /> -->
										@if (offer.expirationDate) {
											{{ offer.expirationDate | date : 'd MMM y' : '' : "en-US" }}
										}
									</td>
									<td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">
										<!-- <ng-icon class="mr-1 text-red-600" name="saxArrowUp3Outline" /> -->
										{{ offer.quantity }}
									</td>
									<td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">
										<!-- <ng-icon class="mr-1 text-red-600" name="saxArrowUp3Outline" /> -->
										{{ offer.price | currency : 'EUR': 'symbol' : '.2-2' }}
									</td>
									<td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">
										@if(isOfferDeclined(offer)) { Declined }
										@else if(isReadyToPurchase(offer))
										{
											@if ( user()?.function === userFunctions.Importer ) { Ready to purchase }
											@else { Waiting buyer to purchase }
										}
										@else if(counterOffer?.parentOfferId)	{
											@if(isAccepted(offer)) { Counter offer sent }
											@else { Counter offer received }
										}
										@else {
											@if(isAccepted(offer)) { Accepted, checking stock }
											@else {
												@if ( user()?.function === userFunctions.Exporter && !isAcceptedByImporter(offer) ) { Offer sent }
												@else { Confirm stock }
											}
										}
									</td>
									<td class="p-4 text-end">
										<div class="flex flex-row justify-end items-center space-x-4 w-full">
											@if(isOfferDeclined(offer)) {}

											@else if(isAccepted(offer)) {
												@if(
													this.user()?.function === this.userFunctions.Importer
													&& ( isReadyToPurchase(offer) )
													&& this.selectedStatus() === OfferStatusEnum.review
												) {
													<button
														class="flex flex-row items-center justify-center text-red-500 hover:text-red-600"
														(click)="this.declineOfferModal = true"
													>
														<ng-icon name="saxCloseCircleBold" size="1.75rem" />
													</button>

													<app-mhp-button
														prefix="saxBookmark2Outline"
														text="Purchase product"
														type="primary"
														(click)="this.purchaseOffersModal = [offer]"
													/>

													<hr inert class="h-8 w-px border-r border-gray-200 dark:border-gray-700" />
												}
											}

											@else if(this.counterOfferVisible(offer)) {
												<app-mhp-button
													prefix="saxNote2Outline"
													text="Counter offer"
													type="tertiary"
													(click)="this.makeOfferModal = true"
												/>

												<button
													class="flex flex-row items-center justify-center text-red-500 hover:text-red-600"
													(click)="this.declineOfferModal = true"
												>
													<ng-icon name="saxCloseCircleBold" size="1.75rem" />
												</button>
												<button
													class="flex flex-row items-center justify-center text-blue-600 hover:text-blue-700"
													(click)="this.acceptOfferModal = true"
												>
													<ng-icon name="saxTickCircleBold" size="1.75rem" />
												</button>

												<hr inert class="h-8 w-px border-r border-gray-200 dark:border-gray-700" />
											}

											<button class="relative flex flex-row items-center" (click)="this.displayOfferHistoryPanel()">
												@if(false) {
													<span class="absolute flex size-2 right-0 top-0">
														<span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75"></span>
														<span class="relative inline-flex rounded-full size-2 bg-blue-500"></span>
													</span>
												}

												<ng-icon size="1.5rem" name="saxMessageOutline" />
											</button>
										</div>
									</td>
								</tr>
							}

							@empty {
								<td colspan="6">
									<div class="flex flex-col items-center justify-center w-full my-6">
										<app-mhp-svg-icon icon="empty-offer" />
										<p class="mt-4 text-gray-500">You haven't received any offers yet</p>
									</div>
								</td>
							}
						}

						@case (OfferStatusEnum.booked) {
							<!-- Confirmed offer
							https://github.com/MedHubCompany/MedHubPlace/issues/123 -->
							@for (offer of this.offersSortedByName(); track $index) {
								<tr
									class="border-t text-gray-900 border-gray-200 dark:text-white dark:border-gray-700"
									(click)="this.offerSelected = offer"
								>
									<td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">
										<div class="inline-flex flex-row items-center w-full space-x-4">
											<div class="flex justify-center items-center h-11 w-11 border rounded-full shrink-0 dark:border-gray-700">
												<app-mhp-svg-icon icon="pill" />
											</div>

											<div class="flex flex-col font-medium">
												<span class="text-sm text-gray-500">{{ offer.packageCip13 | toCip : 13 }}</span>
												<span class="text-sm text-gray-900 dark:text-white">{{ offer.shortName }}</span>
											</div>
										</div>
									</td>

									@if (this.isOfferEditable(offer)) {
										<td class="p-4 cursor-pointer">
											<input
												class="m-0 p-2 w-32 text-center rounded-xl border border-gray-200 focus:border-blue-500 dark:border-gray-700"
												type="text"
												placeholder="Batch number"
												[(ngModel)]="offer.batchNumber"
											/>
										</td>
									}

									@else {
										<td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">{{ this.getOfferLabel(offer) }}</td>
									}


									@if (this.isOfferEditable(offer)) {
										<td class="p-4 cursor-pointer">
											<span class="flex flex-row items-center">
												<!-- <ng-icon class="mr-1 text-red-600" name="saxArrowUp3Outline" /> -->
												<input
													class="m-0 p-2 w-36 text-center rounded-xl border border-gray-200 focus:border-blue-500 dark:border-gray-700 dark:[color-scheme:dark]"
													type="date"
													[(ngModel)]="offer.expirationDate"
												/>
											</span>
										</td>
									}

									@else {
										<td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">
											<span class="flex flex-row items-center">
												<!-- <ng-icon class="mr-1 text-red-600" name="saxArrowUp3Outline" /> -->
												{{ offer.expirationDate | date : 'd MMM y' : '' : "en-US" }}
											</span>
										</td>
									}

									@if (this.isOfferEditable(offer)) {
										<td class="p-4 cursor-pointer">
											<input
												class="m-0 p-2 w-32 text-center rounded-xl border border-gray-200 focus:border-blue-500 dark:border-gray-700"
												type="number"
												placeholder="Quantity"
												[(ngModel)]="offer.quantity"
											/>
										</td>
									}

									@else {
										<td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">
											<span class="flex flex-row items-center">
												<!-- <ng-icon class="mr-1 text-red-600" name="saxArrowUp3Outline" /> -->
												{{ offer.quantity }}
											</span>
										</td>
									}

									<td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">
										<span class="flex flex-row items-center">
											<!-- <ng-icon class="mr-1 text-red-600" name="saxArrowUp3Outline" /> -->
											{{ offer.price | currency : 'EUR': 'symbol' : '.2-2' }}
										</span>
									</td>

									@if(!this.editMode()) {
										<td class="p-4 cursor-pointer text-end" (click)="this.displayOfferHistoryPanel()">
											@if( this.isOfferDeclined(offer) ) {
												<span>Declined</span>
											}

											@else if( this.isWaitingForBatch(offer) ) {
												<span>Waiting for batch</span>
											}

											@else if( this.isReadyToOrder(offer) ) {
												@if( user()?.function === userFunctions.Importer ) { <span>Ready to purchase</span> }
												@else { <span>Waiting shipping request</span> }
											}

											@else if( offer.batchUpdatedAt ) {
												<span>Update sent</span>
											}
										</td>
									}

									<td class="p-4 cursor-pointer text-end" (click)="this.displayOfferHistoryPanel()">
										<div class="flex flex-row justify-end items-center space-x-4 w-full">
										</div>
									</td>

									@if (this.editMode()) {
										<td class="p-4 font-medium">
											<div class="flex flex-row space-x-4 justify-end">

												<button (click)="this.duplicateOffer( offer )">
													<ng-icon name="bootstrapCopy" />
												</button>

												@if (!offer.createdAt) {
													<button>
														<ng-icon (click)="this.deleteOffer( offer )" name="saxTrashOutline" />
													</button>
												}

											</div>
										</td>
									}

									@else {
										<td class="p-4 font-medium"></td>
									}

									<td class="p-4 text-end">


										<div class="flex flex-row justify-end items-center space-x-4 w-full">
											<!-- accept/decline offers are available only for not ready to order -->
											@if (!(this.isOfferDeclined(offer) || this.editMode())) {
												<!-- suppliers can decline a waiting for batch offer, and buyer can decline other status -->
												@if (
													(
														this.user()?.function === userFunctions.Importer
														&& !this.isWaitingForBatch(offer)
														&& !this.isReadyToOrder(offer)
													)
													|| (this.user()?.function === userFunctions.Exporter)
												) {
													<button
														class="flex flex-row items-center justify-center text-red-500 hover:text-red-600"
														(click)="this.declineOfferModal = true"
													>
														<ng-icon name="saxCloseCircleBold" size="1.75rem" />
													</button>

													<hr inert class="h-8 w-px border-r border-gray-200 dark:border-gray-700" />
												}
												<!-- buyers are the only ones that can accept an updated batch -->
												@if(
													this.user()?.function === userFunctions.Importer
													&& !this.isWaitingForBatch(offer)
													&& !this.isReadyToOrder(offer)
												) {
													<button
														class="flex flex-row items-center justify-center text-blue-600 hover:text-blue-700"
														(click)="this.validateBatch( offer )"
													>
														<ng-icon name="saxTickCircleBold" size="1.75rem" />
													</button>

													<hr inert class="h-8 w-px border-r border-gray-200 dark:border-gray-700" />
												}
											}

											<button class="relative flex flex-row items-center" (click)="this.displayOfferHistoryPanel()">
												@if(false) {
													<span class="absolute flex size-2 right-0 top-0">
														<span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75"></span>
														<span class="relative inline-flex rounded-full size-2 bg-blue-500"></span>
													</span>
												}

												<ng-icon size="1.5rem" name="saxMessageOutline" />
											</button>
										</div>
									</td>


								</tr>
							}

							@empty {
								<td colspan="6">
									<div class="flex flex-col items-center justify-center w-full my-6">
										<app-mhp-svg-icon icon="empty-offer" />
										<p class="mt-4 text-gray-500">You have no products currently confirmed.</p>
									</div>
								</td>
							}
						}

						@case (OfferStatusEnum.ordered) {
							@for (offer of this.offersSortedByName(); track $index) {
								<tr
									class="border-t text-gray-900 border-gray-200 dark:text-white dark:border-gray-700"
									(click)="this.offerSelected = offer"
								>
									<td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">
										<div class="inline-flex flex-row items-center w-full space-x-4">
											<div class="flex justify-center items-center h-11 w-11 border rounded-full shrink-0 dark:border-gray-700">
												<app-mhp-svg-icon icon="pill" />
											</div>

											<div class="flex flex-col font-medium">
												<span class="text-sm text-gray-500">{{ offer.packageCip13 | toCip : 13 }}</span>
												<span class="text-sm text-gray-900 dark:text-white">{{ offer.shortName }}</span>
											</div>
										</div>
									</td>

									<td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">{{ offer.batchNumber }}</td>
									<td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">
										<span class="flex flex-row items-center">
											<!-- <ng-icon class="mr-1 text-red-600" name="saxArrowUp3Outline" /> -->
											{{ offer.expirationDate | date : 'd MMM y' : '' : "en-US" }}
										</span>
									</td>
									<td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">
										<span class="flex flex-row items-center">
											<!-- <ng-icon class="mr-1 text-red-600" name="saxArrowUp3Outline" /> -->
											{{ offer.quantity }}
										</span>
									</td>
									<td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">
										<span class="flex flex-row items-center">
											<!-- <ng-icon class="mr-1 text-red-600" name="saxArrowUp3Outline" /> -->
											{{ offer.price | currency : 'EUR': 'symbol' : '.2-2' }}
										</span>
									</td>
									<td class="p-4 cursor-pointer text-end" (click)="this.displayOfferHistoryPanel()">In shipping</td>
									<td class="p-4 cursor-pointer text-end">
										<div class="flex flex-row justify-end items-center space-x-4 w-full">
											<button class="relative flex flex-row items-center" (click)="this.displayOfferHistoryPanel()">
												@if(false) {
													<span class="absolute flex size-2 right-0 top-0">
														<span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75"></span>
														<span class="relative inline-flex rounded-full size-2 bg-blue-500"></span>
													</span>
												}

												<ng-icon size="1.5rem" name="saxMessageOutline" />
											</button>
										</div>
									</td>
								</tr>
							}

							@empty {
								<td colspan="6">
									<div class="flex flex-col items-center justify-center w-full my-6">
										<app-mhp-svg-icon icon="empty-offer" />
										<p class="mt-4 text-gray-500">You have no products currently ordered.</p>
									</div>
								</td>
							}
						}
					}

				</tbody>
			</table>
		}

	</div>
}

@if (this.offerHistoryPanelDisplayed && offerSelected) {
	<app-main-request-offer-history
		[offer]="this.offerSelected"
		[package]="this.getItemByCIP13(this.offerSelected.packageCip13!)!.package"
		(stateClose)="this.offerHistoryPanelDisplayed = false"
	/>
}

@if (this.makeOfferModal) {
	<app-main-request-make-offer-modal
		[exporter]="this.exporter()"
		[item]="this.getItemByCIP13(this.offerSelected?.packageCip13!)!"
		[offer]="this.offerSelected!"
		(stateClose)="this.makeOfferModal = false"
	/>
}

@else if (this.acceptOfferModal) {
	<app-main-request-accept-decline-offer-modal
		[exporter]="this.exporter()"
		[item]="this.getItemByCIP13(this.offerSelected?.packageCip13!)!"
		[offer]="this.offerSelected!"
		[type]="this.ModalResponseTypeEnum.Accepted"
		(stateClose)="this.acceptOfferModal = false"
	/>
}

@else if (this.declineOfferModal) {
	<app-main-request-accept-decline-offer-modal
		[exporter]="this.exporter()"
		[item]="this.getItemByCIP13(this.offerSelected?.packageCip13!)!"
		[offer]="this.offerSelected!"
		[type]="this.ModalResponseTypeEnum.Declined"
		(stateClose)="this.declineOfferModal = false"
	/>
}

@else if (this.purchaseOffersModal.length > 0) {
	@let productText = this.readyToPurchaseOffers().length > 1 ? 'all these products' : 'this product';
	<app-main-request-confirm-offers
		[exporter]="this.exporter()"
		[offers]="this.purchaseOffersModal"
		(onConfirm)="this.purchaseOffers()"
		(onCancel)="this.purchaseOffersModal = []"
		buttonText="Yes, I want to book {{ productText }}"
	>
		<span slot="question">Do you confirm that you want to book {{ productText }} ?</span>
		<span slot="remark">By booking the product, you reserve it in your exporter's stock, committing to the order once you are ready.</span>
	</app-main-request-confirm-offers>
}

@else if (this.orderOffersModal) {
	<app-main-request-confirm-offers
		[exporter]="this.exporter()"
		[offers]="this.readyToOrderOffers()"
		(onCancel)="this.orderOffersModal = false"
		(onConfirm)="this.orderOffers()"
		buttonText="Order and dispatch these products"
	>
		<span slot="question">Do you confirm that your order can be dispatched?</span>
	</app-main-request-confirm-offers>
}
