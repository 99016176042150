import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
@Injectable()
export abstract class BaseService implements OnDestroy {
	/**
	 * À utiliser pour tous les abonnements non unitaires sur des services, dans le pattern takeUntil(subscription$).
	 * Émet la notification de désabonnement lors de la destruction du service.
	 *
	 * @protected
	 * @memberof BaseService
	 */
	protected subscription$ = new Subject();

	public ngOnDestroy(): void {
		this.subscription$.next(null);
		this.subscription$.complete();
	}
}
