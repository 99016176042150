import { Injectable, computed, inject, signal } from '@angular/core';

import { Offer } from '../../models/offer.model';
import { ApiOfferService } from '../api/api-offer/api-offer.service';
import { CompanyService } from '../company/company.service';
import { ImporterRequestItem } from '../../models/importer-request-item.model';
import { ApiImporterService } from '../api/api-importer/api-importer.service';
import { ExporterStockEntry } from '../../models/exporter-stock-entry.model';
import { toDictionary, toDictionarySet } from '../../utils/toDictionary';
import { AccountService } from '../account/account.service';
import { Package } from '../../models/package.model';
import { toArray } from '../../utils/toArray';
import { OfferResponseTypeEnum } from '../../enums/offer-response-type.enum';
import { UserFunctionEnum } from '../../enums/user-function.enum';
import { CounterOffer } from '../../models/counter-offer.model';
import dayjs from 'dayjs';
import { ImporterRequest } from '../../models/importer-request.model';
import { OfferExtension } from '../../extensions/offer.extension';

// [TODO]: Refactor code for getting requestId

@Injectable({
	providedIn: 'root',
})
export class OffersService {
	private readonly _offerApiSvc = inject(ApiOfferService);
	private readonly _apiImporterSvc = inject(ApiImporterService);
	private readonly _companySvc = inject(CompanyService);
	private readonly _accountSvc = inject(AccountService);

	private _currentRequest: ImporterRequest = null!;

	private _currentRequestPackage: Record<number, Package> = {};

	public readonly currentRequestOffers = signal<Offer[]>([]);

	private readonly _newOffers = signal<Offer[]>([]);

	private readonly _editedOffers = signal<Offer[]>([]);

	public readonly newOffers = computed<Offer[]>(this._newOffers);

	public readonly editedOffers = computed<Offer[]>(this._editedOffers);

	public readonly currentRequestOffersByProduct = computed(() =>
		toDictionarySet(this.currentRequestOffers(), o => o.packageCip13 ?? 0)
	);

	private _userFunction = computed(() => {
		const user = this._accountSvc.user();
		if (!user) throw new Error('No connected user found');
		return user!.function;
	});

	public pendingOffersPerProduct = computed<Record<number, Offer[]>>(() => {
		const offers =
			this._userFunction() == UserFunctionEnum.Importer
				? this.currentRequestOffers().filter(o => {
						return OfferExtension.isInPending(o, this._userFunction());
				  })
				: this.newOffers();

		const perProductOffers = toDictionarySet(offers, o => o.packageCip13 ?? 0);
		return perProductOffers;
	});

	public inreviewOffers = computed<Offer[]>(() =>
		this.currentRequestOffers().filter(o => OfferExtension.isInReview(o, this._userFunction()))
	);

	public confirmedOffers = computed<Offer[]>(() =>
		this.currentRequestOffers().filter(o => OfferExtension.isInConfirmed(o))
	);

	public orderedOffers = computed<Offer[]>(() =>
		this.currentRequestOffers().filter(o => OfferExtension.isInShipping(o))
	);

	// () => {
	// 	const orderedOffers = this.currentRequestOffers().filter(o => o.orderingDate);
	// 	return orderedOffers;
	// });

	constructor() {}

	/**
	 * Get offers for a request
	 * @param request the full request
	 */
	public async initRequest(request: ImporterRequest): Promise<void> {
		this._currentRequest = request;

		// order request ites and group them by package
		this._currentRequestPackage = toDictionary(
			request.items.map(item => item.package),
			p => p.cip13
		);

		// short name of package is copied from the package
		const offers = request.offers.map(o => {
			return this.setOfferShortName(o);
		});

		this.currentRequestOffers.set(offers);

		this.resetEditedOffers(request.id, request.items);
	}

	private setOfferShortName(o: Offer) {
		o.shortName = this._currentRequestPackage[o.packageCip13]?.shortName ?? '';
		return o;
	}

	/**
	 * Reinit the currently edited offers
	 */
	private resetEditedOffers(requestId: string, requestItems: ImporterRequestItem[]): void {
		this._newOffers.set([]);
		this._editedOffers.set([]);

		// add an empty offer for each product
		this.createNewOffers(requestId, requestItems);
	}

	/**
	 * Create a new offer for the item in parameter
	 * @param cip13
	 * @returns the new offer
	 */
	public createNewOfferForItem(requestId: string, item: ImporterRequestItem): Offer {
		return <Offer>{
			batchNumber: '',
			exporterId: this._companySvc.currentCompany()!.id,
			packageCip13: item.packageCip13,
			price: 0,
			quantity: 0,
			itemId: item.itemId,
			requestId,
			exporterResponse: OfferResponseTypeEnum.None,
			importerResponse: OfferResponseTypeEnum.None,
		};
	}

	/**
	 * Create new offers for the items in parameter
	 * @param requestId
	 * @param requestItems
	 */
	private createNewOffers(requestId: string, requestItems: ImporterRequestItem[]): void {
		const newOffers: Offer[] = [];
		for (const item of requestItems) {
			const newOffer = this.createNewOfferForItem(requestId, item);
			newOffer.isToOrder = true;
			newOffers.push(newOffer);
		}
		this._newOffers.set(newOffers);
	}

	/**
	 * Delete offer in parameter from the list of offers
	 * @param offerToDelete
	 */
	public deletePendingOffer(offerToDelete: Offer): void {
		const newOffers = this._newOffers().filter(o => o !== offerToDelete);
		this._newOffers.set(newOffers);
	}

	/**
	 * Delete offer in parameter from the list of offers
	 * @param offerToDelete
	 */
	public deleteConfirmedOffer(offerToDelete: Offer): void {
		const editOffers = this._editedOffers().filter(o => o !== offerToDelete);
		this._editedOffers.set(editOffers);
	}

	/**
	 * Duplicate offer in parameter from the list of offers
	 * @param offerToDelete
	 * @returns the duplicated offer
	 */
	public duplicateOffer(offerToDuplicate: Offer, status: number): Offer {
		const duplicateOffer = { ...offerToDuplicate };
		duplicateOffer.createdAt = undefined!;
		duplicateOffer.updatedAt = undefined!;
		duplicateOffer.id = undefined!;

		switch (status) {
			case 1:
				const newOffers = [...this._newOffers(), duplicateOffer];
				this._newOffers.set(newOffers);
				break;
			case 3:
				// set to order just to make it editable
				duplicateOffer.isToOrder = true;

				// set batch updated to make it require validation from importer
				duplicateOffer.batchUpdatedAt = dayjs().toISOString();

				const editedOffers = [...this._editedOffers(), duplicateOffer];
				this._editedOffers.set(editedOffers);
				break;
			default:
				break;
		}

		return duplicateOffer;
	}

	/**
	 * Prefill offers with stock
	 *
	 * @refacto prefill offers that are directly managed by the offer service instead of new ones each time
	 */
	public async prefillOffersWithStock(
		requestItems: ImporterRequestItem[],
		setTotalItemsNumber: (length: number) => void,
		onProgressIncrement: () => void
	): Promise<void> {
		// Get response from API for stocks comparison
		const companyId = this._companySvc.currentCompany()!.id;
		const resp = await this._apiImporterSvc.compareAvailableStocks(this._currentRequest.id, companyId);

		// refacto: handle error at the API level
		if (!resp || !resp.ok) {
			console.error(`Failed to fetch available stocks for request ${this._currentRequest} and company ${companyId}`);
			return;
		}

		setTotalItemsNumber(resp.body?.length!);

		// Compare w/ our request's items, match them to a suitable candidate, and return a tuple for each match (skip if no match)
		const stockEntriesGroupedByOffers = requestItems
			.map(i => {
				const stockEntries = resp.body!.filter(s => s.packageCip13 === i.packageCip13);
				return stockEntries ? { item: i, stockEntries } : null;
			})
			.filter(x => x?.stockEntries.length !== 0);

		// Create offers for each match and store them in the state
		let offersPerProduct: Record<number, Offer[]> = toDictionarySet(this._newOffers(), o => o.packageCip13 ?? 0);

		for (const stockEntriesForOffer of stockEntriesGroupedByOffers) {
			const packageCip13 = stockEntriesForOffer!.item.packageCip13;

			for (const stockEntry of stockEntriesForOffer!.stockEntries) {
				const offersForCip13: Offer[] = [];

				offersForCip13.push({
					requestId: this._currentRequest.id,
					itemId: stockEntriesForOffer!.item.itemId,
					exporterId: this._companySvc.currentCompany()!.id,
					packageCip13: stockEntry.packageCip13,
					batchNumber: stockEntry.batch?.id,
					isToOrder: !stockEntry.batch?.id,
					expirationDate: stockEntry.batch?.expiration!,
					quantity: stockEntry.quantity,
					price: stockEntry.price!,
					exporterResponse: OfferResponseTypeEnum.None,
					importerResponse: OfferResponseTypeEnum.None,
				} as Offer);

				// https://github.com/MedHubCompany/MedHubPlace/issues/91
				// "When I do a prefill, I have at minimum one empty line with the quota information in quantity and as batch number "To order".
				// The price and expiration can be empty"

				if (stockEntry.quota && stockEntry.quota > 0) {
					const quotaOffer = this.createNewOfferForItem(this._currentRequest.id, stockEntriesForOffer!.item);
					quotaOffer.batchNumber = 'To order';
					quotaOffer.isToOrder = true;
					quotaOffer.quantity = stockEntry.quota;
					offersForCip13.push(quotaOffer);
				}

				// offers with the same packageCip13 must be replaced by the new offers
				// empty lines must be removed and replaced by the new offer
				if (
					offersPerProduct[packageCip13] &&
					offersPerProduct[packageCip13].length > 0 &&
					offersPerProduct[packageCip13][0].isToOrder
				)
					offersPerProduct[packageCip13] = [...offersPerProduct[packageCip13], ...offersForCip13];
				else offersPerProduct[packageCip13] = offersForCip13;

				setTimeout(() => onProgressIncrement(), 100);
			}
		}

		this._newOffers.set(toArray(offersPerProduct));
	}

	/**
	 * Fill the batch numbers of offers without batch number, and fill expiration date
	 */
	public async importBatches(): Promise<void> {
		// Get response from API for stocks comparison
		const companyId = this._companySvc.currentCompany()!.id;

		const resp = await this._apiImporterSvc.compareAvailableStocks(this._currentRequest.id, companyId);

		// refacto: handle error at the API level
		if (!resp || !resp.ok) {
			console.error(`Failed to fetch available stocks for request ${this._currentRequest} and company ${companyId}`);
			return;
		}

		const emptyBatchOffers: Offer[] = this.currentRequestOffers().filter(
			offer => offer.confirmationDate && !offer.batchNumber
		);

		// Group stock entrie's packages by CIP13
		const lastUpdatedPackages: { [key: number]: ExporterStockEntry[] } = resp.body!.reduce(
			(acc: { [key: number]: ExporterStockEntry[] }, packge) => {
				const cip13 = packge.packageCip13;
				if (acc[cip13]) acc[cip13].push(packge);
				else acc[cip13] = [packge];
				return acc;
			},
			{}
		);

		// fill the missing batch numbers of offers without batch number
		for (const emptyBatchOffer of emptyBatchOffers) {
			const cip13 = emptyBatchOffer.packageCip13 ?? 0;
			const foundPackages = lastUpdatedPackages[cip13];
			if (foundPackages) {
				const selectedPackage = OfferExtension.selectBatchNumberFromEntriesRule(foundPackages);

				if (selectedPackage) {
					emptyBatchOffer.batchNumber = selectedPackage.id!;
					emptyBatchOffer.expirationDate = selectedPackage.expiration;
				}
			}
		}
	}

	/**
	 * Add or update an offer
	 * @param offer
	 */
	public onOfferUpdatedOrAdded(offer: Offer): void {
		if (offer.requestId !== this._currentRequest.id)
			throw new Error('Cannot add or update offer with different request');

		if (!this._currentRequestPackage[offer.packageCip13])
			throw new Error('Cannot add an offer with a not existing item in the request');

		// set offer shorname
		offer.shortName = this._currentRequestPackage[offer.packageCip13].shortName ?? '';

		const offers = this.currentRequestOffers();

		// search the offer to replace
		let offerIndex = offers.findIndex(o => o.id === offer.id);

		// if it is a counter offer and that counter offer is not found, find the parent offer to replace
		const parentOfferId = (<CounterOffer>offer).parentOfferId;
		if (!!parentOfferId && offerIndex == -1) offerIndex = offers.findIndex(o => o.id === parentOfferId);

		// is there an offer to replace ?
		if (offerIndex != -1) {
			if (offer.packageCip13 !== offers[offerIndex].packageCip13)
				throw new Error('Cannot update offer with different package');

			// replace the offer
			this.currentRequestOffers.update(offers => {
				offers[offerIndex] = offer;
				return [...offers];
			});
			return;
		}
		// case: new offer
		else this.currentRequestOffers.update(offers => [...offers, offer]);
	}

	/**
	 * Send all offers to the server
	 */
	public async sendValidNewOffers(): Promise<void> {
		const offersToSend = this.newOffers().filter(offer => OfferExtension.isValidNewOffer(offer));

		await Promise.all(
			offersToSend.map(async offer => {
				const resp = await this._offerApiSvc.sendOffer(offer);
				if (!resp || !resp.ok) {
					console.error('Failed to send offer', offer);
					return;
				}

				// reset all new offers
				this.resetEditedOffers(this._currentRequest.id, this._currentRequest.items);
			})
		);
	}

	/**
	 * Order the list of offers
	 */
	async orderOffers() {
		await Promise.all(
			this.confirmedOffers()
				.filter(offer => OfferExtension.isReadyToOrder(offer) && !OfferExtension.isOfferDeclined(offer))
				.map(offer => this._offerApiSvc.orderOffer(offer.id))
		);
	}

	/**
	 * Order the list of offers
	 */
	public editOffers(): void {
		const editedOffers: Offer[] = [];

		for (const offer of this.confirmedOffers()) {
			editedOffers.push({ ...offer });
		}

		this._editedOffers.set(editedOffers);
	}

	/**
	 * Cancel editing offers
	 */
	public cancelEditingOffers() {
		this._editedOffers.set([]);
	}

	/**
	 * Validate edited offers, reset edited offers and do a refresh of offers from API
	 */
	public async validateEditedOffers(): Promise<void> {
		await Promise.all(
			this.editedOffers().map(offer => {
				// send the offer to update it
				this._offerApiSvc.sendOffer(offer);

				// set as confirmed if there is a batch number to increase the quantity in stocks
				if (offer.batchNumber && offer.isToOrder) {
					this._offerApiSvc.sendToPurchaseOffer(offer.id);
				}
			})
		);
		this._editedOffers.set([]);
	}

	/**
	 * Validate an offer for shpping
	 */
	public async validateBatch(offer: Offer): Promise<void> {
		this._offerApiSvc.validateBatch(offer.id);
	}

	/**
	 * Book the list of offers
	 */
	public async purchaseOffers(offersToPurchase: Offer[]): Promise<void> {
		await Promise.all(offersToPurchase.map(offer => this._offerApiSvc.sendToPurchaseOffer(offer.id)));
	}
}
