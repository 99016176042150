import { HttpInterceptorFn } from '@angular/common/http';

/**
 * Interceptor to add credentials to the request
 */

export const CredentialsInterceptor: HttpInterceptorFn = (req, next) => {
	req = req.clone({
		withCredentials: true,
	});

	return next(req);
};
