import { Component, input } from '@angular/core';
import { MhpSvgIconComponent } from '../mhp-svg-icon/mhp-svg-icon.component';
import { ToCipPipe } from "../../pipes/to-cip/to-cip.pipe";

@Component({
	selector: 'app-mhp-package',
	standalone: true,
	imports: [
		MhpSvgIconComponent,
		ToCipPipe
	],
	templateUrl: './mhp-package.component.html',
	styleUrl: './mhp-package.component.scss'
})
export class MhpPackageComponent {
	public readonly package = input.required<{ cip13: number, shortName: string | null }>()
}
