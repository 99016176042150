import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SignalRClientBase } from '../signalr/signalr.client.base';
import { ImporterRequest } from '../../models/importer-request.model';
import { filter, Subject } from 'rxjs';
import { Offer } from '../../models/offer.model';
import { RequestEventDispatcher } from './request.event.dispatcher';

@Injectable({
	providedIn: 'root',
})
export class RequestService extends SignalRClientBase {
	private readonly _offerEvent = new Subject<Offer>();

	constructor() {
		super(environment.API_URL + '/hub/request');

		// handle request events
		this._hubConnection.on('OnOfferEvent', (offer: Offer) => {
			this._offerEvent.next(offer);
		});
	}

	public async getRequestAndSubscribeToChanges(requestId: string): Promise<RequestEventDispatcher> {
		await this.getConnectionPromise;

		const request = await this._hubConnection.invoke<ImporterRequest>('GetRequestAndSubscribeToChanges', requestId);

		const newRequestEventDispatcher = new RequestEventDispatcher(
			this._offerEvent.pipe(filter(o => o.requestId == requestId)),
			request
		);

		return newRequestEventDispatcher;
	}

	public async unsubscribeToRequestChanges(requestId: string): Promise<void> {
		await this.getConnectionPromise;

		await this._hubConnection.invoke<void>('UnsubscribeToRequestChanges', requestId);
	}
}
