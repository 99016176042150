/**
 * Status of offers
 * @remark set to lowercase for practial usage of direct use in url
 */
export enum OfferStatusEnum {
	pending = 0,
	review = 1,
	booked = 2,
	ordered = 3,
}
