<div class="update-screen">

	<div id="header" class="header">
		<div id="header-left">
			@if (!this.props().step) {
				<app-mhp-button text="Cancel" type="secondary" [routerLink]="this.sitemap.stockOffer.route" />
			} @else {
				<app-mhp-button text="Back" type="secondary" (click)="previousStep()" />
			}
		</div>

		<div id="header-right">
			@switch (this.props().step) {
				@default {
					<app-mhp-button text="Continue" type="primary" (click)="nextStep()" [hidden]="!file()" />
				}	@case (1) {
					<app-mhp-button text="Confirm the import" type="primary" (click)="submit()" [hidden]="!this.entries().length" />
				}
			}
		</div>
	</div>

	@switch (this.props().step) {
		@default {
			<div class="main-container max-w-2xl">
				<h2>Import my stock</h2>

				<app-mhp-file-uploader
					[(file)]="this.file"
					(fileChange)="processFile($event);"
					[acceptedFiles]="this._tableSvc.acceptedFiles"
				/>

				<ul class="helper-links">
					<li><a href="assets/download/update-stock.template.csv" download="stock.csv">Download the template</a></li>
				</ul>
			</div>
		}

		@case	(1) {
			<div class="main-container">
				<h2>Review my stock</h2>

				<div class="table-container">
					<table class="stock-editor">
						<thead>
							<tr>
								<th>#</th>
								<th>Product</th>
								<th>Batch</th>
								<th>Expiration date</th>
								<th>Quantity</th>
								<th>Unit cost</th>
								<th>Actions</th>
							</tr>
						</thead>

						<tbody>
							@for (entry of this.entries(); track $index;) {

								<tr>
									<td class="p-4">{{ $index + 1 }}</td>
									<td class="p-4">
										<app-mhp-package [package]="{ cip13: entry.packageCip13, shortName: entry.batch!.name ?? null }" />
									</td>
									<td class="text-center">
										<input
											id="package-{{$index}}-batchNumber"
											[class.border-red-500]="this.checkErrorCell(entry, $index)"
											type="text"
											[value]="entry.batch!.id"
											[(ngModel)]="entry.batch!.id"
										/>
									</td>
									<td class="text-center">
										<input
											id="package-{{$index}}-expiration"
											[class.border-red-500]="this.checkErrorCell(entry, $index)"
											type="text"
											[value]="entry.batch!.expiration"
											[(ngModel)]="entry.batch!.expiration!"
										/>
									</td>
									<td class="text-center">
										<input
											id="package-{{$index}}-quantity"
											[class.border-red-500]="this.checkErrorCell(entry, $index)"
											type="text"
											[value]="entry.quantity"
											[(ngModel)]="entry.quantity"
										/>
									</td>
									<td class="text-center">
										<input
											id="package-{{$index}}-price"
											[class.border-red-500]="this.checkErrorCell(entry, $index)"
											type="text"
											[value]="entry.price"
											[(ngModel)]="entry.price"
										/>
									</td>
									<td class="actions">
										<button name="delete" title="Remove from list" (click)="this.deleteItem($index)">
											<ng-icon name="saxTrashOutline" />
										</button>
									</td>
								</tr>
							}
						</tbody>
					</table>
				</div>
			</div>
		}
	}
</div>
