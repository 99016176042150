import { Component, input, model } from '@angular/core';
import { MHPButton } from './mhp-button.interface';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'app-mhp-button-group',
	standalone: true,
	imports: [CommonModule],
	styleUrl: './mhp-button-group.component.scss',
	templateUrl: './mhp-button-group.component.html',
})
export class MhpButtonGroupComponent<T> {
	public readonly buttons = input<MHPButton<T>[]>([]);
	public readonly choice = model<T>();
	public choose(choice: T): void {
		this.choice.set(choice);
	}
}
