import { CommonModule } from '@angular/common';
import { Component, computed, inject, input, model, output, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxBuildingsOutline, saxSearchNormal1Outline } from '@ng-icons/iconsax/outline';

import { Company } from '../../models/company.model';
import { MhpCompanyComponent } from '../mhp-company/mhp-company.component';
import { MhpSvgIconComponent } from '../mhp-svg-icon/mhp-svg-icon.component';
import { saxCloseCircleBold, saxTickCircleBold } from '@ng-icons/iconsax/bold';
import { CompanyService } from '../../services/company/company.service';

@Component({
	selector: 'app-mhp-company-selector',
	standalone: true,
	imports: [CommonModule, FormsModule, NgIconComponent, MhpCompanyComponent, MhpSvgIconComponent],
	providers: [
		provideIcons({
			saxBuildingsOutline,
			saxCloseCircleBold,
			saxSearchNormal1Outline,
			saxTickCircleBold,
		}),
		provideNgIconsConfig({ size: '1rem' }),
	],
	styleUrl: './mhp-company-selector.component.scss',
	templateUrl: './mhp-company-selector.component.html',
})
export class MhpCompanySelectorComponent {
	private readonly _companySvc = inject(CompanyService);

	public readonly description = input('');
	public readonly multipleSelect = input(true);
	public readonly includeOwn = input(false);
	public readonly selected = model<Company[]>([]);

	protected readonly companies = computed(() => {
		if (this.includeOwn()) {
			return this._companySvc.allCompanies();
		} else {
			return this._companySvc.allCompanies().filter(c => c.id !== this._companySvc.currentCompany()!.id);
		}
	});

	protected searchField: string | null = null;
	protected readonly companySearchResult = signal<Company[]>([]);
	protected readonly companyDisplay = computed(() =>
		this.companySearchResult().length ? this.companySearchResult() : this.companies()
	);

	protected isCompanySelected(cid: number) {
		return this.selected().some(c => c.id === cid);
	}

	protected toggleSelect(company: Company): void {
		if (!this.multipleSelect()) {
			this.selected.set([company]);
			return;
		}

		const isSelected = this.isCompanySelected(company.id);

		if (isSelected) {
			this.selected.update(selected => selected.filter(c => c.id !== company.id));
		} else {
			this.selected.update(selected => [...selected, company]);
		}
	}

	protected search(): void {
		this.companySearchResult.set(
			// TODO
			// In the case it is assumes displayName and searchField are not null, then they must be made as not nullable.
			// Otherwise the search function could throw a null exception. General usage of ! reduce the robustness of our
			// application.
			this.companies().filter(item => item.displayName!.toLowerCase().includes(this.searchField!.toLowerCase()))
		);
	}
}
