<div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" aria-hidden="true"></div>

  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div
        class="p-6 relative flex flex-col flex-start items-start transform overflow-hidden rounded-2xl bg-white dark:bg-gray-800 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
      >
        <div class="mb-8">
          <button
            class="flex flex-row mb-6 justify-center item-center"
            (click)="this.close()"
          >
            <app-mhp-svg-icon class="dark:stroke-white" icon="close" />
          </button>

          <h3 class="text-xl font-semibold text-gray-900 dark:text-white">Making a counter-offer</h3>
        </div>

        <div class="mb-8">
          <app-mhp-from-to [from]="this.user()!" [to]="[this.exporter()]" />
        </div>

        <div class="flex flex-col w-full space-y-6">
          <div class="flex flex-col">
            <span class="mb-4 text-sm text-gray-500">Products</span>
            <div class="flex flex-row items-center">
              <div class="flex justify-center items-center h-11 w-11 mr-4 border rounded-full shrink-0 dark:border-gray-700">
                <app-mhp-svg-icon icon="pill" />
              </div>

              @if (this.item().package) {
                <span class="mr-4 text-gray-900 dark:text-white">{{ this.item().package.shortName }}</span>
              }
            </div>
          </div>

          <div class="flex flex-col">
            <div class="flex flex-row items-center mb-4">
              <span class="w-full text-sm text-gray-500">Quantity</span>
              <app-mhp-label class="shrink-0" label="Requested: {{ this.item().quantity }}" />
            </div>
            <app-mhp-input
              [min]="1"
              [stepper]="true"
              suffix="Units"
              type="number"
              [(value)]="this.quantity"
            />
          </div>

          <div class="flex flex-col">
            <div class="flex flex-row items-center mb-4">
              <span class="w-full text-sm text-gray-500">Unit price</span>
              <app-mhp-label class="shrink-0" label="Requested: {{ this.item().price | currency : 'EUR' : 'symbol' : '.2-2' }}" />
            </div>
            <app-mhp-input
              [min]="1"
              [stepper]="true"
              suffix="€"
              type="number"
              [(value)]="this.price"
            />
          </div>

          <!-- [TODO]: Preview to working on comment when is necessary -->
          <!-- <div class="flex flex-col">
            <span class="mb-4 text-sm text-gray-500">Comments (optional)</span>
            <app-mhp-input
              placeholder="Enter your comment..."
              type="textarea"
            />
          </div> -->

          <app-mhp-button
            prefix="saxSend2Outline"
            size="large"
            text="Confirm my offer"
            (click)="this.submit()"
          />
        </div>
      </div>
    </div>
  </div>
</div>
