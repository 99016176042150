<div class="main-package-request-product-modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" aria-hidden="true"></div>

  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div class="main-package-request-product-modal-container">
        @switch (this.step) {
          @case (1) {
            <div class="mb-8">
              <button
                class="flex flex-row mb-6 justify-center item-center"
                (click)="this.close()"
              >
                <app-mhp-svg-icon class="dark:stroke-white" icon="close" />
              </button>

              <h3 class="text-xl font-semibold text-gray-900 dark:text-white">Request product</h3>
            </div>

            <div class="mb-8">
              <app-mhp-from-to [from]="this.user()!" [to]="[this.exporter()]" />
            </div>

            <div class="flex flex-col w-full space-y-6">
              <div class="flex flex-col">
                <span class="mb-4 text-sm text-gray-500">Product</span>
                <div class="flex flex-row items-center">
                  <div class="flex justify-center items-center h-11 w-11 mr-4 border rounded-full shrink-0 dark:border-gray-700">
                    <app-mhp-svg-icon icon="pill" />
                  </div>
                  <div class="flex flex-col font-medium mr-4">
                    <span class="text-sm text-gray-900 dark:text-white">{{ product().shortName }}</span>
                    <span class="text-sm text-gray-500">{{ product().cip13 | toCip : 13 }}</span>
                  </div>
                  <!-- <app-mhp-label label="In demand" class="shrink-0" /> -->
                </div>
              </div>

              <div class="flex flex-col">
                <span class="mb-4 text-sm text-gray-500">Number required</span>
                <app-mhp-input
                  [min]="1"
                  [stepper]="true"
                  suffix="Units"
                  type="number"
                  [(value)]="this.quantity"
                />
              </div>

              <div class="flex flex-col">
                <span class="mb-4 text-sm text-gray-500">Unit price</span>
                <app-mhp-input
                  [min]="0"
                  [stepper]="true"
                  suffix="€"
                  type="number"
                  [(value)]="this.price"
                />
              </div>

							<!-- Hidden as requested in https://github.com/MedHubCompany/MedHubPlace/issues/158 -->

<!--                 <div class="flex flex-col"> -->
<!--                   <span class="mb-4 text-sm text-gray-500">Comments (optional)</span> -->
<!--                   <app-mhp-input -->
<!--                     placeholder="Enter your comment..." -->
<!--                     type="textarea" -->
<!--                   /> -->
<!--                 </div> -->

              <app-mhp-button
                prefix="saxSend2Outline"
                size="large"
                text="Send my request"
                (click)="this.submit()"
              />
            </div>
          }

          @case (2) {
            <div class="mb-6">
              <button
                class="flex flex-row justify-center item-center"
                (click)="this.close()"
              >
                <app-mhp-svg-icon class="dark:stroke-white" icon="close" />
              </button>
            </div>

            <div class="flex flex-col justify-center items-center">
              <div class="flex flex-row justify-center items-center mb-6 h-14 w-14 rounded-full bg-blue-100 dark:bg-blue-900">
                <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path id="Vector" d="M1.5 5.97499L6.57693 10.6654L17.5 1.33203" stroke="#3970FF" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>

              <span class="mb-4 text-center text-xl font-semibold text-gray-900 dark:text-white">A request offer has been created </span>
              <span class="mb-8 text-center text-gray-500">You will be notified as soon as the exporter responds positively or negatively to your request.</span>

              <app-mhp-button
                class="mb-8 w-full"
                prefix="saxEyeOutline"
                size="large"
                text="See the request"
                type="secondary"
                [routerLink]="[ this.sitemap.request.route, this.requestId() ]"
              />

              <a
                class="cursor-pointer text-sm text-gray-900 dark:text-white"
                (click)="this.close()"
              >Go back to the search results page</a>
            </div>
          }
        }
      </div>
    </div>
  </div>
</div>
