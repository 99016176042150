import { Component, computed, inject, input } from '@angular/core';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
	saxArrowDown1Outline,
	saxArrowUp2Outline,
	saxArrowUp3Outline,
	saxMessageOutline,
	saxTrashOutline,
} from '@ng-icons/iconsax/outline';
import { saxCloseCircleBold, saxTickCircleBold } from '@ng-icons/iconsax/bold';
import { bootstrapCopy } from '@ng-icons/bootstrap-icons';

import { ToCipPipe } from '../../../_common/pipes/to-cip/to-cip.pipe';
import { MhpButtonComponent } from '../../../_common/components/mhp-button/mhp-button.component';
import { Company } from 'src/app/_common/models/company.model';
import { MhpCompanyComponent } from '../../../_common/components/mhp-company/mhp-company.component';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { User } from 'src/app/_common/models/user.model';
import { UserFunctionEnum } from 'src/app/_common/enums/user-function.enum';

import { ImporterRequestItem } from '../../../_common/models/importer-request-item.model';
import { Offer } from '../../../_common/models/offer.model';
import { CompanyService } from '../../../_common/services/company/company.service';
import { Package } from '../../../_common/models/package.model';
import { OfferResponseTypeEnum } from 'src/app/_common/enums/offer-response-type.enum';
import {
	MainRequestAcceptDeclineOfferModalComponent,
	ModalResponseTypeEnum,
} from '../main-request-accept-decline-offer-modal/main-request-accept-decline-offer-modal.component';
import { MainRequestMakeOfferModalComponent } from '../main-request-make-offer-modal/main-request-make-offer-modal.component';
import { MhpSvgIconComponent } from 'src/app/_common/components/mhp-svg-icon/mhp-svg-icon.component';
import { xor } from 'src/app/_common/utils/xor.util';
import { MainRequestOfferHistoryComponent } from '../main-request-offer-history/main-request-offer-history.component';
import { MainRequestConfirmOffersComponent } from '../main-request-confirm-offers/main-request-confirm-offers.component';
import { CounterOffer } from 'src/app/_common/models/counter-offer.model';
import { OffersService } from 'src/app/_common/services/offers/offers.service';
import { toDictionary } from 'src/app/_common/utils/toDictionary';
import { OfferExtension } from 'src/app/_common/extensions/offer.extension';
import { OfferStatusEnum } from 'src/app/_common/enums/offer-status.enum';
import { MhpPackageComponent } from '../../../_common/components/mhp-package/mhp-package.component';

@Component({
	selector: 'app-main-request-item-per-company',
	standalone: true,
	imports: [
		CommonModule,
		FormsModule,
		NgIconComponent,
		ToCipPipe,
		MhpButtonComponent,
		MhpCompanyComponent,
		MhpSvgIconComponent,
		MainRequestAcceptDeclineOfferModalComponent,
		MainRequestMakeOfferModalComponent,
		MainRequestOfferHistoryComponent,
		MainRequestConfirmOffersComponent,
		MhpPackageComponent,
	],
	providers: [
		provideIcons({
			saxArrowDown1Outline,
			saxArrowUp2Outline,
			saxArrowUp3Outline,
			saxCloseCircleBold,
			saxMessageOutline,
			saxTickCircleBold,
			saxTrashOutline,
			bootstrapCopy,
		}),
		provideNgIconsConfig({ size: '1rem' }),
	],
	styleUrl: './main-request-item-per-company.component.scss',
	templateUrl: './main-request-item-per-company.component.html',
})
export class MainRequestItemPerCompanyComponent {
	private readonly _accountSvc = inject(AccountService);
	private readonly _companySvc = inject(CompanyService);
	private readonly _offersSvc = inject(OffersService);

	public readonly OfferResponseTypeEnum = OfferResponseTypeEnum;
	public readonly ModalResponseTypeEnum = ModalResponseTypeEnum;
	public OfferStatusEnum = OfferStatusEnum;
	public readonly userFunctions = UserFunctionEnum;
	public readonly xor = xor;

	public readonly exporter = input.required<Company>();
	public readonly items = input.required<ImporterRequestItem[]>();
	public readonly selectedStatus = input.required<OfferStatusEnum>();
	public readonly offers = input.required<Offer[]>();
	public readonly editMode = input<boolean>(false);
	public readonly getOfferLabel: (o: Offer) => string = (offer: Offer) => OfferExtension.getOfferLabel(offer);

	public readonly offersSortedByName = computed(() =>
		this.offers().sort((o1, o2) => o1.shortName?.localeCompare(o2.shortName))
	);

	public readonly currentCompany = computed(() => this._companySvc.currentCompany());
	public readonly exporters = computed(() => this._companySvc.allCompanies());
	public readonly packages = computed<Record<number, Package>>(() =>
		toDictionary(
			this.items().map(i => i.package),
			p => p.cip13
		)
	);
	public readonly user = computed<User | null>(this._accountSvc.user);

	public readonly readyToPurchaseOffers = computed<Offer[]>(() =>
		this.offers().filter(o => OfferExtension.isReadyToPurchase(o))
	);

	public readyToOrderOffers = computed(() =>
		this._offersSvc
			.confirmedOffers()
			.filter(o => OfferExtension.isReadyToOrder(o) && !OfferExtension.isOfferDeclined(o))
	);

	// public readonly readyToBookedOffers = computed<Record<number, Offer[]>>(() => {
	// 	const readyToBookOffers = this.offers().filter(o => this._offersSvc.isReadyToBook(o));
	// 	return toDictionarySet(readyToBookOffers, o => o.exporterId);
	// });

	public isReadyToPurchase(offer: Offer): boolean {
		return OfferExtension.isReadyToPurchase(offer);
	}

	public isReadyToOrder(offer: Offer): boolean {
		return OfferExtension.isReadyToOrder(offer);
	}

	public isAccepted(offer: Offer): boolean {
		return OfferExtension.isOfferAccepted(offer, this.user()!.function);
	}

	public isAcceptedByImporter(offer: Offer): boolean {
		return OfferExtension.checkImporterResponseIsAccepted(offer);
	}

	public isOfferDeclined(offer: Offer): boolean {
		return OfferExtension.isOfferDeclined(offer);
	}

	public counterOfferVisible(offer: Offer): boolean {
		return OfferExtension.isCounterOfferPossible(offer, this.user()!.function);
	}

	public get offerCountDisplay(): number {
		return this.offers().length;
	}

	public acceptOfferModal: boolean = false;
	public declineOfferModal: boolean = false;
	public makeOfferModal: boolean = false;
	public purchaseOffersModal: Offer[] = [];
	public orderOffersModal: boolean = false;
	public offerHistoryPanelDisplayed: boolean = false;
	public expanded: boolean = true;
	public exporterSelected: Company | null = null;
	public offerSelected: Offer | undefined = undefined;

	constructor() {}

	public displayOfferHistoryPanel = () => (this.offerHistoryPanelDisplayed = true);

	public getItemByCIP13 = (cip13: number) => this.items().find(i => i.packageCip13 === cip13);

	castToCounterOffer(offer: Offer): CounterOffer {
		return offer as CounterOffer;
	}

	/**
	 * Make a duplicate of the offer in parameter and then update the offers list
	 * @param offerToDuplicate
	 */
	duplicateOffer(offerToDuplicate: Offer): void {
		this._offersSvc.duplicateOffer(offerToDuplicate, 3);
	}

	/**
	 * Delete the offer from the list
	 */
	deleteOffer(offerToDelete: Offer): void {
		this._offersSvc.deleteConfirmedOffer(offerToDelete);
	}

	/**
	 * Return true if the offer is editable
	 */
	public isOfferEditable(offer: Offer): boolean {
		return this.editMode() && (offer.isToOrder || !offer.createdAt) && !OfferExtension.isOfferDeclined(offer);
	}

	/**
	 * Validate for shipping the offer in parameter
	 * @param offer
	 */
	public validateBatch(offer: Offer) {
		return this._offersSvc.validateBatch(offer);
	}

	public isWaitingForBatch(offer: Offer) {
		return OfferExtension.isWaitingForBatch(offer);
	}

	public async purchaseOffers(): Promise<void> {
		await this._offersSvc.purchaseOffers(this.purchaseOffersModal);
		this.purchaseOffersModal = [];
	}

	/**
	 * Order the offers
	 */
	public async orderOffers(): Promise<void> {
		await this._offersSvc.orderOffers();
	}
}
