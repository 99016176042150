import { OfferResponseTypeEnum } from '../enums/offer-response-type.enum';
import { UserFunctionEnum } from '../enums/user-function.enum';
import { CounterOffer } from '../models/counter-offer.model';
import { ExporterStockEntry } from '../models/exporter-stock-entry.model';
import { Offer } from '../models/offer.model';
import { PackageBatch } from '../models/package-batch.model';

export class OfferExtension {
	/**
	 * Returns the label to display for batch number
	 */
	public static getOfferLabel(offer: Offer): string {
		// if there is a batch number, displlay it even if it is to order in case the batch
		// number has been set after the offer has been made
		return offer.batchNumber ? offer.batchNumber : offer.isToOrder ? 'To order' : '';
	}

	/**
	 * Check if the offer is accepter by the user
	 */
	public static isOfferAccepted(offer: Offer, userFunctions: UserFunctionEnum): boolean {
		if (userFunctions === UserFunctionEnum.Importer) return this.checkImporterResponseIsAccepted(offer);
		if (userFunctions === UserFunctionEnum.Exporter) return this.checkExporterResponseIsAccepted(offer);
		return false;
	}

	/**
	 * Check if the offer is accepted by the exporter
	 */
	public static checkImporterResponseIsAccepted(offer: Offer) {
		return offer.importerResponse === OfferResponseTypeEnum.Accepted;
	}

	/**
	 * Check if the offer is accepted by the importer
	 */
	public static checkExporterResponseIsAccepted(offer: Offer) {
		return offer.exporterResponse === OfferResponseTypeEnum.Accepted;
	}

	/**
	 * Check if the offer is ready to be purchased
	 */
	public static isReadyToPurchase(offer: Offer): boolean {
		return this.checkExporterResponseIsAccepted(offer) && this.checkImporterResponseIsAccepted(offer);
	}

	/**
	 * Business rule for selecting a batch number from a list of stock entries
	 * @returns The batch number of the first entry
	 */
	public static selectBatchNumberFromEntriesRule(entries: ExporterStockEntry[]): PackageBatch | null {
		// take the first entry of the list
		return entries.filter((e: ExporterStockEntry) => e.batch)[0]?.batch ?? null;
	}

	/**
	 * Check if the offer is declines by the exporter
	 */
	public static checkImporterResponseIsDeclined(offer: Offer) {
		return offer.importerResponse === OfferResponseTypeEnum.Declined;
	}

	/**
	 * Check if the offer is ready to be booked
	 */
	public static isReadyToOrder(offer: Offer): boolean {
		return !this.isWaitingForBatch(offer) && (!!offer.importerValidatedBatchAt || !offer.batchUpdatedAt);
	}

	/**
	 * Check if the offer is waiting for a batch number
	 */
	public static isWaitingForBatch(offer: Offer): boolean {
		return !!offer.confirmationDate && (!offer.expirationDate || offer.quantity == 0 || !offer.batchNumber);
	}

	/**
	 * Check if the offer is declined
	 */
	public static isOfferDeclined(offer: Offer): boolean {
		return (
			offer.exporterResponse === OfferResponseTypeEnum.Declined ||
			offer.importerResponse === OfferResponseTypeEnum.Declined
		);
	}

	/**
	 * Check if the new offer is valid for sending
	 */
	public static isValidNewOffer(o: Offer): boolean {
		return !o.createdAt && !!(o.batchNumber || o.isToOrder) && o.quantity > 0;
	}

	/**
	 * Determines if counter offer button should be displayed
	 * If the offer is not accepted by the current user, but accepted by other party
	 * then he should be able to make a counter offer
	 */
	public static isCounterOfferPossible(offer: Offer, userFunction: UserFunctionEnum): boolean {
		const otherFunction: UserFunctionEnum =
			userFunction === UserFunctionEnum.Importer ? UserFunctionEnum.Exporter : UserFunctionEnum.Importer;
		const isAcceptedByCurrentUser = this.isOfferAccepted(offer, userFunction);
		const isAcceptedByOtherParty = this.isOfferAccepted(offer, otherFunction);
		return !isAcceptedByCurrentUser && isAcceptedByOtherParty;
	}

	/** If the offer state has changed then it is in review */
	public static offerStateHasChanged(offer: Offer): boolean {
		const isCounterOffer = (<CounterOffer>offer).parentOfferId;
		const isAcceptedByImporter = this.checkImporterResponseIsAccepted(offer);
		const isDeclinedByImporter = this.checkImporterResponseIsDeclined(offer);
		return !!isCounterOffer || isAcceptedByImporter || isDeclinedByImporter;
	}

	public static isInPending(offer: Offer, userFunction: UserFunctionEnum): boolean {
		return !OfferExtension.offerStateHasChanged(offer) && userFunction == UserFunctionEnum.Importer;
	}

	public static isInReview(offer: Offer, userFunction: UserFunctionEnum): boolean {
		return userFunction == UserFunctionEnum.Importer
			? !offer.confirmationDate && OfferExtension.offerStateHasChanged(offer)
			: !offer.confirmationDate;
	}

	public static isInConfirmed(offer: Offer): boolean {
		return !!offer.confirmationDate && !offer.orderingDate;
	}

	public static isInShipping(offer: Offer): boolean {
		return !!offer.orderingDate;
	}
}
