<div class="mhp-request">

	@if (this.request) {
		<div class="flex flex-col">
			<div class="flex flex-col w-full mb-6">
				<div class="flex flex-row items-center mb-6 text-gray-500">
					<a [routerLink]="[ '/dashboard' ]">MedHubPlace</a>
					<ng-icon class="mx-2 mt-1" name="saxArrowRight3Outline" />
					<a>Request #{{ this.displayedId }}</a>
				</div>

				<div class="flex flex-col space-y-4 md:space-y-0 md:flex-row">
					<div class="flex flex-row items-center w-full">
						<h2 class="mr-4 text-4xl font-semibold text-gray-900 dark:text-white">
							@switch (this.request.type) {
								@case (this.RequestTypeEnum.PurchaseOrder) { Purchase order }
								@case (this.RequestTypeEnum.RequestOffer) { Request Offer }
							}
						</h2>
						<app-mhp-status-label [status]="3" />
					</div>
					<div class="flex flex-row text-right shrink-0 space-x-4 justify-end">
						<!-- <app-mhp-button prefix="saxDocumentTextOutline" text="View details" type="tertiary" /> -->
						<!-- <app-mhp-button prefix="saxAddOutline" text="Add a product" type="tertiary" /> -->
					</div>
				</div>
			</div>

			<div class="flex mb-8 flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-6 text-gray-900 dark:text-white">
				<!-- <div class="flex flex-col items-center w-auto p-6 rounded-2xl backdrop-blur-xl space-y-6 lg:mb-0 lg:my-0 lg:w-full bg-white border border-gray-200 dark:bg-gray-800 dark:border-gray-700">
					<div class="flex flex-row w-full">
						<span class="font-semibold w-full">Fulfilment of this request</span>
						<app-mhp-progressbar
							class="w-32 shrink-0"
							[progress]="0"
							[showStats]="true"
						/>
					</div>
					<div class="flex flex-row w-full items-center">
						<div class="flex flex-row w-full space-x-4">
							<div class="flex flex-row justify-center items-center shrink-0 w-10 h-10 rounded-full border border-gray-200 dark:border-gray-700">
								<ng-icon name="saxBuildingsOutline" />
							</div>
							<div class="flex flex-col font-medium">
								<span class="text-sm">R3S offers {{ 230 }}/{{ 300 }} products.</span>
								<span class="text-sm">There are {{ 70 }} references missing.</span>
							</div>
							<div class="flex flex-col font-medium">
								<span class="text-sm">Be patient, offers will come soon.</span>
							</div>
						</div>
						<app-mhp-button
							class="shrink-0"
							text="See missing products"
							type="secondary"
						/>
					</div>
				</div> -->
				<!-- <div class="flex flex-col items-center w-auto p-6 rounded-2xl backdrop-blur-xl space-y-6 lg:mb-0 lg:my-0 lg:w-full bg-white border border-gray-200 dark:bg-gray-800 dark:border-gray-700">
					<div class="flex flex-row w-full space-x-4">
						<span class="font-semibold w-full">Estimated cost</span>
						<app-mhp-button
							class="-mt-2 shrink-0"
							prefix="saxArrowDown1Outline"
							text="All exporters"
							type="tertiary"
						/>
						<app-mhp-button
							class="-mt-2 shrink-0"
							prefix="saxArrowDown1Outline"
							text="Booked products"
							type="tertiary"
						/>
					</div>
					<div class="flex flex-row w-full">
						<span class="text-3xl font-semibold">{{ 1000000 | currency : 'EUR': 'symbol' : '.0-2' }}</span>
					</div>
				</div> -->
			</div>

			<div class="flex flex-row items-center mb-8">
				<div class="flex flex-row w-full">
					<app-mhp-button-group
						[buttons]="this.statusLabel"
						[choice]="this.selectedStatus"
						(choiceChange)="this.gotoTab($event, true)"
					/>
				</div>

				<div class="flex flex-row shrink-0 space-x-4">
					@if (
						this.user()?.function === this.UserFunctionEnum.Exporter
						&& this.selectedStatus === OfferStatusEnum.pending
					) {
						<app-mhp-button
							class="shrink-0"
							prefix="saxImportOutline"
							text="Pre-fill with my stock"
							type="tertiary"
							(click)="this.requestPreFillConfirmModal = true"
						/>

						<app-mhp-button
							class="shrink-0"
							prefix="saxSend2Outline"
							text="Send offer ({{ validNewOffersAmount }} products)"
							type="primary"
							(click)="this.sendOfferConfirmModal = true"
							[disabled]="validNewOffersAmount === 0"
						/>
					}

					@else if(this.selectedStatus === OfferStatusEnum.booked) {
						@if(this.user()?.function === this.UserFunctionEnum.Exporter) {
							@if ( this.editedOffers().length == 0 ) {
								<!-- Edit batches -->
								<app-mhp-button
										class="shrink-0"
										prefix="saxImportOutline"
										text="Edit batches"
										type="primary"
										(click)="this.editBatches()"
								/>
							}

							@else {
								<!-- Validate button -->
								<app-mhp-button
									class="shrink-0"
									text="Validate"
									type="primary"
									(click)="this.validateEditedOffers()"
								/>

								<!-- Cancel button -->
								<app-mhp-button
									class="shrink-0"
									text="Cancel"
									type="secondary"
									(click)="this.cancelEdit()"
								/>
							}
						}
					}
				</div>
			</div>

			<div class="flex flex-col space-y-4">
				@switch (this.selectedStatus) {
					@case (OfferStatusEnum.pending) {
						@for (item of this.request.items; track item.itemId) {
							@let offersForProduct = this.pendingOffersPerProduct()[item.packageCip13] || [];

							<app-main-request-item-per-product
								[item]="item"
								[requestId]="this.request.id"
								[offers]="offersForProduct"
							/>
						}

						@empty {
							<div class="flex flex-col items-center justify-center p-6">
								<app-mhp-svg-icon icon="empty-product" />
								<p class="mt-4 text-gray-500">You haven't received any offers yet</p>
							</div>
						}
					}

					@case (OfferStatusEnum.review) {
						<app-main-request-item-per-company
							[items]="this.request.items"
							[exporter]="this.company"
							[offers]="this.inreviewOffers()"
							[selectedStatus]="+this.selectedStatus"
						/>
					}

					@case (OfferStatusEnum.booked) {
						<app-main-request-item-per-company
							[items]="this.request.items"
							[exporter]="this.company"
							[offers]="this.editedOffers().length > 0 ? this.editedOffers() : this.confirmedOffers()"
							[editMode]="this.editedOffers().length > 0"
							[selectedStatus]="+this.selectedStatus"
						/>
					}

					@case (OfferStatusEnum.ordered) {
						<app-main-request-item-per-company
							[items]="this.request.items"
							[exporter]="this.company"
							[offers]="this.orderedOffers()"
							[selectedStatus]="+this.selectedStatus"
						/>
					}
				}
			</div>
		</div>
	}

	@else {
		The request you are looking for is not available.
	}

</div>

@if (this.requestPreFillConfirmModal && this.user()?.function === this.UserFunctionEnum.Exporter) {
	<app-main-request-pre-fill-confirm-modal
		[(isOpen)]="this.requestPreFillConfirmModal"
		[(response)]="this.prefillConfirmation"
	/>
}

@if (this.sendOfferConfirmModal && this.user()?.function === this.UserFunctionEnum.Exporter) {
	<app-main-request-send-offer-confirm-modal
		[exporter]="this.request.importer"
		[request]="this.request"
		[validOffersCount]="this.validNewOffersAmount"
		[(isOpen)]="this.sendOfferConfirmModal"
		(submit)="this.onOffersSent()" />
}

@if ( this.isImporting() ) {
	<offers-loading
		[importingItems]="importingItems()"
		[totalItems]="totalItems()" />
/>
}
