import { HttpEvent, HttpHandler, HttpInterceptor, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { finalize, Observable } from 'rxjs';
import { LoaderService } from '../services/loader/loader.service';

@Injectable({
	providedIn: 'root',
})
export class LoadingInterceptor implements HttpInterceptor {
	private isLoaderRunning: boolean;

	private _internalCounters: number;

	/**
	 * List of urls that should  trigger the loader
	 *
	 * @todo: to move in a configuration file
	 */
	private urlsLoader: string[] = ['/package'];

	/**
	 * Current request counter
	 */
	public get internalCounters(): number {
		return this._internalCounters;
	}

	/**
	 * Creates an instance of LoadingInterceptor.
	 * @param {LoaderService} loaderService
	 * @memberof LoadingInterceptor
	 */
	constructor(private loaderService: LoaderService) {
		this.isLoaderRunning = false;
		this._internalCounters = 0;
	}

	/**
	 * Méthode appelée par le cadre angular lorsqu'une requête (HTTPCLient) est émise
	 *
	 * @param {HttpRequest<unknown>} req
	 * @param {HttpHandler} next
	 * @return {*}  {Observable<HttpEvent<unknown>>}
	 * @memberof LoadingInterceptor
	 */
	intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		if (this.hasToBeActivatedForUrlPart(req.url)) {
			//  Ici l'objectif est juste d'écouter sans intervenir sur la requête émise ou le retour de cette requête
			this._internalCounters += 1;

			console.log(`Loader - intercept - ${this._internalCounters} - ${req.urlWithParams} - ${req.responseType}`);

			if (!this.isLoaderRunning) {
				this.loaderService.start();
				this.isLoaderRunning = true;
			}

			return this.stopLoaderOnResponse(req, next);
		}
		return next.handle(req);
	}

	private stopLoaderOnResponse(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(req).pipe(
			/** Execute callback function when the observable completes */
			finalize(() => {
				this._internalCounters -= 1;
				this._internalCounters = Math.max(0, this._internalCounters);

				console.log(`Loader - finalize - ${this._internalCounters} - ${req.urlWithParams} - ${req.responseType}`);

				if (this._internalCounters <= 0) {
					this.isLoaderRunning = false;
					this.loaderService.stop();
				}
			})
		);
	}

	private hasToBeActivatedForUrlPart(urlPart: string): boolean {
		return this.urlsLoader.filter(url => urlPart.includes(url)).length > 0;
	}
}
