import { Component, computed, HostListener, inject, input, model, OnInit, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';

import { User } from 'src/app/_common/models/user.model';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { MhpSvgIconComponent } from '../../../_common/components/mhp-svg-icon/mhp-svg-icon.component';
import { MhpButtonComponent } from '../../../_common/components/mhp-button/mhp-button.component';
import { MhpFromToComponent } from '../../../_common/components/mhp-from-to/mhp-from-to.component';
import { MhpInputComponent } from '../../../_common/components/mhp-input/mhp-input.component';
import { Company } from 'src/app/_common/models/company.model';
import { MhpLabelComponent } from '../../../_common/components/mhp-label/mhp-label.component';
import { ImporterRequestItem } from 'src/app/_common/models/importer-request-item.model';
import { Offer } from 'src/app/_common/models/offer.model';
import { CompanyService } from 'src/app/_common/services/company/company.service';
import { ApiOfferService } from 'src/app/_common/services/api/api-offer/api-offer.service';
import { OffersService } from 'src/app/_common/services/offers/offers.service';
import { CounterOffer } from 'src/app/_common/models/counter-offer.model';

@Component({
	selector: 'app-main-request-make-offer-modal',
	standalone: true,
	imports: [
		MhpButtonComponent,
		MhpFromToComponent,
		MhpInputComponent,
		MhpLabelComponent,
		MhpSvgIconComponent,
		CommonModule,
	],
	templateUrl: './main-request-make-offer-modal.component.html',
})
export class MainRequestMakeOfferModalComponent implements OnInit {
	private readonly _apiOfferSvc = inject(ApiOfferService);
	private readonly _accountSvc = inject(AccountService);
	private readonly _companySvc = inject(CompanyService);
	private readonly _offersSvc = inject(OffersService);

	public readonly exporter = input.required<Company>();
	public readonly item = input.required<ImporterRequestItem>();
	public readonly offer = input.required<Offer>();

	public readonly stateClose = output<boolean>();

	public readonly price = signal<string>('');
	public readonly quantity = signal<string>('');
	public readonly user = computed<User | null>(this._accountSvc.user);

	public importingItems: number = 0;
	public totalItems: number = 0;

	constructor() {}

	public ngOnInit(): void {
		this.price.set(this.offer().price.toString());
		this.quantity.set(this.offer().quantity.toString());
	}

	public submit(): void {
		this._apiOfferSvc
			.sendCounterOffer(this.offer().id, <CounterOffer>{
				requestId: this.offer().requestId,
				itemId: this.item().itemId,
				exporterId: this._companySvc.currentCompany()?.id!,
				packageCip13: this.item().package.cip13,
				batchNumber: this.offer().batchNumber,
				expirationDate: this.offer().expirationDate,
				quantity: +this.quantity(),
				minQuantity: this.offer().minQuantity,
				multiple: this.offer().multiple,
				price: +this.price(),
				parentOffer: this.offer(),
				initiatedById: this.offer().exporterId,
				confirmationDate: null,
				orderingDate: null,
				isToOrder: this.offer().isToOrder,
			})
			.then(async response => {
				if (response.ok) {
					// refacto: replace the refresh by a replacement of the coutner offer returned by the API sendCounterOffer
					this.close();
				}
			})
			.catch((err: any) => console.error(err));
	}

	@HostListener('document:keydown', ['$event'])
	public triggerClosing(e: any): void {
		if (e.keyCode === 27) this.stateClose.emit(true);
	}

	public close(): void {
		this.stateClose.emit(true);
	}
}
