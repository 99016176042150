/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	ApplicationRef,
	ComponentFactoryResolver,
	ComponentRef,
	EmbeddedViewRef,
	Injectable,
	Injector,
	OnDestroy,
	Type,
} from '@angular/core';
import { BaseService } from '../base-service/base.service';

/**
 * Helper to inject knwon components in the root of the application.
 * Got from : https://stackoverflow.com/questions/39857222/angular2-dynamic-component-injection-in-root/40687392#40687392
 *
 * @export
 * @class InjectionService
 */
@Injectable({ providedIn: 'root' })
export class ComponentInjectorService extends BaseService implements OnDestroy {
	private container: ComponentRef<any> = null!;

	constructor(
		private applicationRef: ApplicationRef,
		private componentFactoryResolver: ComponentFactoryResolver,
		private injector: Injector
	) {
		// Necessaire au bon fonctionnement du cadre transversal. Ne pas supprimer.
		super();
		this.applicationRef = applicationRef;
		this.componentFactoryResolver = componentFactoryResolver;
		this.injector = injector;
	}

	/**
	 * Gets the root view container to inject the component to.
	 */
	public getRootViewContainer(): ComponentRef<any> | null {
		if (this.container) return this.container;

		// Angular5 a supprimé _rootComponents. Je remplace par components, qui fonctionne.
		const rootComponents = this.applicationRef['components'];

		// cas 1: un composant est actuelle chargé
		if (rootComponents.length) return rootComponents[0];

		// cas 2: pas de composant racine, c'est le cas quand on est dans l'init de l'application
		return null;
	}

	/**
	 * Overrides the default root view container.
	 */
	public setRootViewContainer(container: ComponentRef<any>): void {
		this.container = container;
	}

	/**
	 * Gets the html element for a component ref.
	 */
	public getComponentRootNode(componentRef: ComponentRef<any>): HTMLElement {
		return (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
	}

	/**
	 * Gets the root component container html element.
	 */
	public getRootViewContainerNode(): HTMLElement | null {
		const viewContainer = this.getRootViewContainer();
		return viewContainer ? this.getComponentRootNode(viewContainer) : null;
	}

	/**
	 * Projects the inputs onto the component
	 */
	public projectComponentInputs(component: ComponentRef<any>, options: any): ComponentRef<any> {
		if (options) {
			const props = Object.getOwnPropertyNames(options);
			for (const prop of props) {
				component.instance[prop] = options[prop];
			}
		}

		return component;
	}

	/**
	 * Appends a component to a adjacent location
	 */
	public appendComponent<T>(
		componentClass: Type<T>,
		options: any = {},
		rootNode: Element | null = this.getRootViewContainerNode()
	): ComponentRef<any> {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentClass);
		const componentRef = componentFactory.create(this.injector);
		const componentRootNode = this.getComponentRootNode(componentRef);

		if (!rootNode) {
			// we are at the initialization of the application, do nothing
			return null!;
		}

		if (!componentRootNode) {
			console.error('The component root node is not part of the DOM');
			return null!;
		}

		// project the options passed to the component instance
		this.projectComponentInputs(componentRef, options);

		this.applicationRef.attachView(componentRef.hostView);
		componentRef.onDestroy(() => {
			this.applicationRef.detachView(componentRef.hostView);
		});
		if (rootNode) rootNode.appendChild(componentRootNode);
		componentRef.changeDetectorRef.detectChanges();
		return componentRef;
	}
}
