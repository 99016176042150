import { Component, Directive } from '@angular/core';
import { Subject } from 'rxjs';

@Directive()
export class MhpBaseComponent {
	/**
	 * To be used for all non-unitary subscriptions on services, in the takeUntil(subscription$) pattern.
	 * Emits the unsubscribe notification upon the destruction of the component.
	 */
	protected subscription$ = new Subject();

	public ngOnDestroy(): void {
		this.subscription$.next(null);
		this.subscription$.complete();
	}
}
