<div class="mhp-package">

  @if (this.package()) {

    <div class="flex flex-col">
      <div class="flex flex-col w-full mb-6">
        <div class="flex flex-row items-center mb-6 text-gray-500">
          <a [routerLink]="[ '/dashboard' ]">MedHubPlace</a>
          <ng-icon class="mx-2 mt-1" name="saxArrowRight3Outline" />
          <a>Find a product Result</a>
        </div>

        <div class="flex flex-row mb-2">
          <h2 class="w-full text-4xl font-semibold text-gray-900 dark:text-white">{{ this.package()?.shortName }}</h2>

	        <!-- #85 Hide temporarily the filtering and view characteristics button, please :-), as below -->

          <!-- <div class="text-right shrink-0">
            <app-mhp-button prefix="saxDocumentTextOutline" text="View characteristics" type="tertiary" />
          </div> -->

        </div>

        <h3 class="text-gray-500">{{ this.package()?.cip13! | toCip : 13 }}</h3>
      </div>

      <!-- #85 Hide temporarily the filtering and view characteristics button, please :-), as below -->
			<!--
			<div class="flex flex-row items-center space-x-4 mb-10">
        <app-mhp-button suffix="saxArrowDown1Outline" text="Exporter" type="tertiary" />
        <app-mhp-button suffix="saxArrowDown1Outline" text="Expiration date" type="tertiary" />
        <app-mhp-button suffix="saxArrowDown1Outline" text="Country from" type="tertiary" />
        <app-mhp-button suffix="saxArrowDown1Outline" text="Sort" type="tertiary" />
      </div> -->

      <div class="flex flex-col">
        <div class="flex flex-row items-center mb-6">
          <h5 class="text-2xl font-semibold w-full text-gray-900 dark:text-white">
            {{ this.productsSum() }} units in stocks from {{ this.distinctExporters() | i18nPlural : { '=1': '# exporter', 'other': '# exporters' } }}
          </h5>

          <!-- <app-mhp-view-switch
            class="shrink-0"
            (choice)="this.view = $event"
          /> -->
        </div>

        @if (this.view === 1) {
          <div class="relative overflow-x-auto border border-gray-200 dark:border-gray-700 rounded-2xl">
            <table class="w-full text-sm text-left rtl:text-right text-gray-900 bg-white dark:bg-gray-800 dark:text-white">
              <thead class="text-xs text-gray-500">
                <tr>
                  <th class="py-4 pl-4 font-medium">Exporter</th>
                  <th class="py-4 pl-4 font-medium">Product</th>
									<th class="py-4 pl-4 font-medium">Quota</th>
                  <th class="py-4 pl-4 font-medium">Stock</th>
                  <th class="py-4 pl-4 font-medium">Batch</th>
                  <th class="py-4 pl-4 font-medium">Expiration date</th>
                  <th class="py-4 pl-4 font-medium">Unit cost</th>
                  <th class="py-4 pl-4 font-medium"></th>
                </tr>
              </thead>
              <tbody>

								<!--  https://github.com/orgs/MedHubCompany/projects/1/views/6?pane=issue&itemId=84722179  -->
                @for (stock of this.stocks(); track stock) {
                  <tr class="bg-white border-t dark:bg-gray-800 dark:border-gray-700 font-medium">
                    <td class="py-4 px-4">
                      <app-mhp-company [company]="this.getExporter(stock.exporterId)!" [displayCountry]="true" />
                    </td>
										<!-- #85 If I don't have a batch number, my line will only have the quotas. -->
                    <td class="p-4">{{ this.package()?.product?.shortName }}</td>
										<td class="p-4">{{ stock.quota || '' }}</td>
                    <td class="p-4">{{ stock.batch?.id ? stock.quantity : '' }}</td>
                    <td class="p-4">{{ stock.batch?.id ?? ''}}</td>
                    <td class="p-4">{{ stock.batch?.id ? (stock.batch?.expiration | date : 'dd/MM/yyyy') : null }}</td>
                    <td class="p-4">{{ stock.price ? (stock.price | currency : 'EUR' : 'symbol' : '.0-2') : 'Make an offer' }}</td>
                    <td class="p-4">
                      <div class="flex flex-row justify-end items-center space-x-4">
                        <app-mhp-button
                          prefix="saxSmsOutline"
                          type="secondary"
                        />
                        <app-mhp-button
                          class="shrink-0"
                          text="Request product"
                          type="primary"
                          prefix="saxAddOutline"
                          (click)="requestStock(stock)"
                        />
                        <!-- <button class="flex flex-row justify-center items-center shrink-0">
                          <ng-icon size="1.5rem" name="saxMoreOutline" />
                        </button> -->
                      </div>
                    </td>
                  </tr>
                }

              </tbody>
            </table>
          </div>
        }

        @else if (this.view === 2) {
          <span class="dark:text-white">Coming soon...</span>
        }
      </div>
    </div>
  }


  @else {
    The product you are looking for is not available.
  }

</div>

@if (this.requestProductModal()) {
  <app-main-package-request-product-modal
    [(isOpen)]="this.requestProductModal"
    [exporter]="getExporter(selectedStock()?.exporterId!)!"
    [product]="this.package()!"
    [stock]="this.selectedStock()!"
  />
}
