import { Component, computed, HostListener, inject, input, output, signal } from '@angular/core';
import { MhpButtonComponent } from '../../../_common/components/mhp-button/mhp-button.component';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { User } from 'src/app/_common/models/user.model';
import { Offer } from 'src/app/_common/models/offer.model';
import { Company } from 'src/app/_common/models/company.model';
import { MhpSvgIconComponent } from '../../../_common/components/mhp-svg-icon/mhp-svg-icon.component';
import { MhpFromToComponent } from '../../../_common/components/mhp-from-to/mhp-from-to.component';

@Component({
	selector: 'app-main-request-confirm-offers',
	standalone: true,
	imports: [MhpButtonComponent, MhpFromToComponent, MhpSvgIconComponent],
	templateUrl: './main-request-confirm-offers.component.html',
})
export class MainRequestConfirmOffersComponent {
	private readonly _accountSvc = inject(AccountService);
	public readonly exporter = input.required<Company>();
	public readonly offers = input.required<Offer[]>();

	public readonly onCancel = output();
	public readonly onConfirm = output();

	public readonly user = computed<User | null>(this._accountSvc.user);

	public clickedInside: boolean = false;
	public readonly buttonText = input.required<string>();

	constructor() {}

	public submitConfirm() {
		this.onConfirm.emit();
		this.close();
	}

	@HostListener('document:keydown', ['$event'])
	public triggerClosing(e: any): void {
		if (e.keyCode === 27) this.onCancel.emit();
	}

	@HostListener('document:click')
	public close(): void {
		if (!this.clickedInside) this.onCancel.emit();

		this.clickedInside = false;
	}
}
