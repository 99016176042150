import { inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ChatMessage } from '../../models/chat-message.model';
import { ChatRoom } from '../../models/chat-room.model';
import { Subject } from 'rxjs/internal/Subject';
import { filter } from 'rxjs/internal/operators/filter';
import { SignalRClientBase } from '../signalr/signalr.client.base';
import { ChatRoomEventDispatcher } from './chatroom.event.dispatcher';
import { Offer } from '../../models/offer.model';
import { Company } from '../../models/company.model';
import { User } from '../../models/user.model';
import { AccountService } from '../account/account.service';
import { CompanyService } from '../company/company.service';

@Injectable({
	providedIn: 'root',
})
export class MessagingService extends SignalRClientBase {
	private readonly _accountSvc = inject(AccountService);
	private readonly _companySvc = inject(CompanyService);

	private readonly _newMessageSubject = new Subject<ChatMessage>();
	private readonly _editedMessageSubject = new Subject<ChatMessage>();
	private readonly _deletedMessageSubject = new Subject<ChatMessage>();
	private readonly _userWritingSubject = new Subject<ChatMessage>();

	constructor() {
		super(environment.API_URL + '/hub/messaging');

		// Handle messaging events
		this._hubConnection.on('NewMessage', (message: ChatMessage) => {
			console.log('New message received:', message);

			this._newMessageSubject.next(message);
		});

		this._hubConnection.on('EditedMessage', (message: ChatMessage) => {
			this._editedMessageSubject.next(message);
		});

		this._hubConnection.on('DeletedMessage', (message: ChatMessage) => {
			this._deletedMessageSubject.next(message);
		});

		this._hubConnection.on('UserWriting', (user: ChatMessage) => {
			this._userWritingSubject.next(user);
		});
	}

	/**
	 * Get a chat room for the offer provided, create a new one if it doesn't exist
	 * @param offerId
	 * @returns
	 */
	public async getOrCreateChatRoomFromOffer(offer: Offer): Promise<ChatRoom> {
		await this.getConnectionPromise;

		// if the offer has an idea, we should get it from the back
		if (offer.id) return await this._hubConnection.invoke<ChatRoom>('GetChatRoomFromOffer', offer.id);
		else {
			// return the chat room if it already exists
			if (offer.chatRoom) return offer.chatRoom;

			const company = this._companySvc.currentCompany();
			if (!company) throw new Error('Company not found');

			// otherwize create a new chat room in the front app
			const newChatRoom = <ChatRoom>{
				id: undefined!, // set to undefined to map to empty Guid in the backend
				participants: [company],
				messages: [],
				readOnly: false,
				createdAt: new Date(),
				updatedAt: new Date(),
			};

			offer.chatRoom = newChatRoom;

			return newChatRoom;
		}
	}

	/**
	 * Join the chat room and get all chat room message history
	 */
	public async joinChatRoom(roomId: string): Promise<ChatRoomEventDispatcher> {
		await this.getConnectionPromise;

		const chatHistory = await this._hubConnection.invoke<ChatMessage[]>('JoinChatRoom', roomId);

		const roomFilter = (message: ChatMessage) => message.roomId === roomId;

		return new ChatRoomEventDispatcher(
			this._newMessageSubject.pipe(filter(roomFilter)),
			this._editedMessageSubject.pipe(filter(roomFilter)),
			this._deletedMessageSubject.pipe(filter(roomFilter)),
			this._userWritingSubject.pipe(filter(roomFilter)),
			chatHistory
		);
	}

	/**
	 * Get all chat room messages
	 */
	public async leaveChatRoom(roomId: string): Promise<void> {
		await this.getConnectionPromise;

		await this._hubConnection.invoke('LeaveChatRoom', roomId);
	}

	/**
	 * Send message to the chat room
	 */
	public async sendMessage(room: ChatRoom, message: string): Promise<any> {
		await this.getConnectionPromise;

		if (room.id) {
			// chat room is online send message to the chat room
			await this._hubConnection.invoke('SendMessage', room.id, message);
		} else {
			// chat room is onffline, get user and company
			const user = this._accountSvc.user();
			if (!user) throw new Error('User not found');

			const company = this._companySvc.currentCompany();
			if (!company) throw new Error('Company not found');

			this.pushOfflineMessage(room, user, company, message);
		}
	}

	/**
	 * Push offline message to the chat room
	 */
	private pushOfflineMessage(room: ChatRoom, user: User, company: Company, messageToSend: string) {
		room.messages.push(<ChatMessage>{
			id: undefined!, // set to undefined to map to empty Guid in the backend
			roomId: room.id,
			authorId: user.id,
			authorFullName: user.firstName + ' ' + user.lastName,
			authorCompanyId: company.id,
			authorCompanyName: company.displayName,
			content: messageToSend,
			createdAt: new Date(),
			updatedAt: new Date(),
		});
	}
}
