@if (this.message(); as message) {
	<div class="mhp-messaging-conversation-message" [class.sender]="this.isCompanySender()" [class.receiver]="!this.isCompanySender()">
		<div class="info">
			<span class="sender">{{ this.isAccountSender() ? "You" : message.authorFullName }}</span>
			<span class="timestamp">{{ message.createdAt | date: 'short' }}</span>
		</div>
	
		<div class="bubble">
			<p>{{ message.content }}</p>
		</div>
	</div>
}
