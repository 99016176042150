import { Component, computed, inject, signal } from '@angular/core';
import { ActivatedRoute, ParamMap, RouterModule } from '@angular/router';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxArrowRight3Outline, saxBuildingsOutline, saxMoreOutline, saxSmsOutline } from '@ng-icons/iconsax/outline';

import { Package } from 'src/app/_common/models/package.model';
import { ApiPackageService } from 'src/app/_common/services/api/api-package/api-package.service';
import { ToCipPipe } from '../../_common/pipes/to-cip/to-cip.pipe';
import { MhpButtonComponent } from '../../_common/components/mhp-button/mhp-button.component';
import { MhpCompanyComponent } from '../../_common/components/mhp-company/mhp-company.component';
import { MainPackageRequestProductModalComponent } from './main-package-request-product-modal/main-package-request-product-modal.component';
import { Company } from 'src/app/_common/models/company.model';
import { ApiCompanyService } from 'src/app/_common/services/api/api-company/api-company.service';
import { ExporterStockEntry } from 'src/app/_common/models/exporter-stock-entry.model';
import { CurrencyPipe, DatePipe, I18nPluralPipe } from '@angular/common';
import { CompanyExtension } from 'src/app/_common/extensions/company.extension';

@Component({
	selector: 'app-main-package',
	standalone: true,
	imports: [
		RouterModule,
		NgIconComponent,
		ToCipPipe,
		MhpButtonComponent,
		MhpCompanyComponent,
		MainPackageRequestProductModalComponent,
		DatePipe,
		CurrencyPipe,
		I18nPluralPipe,
	],
	providers: [
		provideIcons({
			saxArrowRight3Outline,
			saxBuildingsOutline,
			saxMoreOutline,
			saxSmsOutline,
		}),
		provideNgIconsConfig({ size: '1rem' }),
	],
	styleUrls: ['./main-package.component.scss'],
	templateUrl: './main-package.component.html',
})
export class MainPackageComponent {
	private readonly activatedRoute = inject(ActivatedRoute);
	private readonly _apiCompanySvc = inject(ApiCompanyService);
	private readonly _apiPackageSvc = inject(ApiPackageService);

	public readonly distinctExporters = computed(
		() =>
			this.stocks()
				.map(stock => stock.exporterId)
				.filter((value, index, self) => self.indexOf(value) === index).length
	);
	public readonly exporters = signal<Company[]>([]);
	public readonly package = signal<Package | null>(null);
	public readonly productsSum = computed(() => this.stocks().reduce((acc, stock) => acc + (stock.quantity ?? 0), 0));
	public readonly requestProductModal = signal(false);
	protected readonly selectedStock = signal<ExporterStockEntry | null>(null);
	public readonly stocks = signal<ExporterStockEntry[]>([]);

	public cip: string = '';
	public view: number = 1;

	constructor() {
		this.activatedRoute.paramMap.subscribe(async (paramMap: ParamMap): Promise<void> => {
			this.cip = paramMap.get('cip')!;

			this.package.set(
				this.cip.length === 13
					? (await this._apiPackageSvc.getByCIP13(+paramMap.get('cip')!)).body
					: this.cip.length === 7
					? (await this._apiPackageSvc.getByCIP7(+paramMap.get('cip')!)).body
					: null
			);

			this.stocks.set((await this._apiPackageSvc.getByCIP13WithStocks(this.package()?.cip13!)).body!);
		});

		this._apiCompanySvc.getAll().then(response => {
			if (response.ok) this.exporters.set(response.body || []);
		});
	}

	public getExporter = (id: number): Company | undefined => CompanyExtension.getExporterById(this.exporters(), id);

	public requestStock(stock: ExporterStockEntry) {
		this.requestProductModal.set(true);
		this.selectedStock.set(stock);
	}
}
