import { Component, HostListener, inject, input, OnInit, output, signal } from '@angular/core';

import { Offer } from 'src/app/_common/models/offer.model';
import { Package } from 'src/app/_common/models/package.model';
import { ToCipPipe } from '../../../_common/pipes/to-cip/to-cip.pipe';
import { MhpSvgIconComponent } from '../../../_common/components/mhp-svg-icon/mhp-svg-icon.component';
import { MHPTab } from 'src/app/_common/components/mhp-tabs/mhp-tab.interface';
import { MainRequestOfferHistoryTabsEnum } from 'src/app/_common/enums/main-request-offer-history-tabs.enum';
import { MainRequestOfferHistoryDiscussionComponent } from './main-request-offer-history-discussion/main-request-offer-history-discussion.component';
import { ChatRoom } from 'src/app/_common/models/chat-room.model';
import { MessagingService } from 'src/app/_common/services/messaging/messaging.service';

@Component({
	selector: 'app-main-request-offer-history',
	standalone: true,
	imports: [ToCipPipe, MhpSvgIconComponent, MainRequestOfferHistoryDiscussionComponent],
	templateUrl: './main-request-offer-history.component.html',
	styleUrl: './main-request-offer-history.component.scss',
})
export class MainRequestOfferHistoryComponent implements OnInit {
	private readonly _messagingSvc = inject(MessagingService);

	public readonly offer = input.required<Offer>();
	public readonly package = input.required<Package>();

	public readonly stateClose = output<boolean>();
	public readonly mainRequestOfferHistoryTabs = MainRequestOfferHistoryTabsEnum;

	public readonly offerRoom = signal<ChatRoom | null>(null);

	public readonly tabs: MHPTab<MainRequestOfferHistoryTabsEnum>[] = [
		// { text: 'History', value: MainRequestOfferHistoryTabsEnum.History },
		{ text: 'Discussion', value: MainRequestOfferHistoryTabsEnum.Discussion },
	];

	public tabSelected: MHPTab<MainRequestOfferHistoryTabsEnum> = this.tabs[0];
	public clickedInside: boolean = false;

	public async ngOnInit(): Promise<void> {
		this.offerRoom.set(await this._messagingSvc.getOrCreateChatRoomFromOffer(this.offer()));
	}

	@HostListener('document:keydown', ['$event'])
	public triggerClosing(e: any): void {
		if (e.keyCode === 27) this.stateClose.emit(true);
	}

	@HostListener('document:click')
	public close(): void {
		if (!this.clickedInside) this.stateClose.emit(true);

		this.clickedInside = false;
	}
}
