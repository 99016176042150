<div class="flex flex-col justify-center items-center pt-[15vh] w-full">

  <button
    class="fixed px-4 py-3 m-8 left-0 top-0 rounded-xl border border-gray-200 text-gray-900 bg-white dark:text-white dark:border-gray-700 dark:bg-gray-800"
    (click)="this.reset()"
    [routerLink]="[ this.sitemap.dashboard.route ]"
  >Cancel</button>

  @if (this.props().step; as step) {
    <div class="fixed m-8 z-10 right-0 top-0 flex flex-row space-x-4">
      <!-- <button
        class="px-4 py-3 rounded-xl text-gray-900 bg-gray-100 hover:bg-gray-200 dark:text-white dark:bg-gray-900 dark:hover:bg-gray-950"
      >Save draft</button> -->

      @if (this.canContinue()) {
        <button
          class="px-4 py-3 rounded-xl text-white bg-blue-500 hover:bg-blue-600"
          (click)="step === 4 ? this.submit() : this.nextStep()"
        >
          @if (step === 4) { Send order }
          @else { Continue }
        </button>
      }
    </div>
  }

  @switch (this.props().step) {
    @case (0 || undefined) {
      <div class="flex flex-col justify-center">
        <h2 class="text-2xl text-center font-semibold mb-8 text-gray-900 dark:text-white">What would you like to request?</h2>

        <div class="flex flex-col space-y-4">
					<mhp-suggestions-card
						icon="file"
						iconBg="bg-[#54c7e320]"
						title="Purchase Order"
						text="You define a list of products, an exporter and commit to an order."
						(click)="this.updateRequestType(requestTypes.PurchaseOrder)"
					/>

					<mhp-suggestions-card
						[disabled]="true"
						icon="search"
						iconBg="bg-[#3971ff20]"
						title="Request Offer"
						text="Coming soon..."
						(click)="this.updateRequestType(requestTypes.RequestOffer)"
					/>
					<!-- text="You define a list of products and ask exporters to tell you the price before you decide to buy." -->


					<!-- <app-new-request-button
						title="Price request"
						text="You define a list of products and ask exporters to tell you the price before you decide to buy. "
						(click)="this.nextStep()"
					/> -->
        </div>
      </div>
    }

    @case (1) {
      <div class="flex flex-col justify-center min-w-[30rem]">
        <h2 class="text-2xl text-center font-semibold mb-8 text-gray-900 dark:text-white">Import your list of products</h2>
				<app-mhp-file-uploader [acceptedFiles]="['.csv']" (fileChange)="this.processingFiles($event)" />
      </div>

			<!-- Link to the template file -->
			<div class="flex justify-center items-center mt-4 text-gray-900 dark:text-white">
				<a [href]="purchaseOrderTemplateCsvPath" class="hover:underline">Download our template</a>
			</div>
    }

    @case (2) {
      <div class="flex flex-col justify-center min-w-[30rem] pb-12">
        <h2 class="text-2xl text-center font-semibold mb-8 text-gray-900 dark:text-white">Confirm your products list</h2>

        <div class="flex flex-col w-full">
          <span class="mb-6 text-lg font-semibold text-gray-900 dark:text-white">We have identified {{ this.packageItems().length }} products</span>

          <div class="overflow-x-auto border border-gray-200 dark:border-gray-700 rounded-2xl">
            <table class="request-content">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Product</th>
                  <th>Batch</th>
                  <th>Min. expiration</th>
                  <th>Quantity</th>
									<th>Multiple</th>
									<th>Min. quantity</th>
                  <th>Unit cost</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                @for (packageItem of this.packageItems(); track $index) {
                  <tr>
                    <td class="p-4">{{ $index + 1 }}</td>
                    <td class="p-4">
											<app-mhp-package [package]="{ cip13: packageItem.packageCip13, shortName: packageItem.packageName }" />
                    </td>
                    <td class="text-center">
                      <input
												id="package-{{$index}}-batchNumber"
												[class.border-red-500]="this.checkErrorCell(packageItem, $index)"
                        type="text"
                        [value]="packageItem.batchNumber"
                        [(ngModel)]="packageItem.batchNumber"
                      />
                    </td>
                    <td class="text-center">
                      <input
												id="package-{{$index}}-minExpiration"
												[class.border-red-500]="this.checkErrorCell(packageItem, $index)"
                        type="text"
                        [value]="packageItem.minExpiration"
                        [(ngModel)]="packageItem.minExpiration"
                      />
                    </td>
                    <td class="text-center">
                      <input
												id="package-{{$index}}-quantity"
												[class.border-red-500]="this.checkErrorCell(packageItem, $index)"
                        type="text"
                        [value]="packageItem.quantity"
                        [(ngModel)]="packageItem.quantity"
                      />
                    </td>
										<td class="text-center">
											<input
												id="package-{{$index}}-multiple"
												[class.border-red-500]="this.checkErrorCell(packageItem, $index)"
												type="text"
												[value]="packageItem.multiple"
												[(ngModel)]="packageItem.multiple"
											/>
										</td>
										<td class="text-center">
											<input
												id="package-{{$index}}-minQty"
												[class.border-red-500]="this.checkErrorCell(packageItem, $index)"
												type="text"
												[value]="packageItem.minQuantity"
												[(ngModel)]="packageItem.minQuantity"
											/>
										</td>
                    <td class="text-center">
                      <input
												id="package-{{$index}}-price"
												[class.border-red-500]="this.checkErrorCell(packageItem, $index)"
                        type="text"
                        [value]="packageItem.price"
                        [(ngModel)]="packageItem.price"
                      />
                    </td>
                    <td class="actions">
                      <button name="delete" title="Remove from list" (click)="this.deleteItem($index)">
                        <ng-icon name="saxTrashOutline" />
                      </button>
                    </td>
                  </tr>
                }

                @empty {
                  <tr>
                    <td class="p-4">Empty</td>
                  </tr>
                }

              </tbody>
            </table>
          </div>
        </div>
      </div>
    }

    @case (3) {
      <div class="flex flex-col justify-center max-w-[30rem] pb-12">
        <h2 class="text-2xl text-center font-semibold mb-8 text-gray-900 dark:text-white">To whom would you like to send this purchase order?</h2>

				<!-- FIXME: Update to support multiple exporters -->
				@let exporter = this.props().exporter;
        <app-mhp-company-selector
          description="You have already made requests to these exporters"
					[multipleSelect]="false"
          [selected]="exporter ? [exporter] : []"
          (selectedChange)="this.updateExporter($event[0])"
        />
      </div>
    }

    @case (4) {
      <div class="flex flex-col justify-center max-w-[30rem] pb-12">
        <h2 class="text-2xl text-center font-semibold mb-8 text-gray-900 dark:text-white">Confirm and complete the information before to send</h2>

        <div class="flex flex-col p-6 space-y-8 border rounded-2xl border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-800">
          <span class="font-semibold text-gray-900 dark:text-white">You would like to make a purchase order</span>

          <div class="flex flex-col space-y-6">
            <app-mhp-from-to [from]="this.user()!" [to]="[this.props().exporter!]" />

						<span class="text-sm text-gray-500">Number reference</span>
						<app-mhp-input
							[(value)]="this.props().userRequestId"
							type="text"
							placeholder="Enter a number reference"
						/>

            <!-- VJO 12/12/24 11:42 "Tu peux le cacher pour le moment, on n'a pas encore développé cette fonctionnalité"

						 <div class="flex flex-row px-4 py-3 items-center justify-center border rounded-xl border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-800">
              <span class="w-full text-sm text-gray-500">Open to others if stock unsure, no commitment</span>
              <app-mhp-switch />
            </div> -->
          </div>

          <div class="flex flex-col space-y-8">
            <div class="flex flex-col space-y-4">
              <span class="text-sm text-gray-500">Products requested</span>
              <div class="flex flex-row items-center">
                <div class="flex flex-row w-full space-x-4">
                  <div class="flex justify-center items-center h-11 w-11 border rounded-full shrink-0 dark:border-gray-700">
                    <app-mhp-svg-icon icon="pill" />
                  </div>
                  <div class="flex flex-col text-gray-900 dark:text-white">
                    <span>{{ this.packageItems().length }} references</span>
                    <span class="text-sm">{{ this.packageItemsSum() | currency : 'EUR': 'symbol' : '.0-2' }}</span>
                  </div>
                </div>
                <app-mhp-button
                  class="shrink-0"
                  text="See the list"
                  type="secondary"
                  (click)="this.setStep(2)"
                />
              </div>
            </div>


<!--
								Hidden as requested : https://github.com/MedHubCompany/MedHubPlace/issues/130
-->

<!--               <div class="flex flex-col space-y-4"> -->
<!--                 <div class="flex flex-row items-center text-sm text-gray-500"> -->
<!--                   <span class="mr-1">Minimum order price</span> -->
<!--                   <ng-icon name="saxInfoCircleOutline" size="1rem" /> -->
<!--                 </div> -->
<!--                 <app-mhp-input -->
<!--                   [min]="0" -->
<!--                   [stepper]="true" -->
<!--                   suffix="€" -->
<!--                   type="number" -->
<!--                 /> -->
<!--               </div> -->

<!--               <div class="flex flex-col space-y-4"> -->
<!--                 <span class="text-sm text-gray-500">Comments (optional)</span> -->
<!--                 <app-mhp-input -->
<!--                   placeholder="Enter your comment..." -->
<!--                   type="textarea" -->
<!--                 /> -->
<!--               </div> -->
          </div>
        </div>
      </div>
    }
  }


</div>
