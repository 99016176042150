import { Component, computed, HostListener, inject, input, model, OnInit, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MhpSvgIconComponent } from '../../../_common/components/mhp-svg-icon/mhp-svg-icon.component';
import { MhpFromToComponent } from '../../../_common/components/mhp-from-to/mhp-from-to.component';
import { MhpInputComponent } from '../../../_common/components/mhp-input/mhp-input.component';
import { MhpButtonComponent } from '../../../_common/components/mhp-button/mhp-button.component';
import { User } from 'src/app/_common/models/user.model';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { Company } from 'src/app/_common/models/company.model';
import { ImporterRequest } from 'src/app/_common/models/importer-request.model';
import { Package } from 'src/app/_common/models/package.model';
import { ApiPackageService } from 'src/app/_common/services/api/api-package/api-package.service';
import { ApiOfferService } from '../../../_common/services/api/api-offer/api-offer.service';

@Component({
	selector: 'app-main-request-send-offer-confirm-modal',
	standalone: true,
	imports: [CommonModule, MhpSvgIconComponent, MhpFromToComponent, MhpInputComponent, MhpButtonComponent],
	templateUrl: './main-request-send-offer-confirm-modal.component.html',
})
export class MainRequestSendOfferConfirmModalComponent implements OnInit {
	private readonly _accountSvc = inject(AccountService);
	private readonly _apiPackageSvc = inject(ApiPackageService);
	private readonly _apiOfferSvc = inject(ApiOfferService);

	public readonly exporter = input.required<Company>();
	public readonly request = input.required<ImporterRequest>();
	public readonly validOffersCount = input.required<number>();

	public readonly onSumbit = output({ alias: 'submit' });

	public readonly isOpen = model<boolean>(false);
	public readonly packages = signal<Package[]>([]);
	public readonly user = computed<User | null>(this._accountSvc.user);

	public clickedInside: boolean = false;

	constructor() {}

	public ngOnInit(): void {
		this._apiPackageSvc
			.getByCIP13s(this.request().items!.map(item => item.packageCip13))
			.then(response => {
				if (response.ok) this.packages.set(response.body!);
			})
			.catch((err: any) => console.error(err))
			.finally(() => {});
	}

	@HostListener('document:keydown', ['$event'])
	public triggerClosing(e: any): void {
		if (e.keyCode === 27) this.isOpen.set(false);
	}

	@HostListener('document:click')
	public close(): void {
		if (!this.clickedInside) this.isOpen.set(false);

		this.clickedInside = false;
	}

	public submit() {
		// Submit all provided offers
		this.isOpen.set(false);
		this.onSumbit.emit();
	}
}
