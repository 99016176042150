import { Component, computed, HostListener, inject, input, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MhpSvgIconComponent } from '../../../_common/components/mhp-svg-icon/mhp-svg-icon.component';
import { MhpCompanyComponent } from '../../../_common/components/mhp-company/mhp-company.component';
import { MhpButtonComponent } from '../../../_common/components/mhp-button/mhp-button.component';
import { Company } from 'src/app/_common/models/company.model';
import { ImporterRequestItem } from 'src/app/_common/models/importer-request-item.model';
import { Offer } from 'src/app/_common/models/offer.model';
import { ApiOfferService } from 'src/app/_common/services/api/api-offer/api-offer.service';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { User } from 'src/app/_common/models/user.model';
import { OffersService } from 'src/app/_common/services/offers/offers.service';
import { HttpResponse } from '@angular/common/http';
import { OfferExtension } from 'src/app/_common/extensions/offer.extension';

export enum ModalResponseTypeEnum {
	None = 0,
	Accepted = 1,
	Declined = 2,
}

@Component({
	selector: 'app-main-request-accept-decline-offer-modal',
	standalone: true,
	imports: [CommonModule, MhpCompanyComponent, MhpSvgIconComponent, MhpButtonComponent],
	templateUrl: './main-request-accept-decline-offer-modal.component.html',
	styleUrls: ['./main-request-accept-decline-offer-modal.component.scss'],
})
export class MainRequestAcceptDeclineOfferModalComponent {
	private readonly _apiOfferSvc = inject(ApiOfferService);
	private readonly _offersSvc = inject(OffersService);
	private readonly _accountSvc = inject(AccountService);

	public readonly offerResponseTypes = ModalResponseTypeEnum;

	public readonly exporter = input.required<Company>();
	public readonly item = input.required<ImporterRequestItem>();
	public readonly offer = input.required<Offer>();
	public readonly type = input.required<ModalResponseTypeEnum>();

	public readonly stateClose = output<boolean>();
	public readonly onSubmit = output({ alias: 'submit' });

	public readonly user = computed<User | null>(this._accountSvc.user);

	public readonly getOfferLabel = () => OfferExtension.getOfferLabel(this.offer());

	public clickedInside: boolean = false;

	constructor() {}

	public submit(): void {
		let offerUpdated: Promise<HttpResponse<Offer>>;

		switch (this.type()) {
			case ModalResponseTypeEnum.Accepted:
				offerUpdated = this._apiOfferSvc.acceptOffer(this.offer().id);
				break;
			case ModalResponseTypeEnum.Declined:
				offerUpdated = this._apiOfferSvc.declineOffer(this.offer().id);
				break;
			default:
				throw new Error('Invalid type');
		}

		offerUpdated
			.then(response => {
				if (response.ok) {
					this.close();
				}
			})
			.catch((err: any) => console.error(err));
	}

	@HostListener('document:keydown', ['$event'])
	public triggerClosing(e: any): void {
		if (e.keyCode === 27) this.stateClose.emit(true);
	}

	@HostListener('document:click')
	public close(): void {
		if (!this.clickedInside) this.stateClose.emit(true);

		this.clickedInside = false;
	}
}
