import { Observable } from 'rxjs';
import { ChatMessage } from '../../models/chat-message.model';
import { Offer } from '../../models/offer.model';
import { ImporterRequest } from '../../models/importer-request.model';

/**
 * This class is used to dispatch chat events of ONE request
 */
export class RequestEventDispatcher {
	request: ImporterRequest;
	newOfferEvent$: Observable<Offer>;
	constructor(newOfferEvent$: Observable<Offer>, request: ImporterRequest) {
		this.newOfferEvent$ = newOfferEvent$;
		this.request = request;
	}
}
