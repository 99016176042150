import { ImporterRequest } from '../models/importer-request.model';

export namespace ImporterRequestExtension {
	/**
	 * Get the UI request ID
	 */
	export function getUIRequestId(request: ImporterRequest): string {
		return request.userRequestId ? request.userRequestId : request.id.substring(request.id.length - 10).toUpperCase();
	}
}
