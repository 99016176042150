import { Component, computed, input } from '@angular/core';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxArrowRight3Outline } from '@ng-icons/iconsax/outline';
import { MhpSvgIconComponent, MhpSvgIconType } from '../mhp-svg-icon/mhp-svg-icon.component';
import { NgClass } from '@angular/common';

@Component({
	selector: 'mhp-suggestions-card',
	standalone: true,
	imports: [NgIconComponent, MhpSvgIconComponent, NgClass],
	providers: [
		provideIcons({
			saxArrowRight3Outline,
		}),
		provideNgIconsConfig({ size: '1.5rem' }),
	],
	templateUrl: './mhp-suggestions-card.component.html',
	styleUrls: ['./mhp-suggestions-card.component.scss'],
})
export class MhpSuggestionsCard {
	public readonly disabled = input<boolean>(false);

	public readonly icon = input<MhpSvgIconType>();
	public readonly iconBg = input<string>('bg-gray-200 dark:bg-gray-900');

	public readonly title = input.required<string>();
	public readonly text = input.required<string>();
}
