<div class="main-request-offer-history">
	<div class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" aria-hidden="true"></div>

	<div class="main-request-offer-history-aside" (click)="this.clickedInside = true">
		<div class="flex flex-row mb-10">
			<button
				class="flex flex-row justify-center item-center"
				(click)="this.close()"
			>
				<app-mhp-svg-icon class="dark:stroke-white" icon="close" />
			</button>
		</div>

		<div class="flex flex-row items-center w-full space-x-6 mb-6">
			<div class="p-3 shrink-0 border rounded-full border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-800">
				<app-mhp-svg-icon icon="pill" />
			</div>
			<div class="flex flex-col-reverse">
				<h2 class="pr-4 w-full text-2xl font-semibold line-clamp-1 overflow-ellipsis text-gray-900 dark:text-white">{{ this.package().shortName }}</h2>
				<h3 class="text-sm text-gray-500">{{ this.package().cip13 | toCip : 13 }}</h3>
			</div>
		</div>

		<!-- <app-mhp-tabs
			class="mb-6"
			[tabs]="this.tabs"
			[(choice)]="this.tabSelected"
		/> -->

		<hr class="-mx-6 border-gray-200 dark:border-gray-700" />

		<div class="main-request-offer-history-aside-container">
			@switch (this.tabSelected.value) {
				@case (mainRequestOfferHistoryTabs.History) {
					<div class="main-request-offer-history-aside-history">
						This feature will come soon.
					</div>
				}

				@case (mainRequestOfferHistoryTabs.Discussion) {
					@let room = this.offerRoom();
					@if( room )
					{
						<app-main-request-offer-history-discussion
							class="main-request-offer-history-aside-discussion"
							[room]="room"
						/>
					}
				}
			}
		</div>
	</div>
</div>
