import csv from 'csvtojson';
import { CommonModule, DatePipe } from '@angular/common';
import { Component, computed, effect, inject, OnInit, Signal, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxImportOutline, saxInfoCircleOutline, saxTrashOutline } from '@ng-icons/iconsax/outline';
import { saxTickCircleBold } from '@ng-icons/iconsax/bold';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { SITEMAP } from '../_common/sitemap';

import { Company } from '../_common/models/company.model';
import { PackageItem } from '../_common/models/package-item.model';
import { ImporterRequest } from '../_common/models/importer-request.model';
import { AccountService } from '../_common/services/account/account.service';
import { ApiImporterService } from '../_common/services/api/api-importer/api-importer.service';
import { MhpSuggestionsCard } from '../_common/components/mhp-new-request-button/mhp-suggestions-card.component';
import { MhpCompanySelectorComponent } from '../_common/components/mhp-company-selector/mhp-company-selector.component';
import { MhpFromToComponent } from '../_common/components/mhp-from-to/mhp-from-to.component';
import { MhpButtonComponent } from '../_common/components/mhp-button/mhp-button.component';
import { MhpInputComponent } from '../_common/components/mhp-input/mhp-input.component';
import { UserFunctionEnum } from '../_common/enums/user-function.enum';
import { MhpSvgIconComponent } from '../_common/components/mhp-svg-icon/mhp-svg-icon.component';
import { RequestTypeEnum } from '../_common/enums/request-type.enum';
import { MhpPackageComponent } from '../_common/components/mhp-package/mhp-package.component';
import { TableHandlerService } from '../_common/services/table-handler/table-handler.service';
import { CompanyService } from '../_common/services/company/company.service';
import { MhpFileUploaderComponent } from '../_common/components/mhp-file-uploader/mhp-file-uploader.component';
import { ApiPackageService } from '../_common/services/api/api-package/api-package.service';

interface NewRequestProps {
	exporter?: Company;
	requestType?: RequestTypeEnum;
	step?: number;
	userRequestId?: string;
}

@Component({
	selector: 'app-new-request',
	standalone: true,
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
		NgIconComponent,
		MhpSuggestionsCard,
		MhpButtonComponent,
		MhpCompanySelectorComponent,
		MhpFromToComponent,
		MhpInputComponent,
		MhpSvgIconComponent,
		MhpPackageComponent,
		MhpFileUploaderComponent,
	],
	providers: [
		DatePipe,
		provideIcons({
			saxImportOutline,
			saxInfoCircleOutline,
			saxTickCircleBold,
			saxTrashOutline,
		}),
		provideNgIconsConfig({ size: '1.5rem' }),
	],
	templateUrl: './new-request.component.html',
	styleUrl: './new-request.component.scss',
})
export class NewRequestComponent implements OnInit {
	private readonly router = inject(Router);
	private readonly _account = inject(AccountService);
	private readonly _companySvc = inject(CompanyService);
	private readonly _apiImporter = inject(ApiImporterService);
	private readonly _apiPackageSvc = inject(ApiPackageService);

	protected _tableSvc: TableHandlerService<PackageItem, NewRequestProps> = new TableHandlerService<
		PackageItem,
		NewRequestProps
	>(
		'new-request',
		row =>
			<PackageItem>{
				packageCip13: +row['CIP 13'],
				packageName: row['Product name'],
				quantity: +row['Quantities'] || null,
				minQuantity: +row['Qty min'] || null,
				quota: null,
				multiple: +row['Multiple'] || null,
				batchNumber: row['Batch number'] || null,
				minExpiration: row['Min expiration date'],
				price: +row['Price'] || null,
			}
	);

	protected readonly sitemap = SITEMAP;
	protected readonly requestTypes = RequestTypeEnum;
	protected readonly userFunctions = UserFunctionEnum;

	protected readonly props = this._tableSvc.props;
	protected readonly packageItems = this._tableSvc.items;
	protected readonly validCip13s = signal<number[]>([]);

	protected readonly packageItemsSum = computed(
		() =>
			Math.round(
				this.packageItems()
					.map((item: PackageItem) => (item.price ?? 0) * (item.quantity ?? 0))
					.reduce((a, b) => a + b) * 100
			) / 100
	);

	protected readonly user = this._account.user;
	protected readonly exporters = this._companySvc.allCompanies;

	// TODO: make exporter not nullable in order to get a more robust code
	public csvFileUploaded: boolean = false;

	/// downloadable template url for stock csv file
	public readonly purchaseOrderTemplateCsvPath = '/assets/download/purchase-order.template.csv';

	constructor() {
		dayjs.extend(customParseFormat);
	}

	/**
	 * Initializes the component, and the table service
	 */
	ngOnInit(): void {
		this._tableSvc.initFromLocalStorage();
	}

	public submit(): void {
		const props = this.props()!;

		this._apiImporter
			.postRequest({
				type: props.requestType!,
				userRequestId: props.userRequestId!,
				exporters: [props.exporter!],
				items: this.packageItems().map((packageItem: PackageItem) => {
					packageItem.minExpiration = dayjs(
						packageItem.minExpiration,
						TableHandlerService.dateParsingFormats,
						true
					).format('YYYY-MM-DD');
					return packageItem;
				}),
			})
			.then((response: HttpResponse<ImporterRequest | null>) => {
				if (response.ok) {
					this.reset();
					this.router.navigate([this.sitemap.request.route, response.body!.id]);
				}
			})
			.catch(err => console.error(err));
	}

	public updateExporter(exporter: Company): void {
		this._tableSvc.props.update(props => {
			if (!props) {
				props = <NewRequestProps>{};
			}

			props.exporter = exporter;
			return props;
		});
	}

	public processingFiles(file: File | null): void {
		if (file) {
			this._tableSvc.processFile(file, async () => {
				this.nextStep();
				this.validCip13s.set(
					(
						await this._apiPackageSvc.validateCip13s(
							this.packageItems()
								.map(entry => entry.packageCip13)
								.filter(cip13 => !!cip13)
						)
					).body ?? []
				);
			});
		}

		this.csvFileUploaded = !!file;
	}

	public checkErrorCell(packageItem: PackageItem, index: number): boolean {
		const packageItems = [...this.packageItems()];
		return packageItems.splice(index, 1).includes(packageItem);
	}

	public deleteItem(index: number): void {
		this.packageItems().splice(index, 1);
	}

	public updateRequestType(requestType: number): void {
		this._tableSvc.props.update(props => {
			if (!props) {
				props = <NewRequestProps>{};
			}

			props.requestType = requestType;
			return props;
		});

		this.nextStep();
	}

	public setStep(step: number): void {
		this.props.update(props => {
			if (!props) {
				props = <NewRequestProps>{};
			}

			props.step = step;
			return props;
		});
	}

	public nextStep(): void {
		if (this.props()?.step === 4) return;
		this.setStep((this.props()?.step || 0) + 1);
	}

	public previousStep(): void {
		if (this.props()?.step === 0) return;
		this.setStep((this.props()?.step || 0) - 1);
	}

	public reset(): void {
		this._tableSvc.clearCsvFile();
		this.props.set(<NewRequestProps>{});
	}

	public canContinue(): boolean {
		if (this.props()?.step === 3 && this.user()?.function === this.userFunctions.Exporter) return false;

		switch (this.props()?.step) {
			case 1:
				return this.csvFileUploaded;
			case 2:
				return !!this.packageItems().length;
			case 3:
				return !!this.props()?.exporter;
			case 4:
				return true;
		}

		return false;
	}
}
