import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseService } from '../base-service/base.service';

/**
 * Service permettant de gérer le loader mise en place apr le cadre outils
 */
@Injectable({
	providedIn: 'root',
})
export class LoaderService extends BaseService {
	public isActivated$ = new BehaviorSubject<boolean>(false);

	public onMessageChanged$ = new BehaviorSubject<string>('');

	private isEnabled = true;

	private isUserInteractionEnabled = true;

	/**
	 * Start application loader
	 */
	public start(): void {
		if (this.isEnabled) {
			this.lockUserInteraction();
			this.isActivated$.next(true);
		}
	}

	/**
	 * Stop application loader
	 */
	public stop(): void {
		this.isActivated$.next(false);
		this.subscription$.next(null);
		this.subscription$.complete();
		this.subscription$ = new Subject();
	}

	/**
	 * Update the message displayed by the loader
	 */
	public setMessage(message: string): void {
		this.onMessageChanged$.next(message);
	}

	/**
	 * Set if user interactions are authorized
	 */
	public setIsUserInteractionEnabled(value: boolean): void {
		this.isUserInteractionEnabled = value;
	}

	/**
	 * Activate the loader
	 */
	public enable(): void {
		this.isEnabled = true;
	}

	/**
	 * Disable the loader
	 */
	public disable(): void {
		this.isEnabled = false;
	}

	/**
	 * Lock all user interactions
	 */
	private lockUserInteraction(): void {
		if (!this.isUserInteractionEnabled) {
			this.preventAndStopEvent(fromEvent<MouseEvent>(document, 'mousemove'));
			this.preventAndStopEvent(fromEvent<MouseEvent>(document, 'mousedown'));
			this.preventAndStopEvent(fromEvent<MouseEvent>(document, 'mouseup'));
			this.preventAndStopEvent(fromEvent<WheelEvent>(document, 'wheel'));
			this.preventAndStopEvent(
				fromEvent<KeyboardEvent>(document, 'keyup', {
					// passive: false,
					// capture: false,
					// once: false,
				})
			);
			this.preventAndStopEvent(fromEvent<KeyboardEvent>(document, 'keydown'));
		}
	}

	private preventAndStopEvent<T extends UIEvent>(source$: Observable<T>) {
		source$.pipe(takeUntil(this.subscription$)).subscribe((value: T) => {
			value.preventDefault();
			value.stopPropagation();
		});
	}
}
