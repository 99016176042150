import { CommonModule } from '@angular/common';
import { Component, computed, effect, inject, model, OnDestroy, OnInit, signal } from '@angular/core';
import { ActivatedRoute, ParamMap, Router, RouterModule } from '@angular/router';
import { saxArrowRight3Outline, saxBuildingsOutline, saxTruckOutline } from '@ng-icons/iconsax/outline';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';

import { User } from 'src/app/_common/models/user.model';
import { ImporterRequest } from '../../_common/models/importer-request.model';
import { Offer } from '../../_common/models/offer.model';
import { Package } from '../../_common/models/package.model';
import { ImporterRequestItem } from '../../_common/models/importer-request-item.model';
import { UserFunctionEnum } from 'src/app/_common/enums/user-function.enum';
import { RequestTypeEnum } from 'src/app/_common/enums/request-type.enum';
import { MHPButton } from 'src/app/_common/components/mhp-button-group/mhp-button.interface';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { OffersService } from 'src/app/_common/services/offers/offers.service';

import { MhpButtonComponent } from '../../_common/components/mhp-button/mhp-button.component';
import { MhpButtonGroupComponent } from '../../_common/components/mhp-button-group/mhp-button-group.component';
import { MhpStatusLabelComponent } from '../../_common/components/mhp-status-label/mhp-status-label.component';
import {
	MainRequestPreFillConfirmModalComponent,
	PreFillModalResponse,
} from './main-request-pre-fill-confirm-modal/main-request-pre-fill-confirm-modal.component';
import { MainRequestItemPerProductComponent } from './main-request-item-per-product/main-request-item-per-product.component';
import { MainRequestItemPerCompanyComponent } from './main-request-item-per-company/main-request-item-per-company.component';
import { MainRequestSendOfferConfirmModalComponent } from './main-request-send-offer-confirm-modal/main-request-send-offer-confirm-modal.component';
import { OffersLoadingComponent } from './offers-loading/offers-loading.component';
import { Company } from 'src/app/_common/models/company.model';
import { OfferStatusEnum } from 'src/app/_common/enums/offer-status.enum';
import { RequestService } from 'src/app/_common/services/request/request.service';
import { filter, from, map, Observable, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { MhpSvgIconComponent } from '../../_common/components/mhp-svg-icon/mhp-svg-icon.component';
import { ImporterRequestExtension } from 'src/app/_common/extensions/importer-request.extension';
import { OfferExtension } from 'src/app/_common/extensions/offer.extension';
import { RequestEventDispatcher } from 'src/app/_common/services/request/request.event.dispatcher';

@Component({
	selector: 'app-main-request',
	standalone: true,
	imports: [
		CommonModule,
		RouterModule,
		NgIconComponent,
		MhpButtonComponent,
		MhpButtonGroupComponent,
		MhpStatusLabelComponent,
		MainRequestItemPerProductComponent,
		MainRequestPreFillConfirmModalComponent,
		MainRequestItemPerProductComponent,
		MainRequestItemPerCompanyComponent,
		MainRequestSendOfferConfirmModalComponent,
		OffersLoadingComponent,
		MhpSvgIconComponent,
	],
	providers: [
		provideIcons({
			saxArrowRight3Outline,
			saxBuildingsOutline,
			saxTruckOutline,
		}),
		provideNgIconsConfig({ size: '1rem' }),
	],
	templateUrl: './main-request.component.html',
})
export class MainRequestComponent implements OnInit, OnDestroy {
	private readonly _activatedRoute = inject(ActivatedRoute);
	private readonly _router = inject(Router);
	private readonly _requestService = inject(RequestService);
	private readonly _accountSvc = inject(AccountService);
	private readonly _offersSvc = inject(OffersService);
	public readonly RequestTypeEnum = RequestTypeEnum;
	public readonly UserFunctionEnum = UserFunctionEnum;
	public readonly OfferExtension = OfferExtension;

	public readonly importingItems = signal<number>(0);
	public readonly totalItems = signal<number>(0);

	public displayedId: string = '';

	private _request: ImporterRequest = null!;

	public OfferStatusEnum = OfferStatusEnum;

	public get request(): ImporterRequest {
		return this._request;
	}

	public set request(request: ImporterRequest) {
		this._request = request;
		this.displayedId = ImporterRequestExtension.getUIRequestId(request);
	}

	public readonly prefillConfirmation = model<PreFillModalResponse>(PreFillModalResponse.None);

	public get offersByProduct(): Record<string, Offer[]> {
		return <Record<string, (Offer & ImporterRequestItem & Package)[]>>this._offersSvc.currentRequestOffersByProduct();
	}

	public get validNewOffersAmount(): number {
		return this._offersSvc.newOffers().filter(OfferExtension.isValidNewOffer).length;
	}

	public get userFunction(): UserFunctionEnum {
		return this.user()!.function;
	}

	public get requestOffers(): Offer[] {
		return this._offersSvc.currentRequestOffers();
	}

	public readonly pendingOffersPerProduct = computed(this._offersSvc.pendingOffersPerProduct);
	public readonly inreviewOffers = computed(this._offersSvc.inreviewOffers);
	public readonly confirmedOffers = computed(this._offersSvc.confirmedOffers);
	public readonly orderedOffers = computed(this._offersSvc.orderedOffers);
	public readonly editedOffers = computed(this._offersSvc.editedOffers);

	public get company(): Company {
		return this.request.exporters![0];
	}

	public readonly isImporting = signal<boolean>(false);
	public readonly user = computed<User | null>(this._accountSvc.user);

	// refacto: use enum for status, not integer values
	public readonly statusLabel: MHPButton<OfferStatusEnum>[] = [
		{ text: 'Pending', value: OfferStatusEnum.pending, ping: false },
		{ text: 'Under review', value: OfferStatusEnum.review, ping: false },
		{ text: 'Confirmed', value: OfferStatusEnum.booked, ping: false },
		{ text: 'In shipping', value: OfferStatusEnum.ordered, ping: false },
	];

	// refacto: rename filter to selectedStatusButton
	public selectedStatus: OfferStatusEnum = OfferStatusEnum.pending;
	public requestPreFillConfirmModal: boolean = false;
	public sendOfferConfirmModal: boolean = false;

	constructor() {
		/// Listen for prefill confirmation, and if confirmed, prefill offers with stock
		effect(
			async () => {
				if (this.prefillConfirmation() === PreFillModalResponse.Confirmed) {
					this.prefillConfirmation.set(PreFillModalResponse.None), 100;
					await this.prefillOffersWithStock();
				}
			},
			{ allowSignalWrites: true }
		);
	}

	/**
	 * Init the request and subscribe to the request changes
	 */
	ngOnInit() {
		// Subscribe to the route parameter map
		this._activatedRoute.paramMap
			.pipe(
				map(paramMap => {
					this.updateSelectedStatusFromParams(paramMap);
					return paramMap.get('id');
				}),
				filter(id => id !== null),
				switchMap(id => this.loadAndSubscribeToRequest(id!))
			)
			.subscribe(); // Ensuring the subscription occurs
	}

	/**
	 * Update the selected status based on the route parameter
	 * @param paramMap The route parameter map
	 */
	private updateSelectedStatusFromParams(paramMap: ParamMap): void {
		const tab = paramMap.get('tab');
		if (tab) {
			this.selectedStatus = OfferStatusEnum[tab as keyof typeof OfferStatusEnum];
			this.setPing(this.selectedStatus, false);
		} else {
			// open the last tab with offers (except shipped)
			this.selectedStatus =
				this.confirmedOffers().length > 0
					? OfferStatusEnum.booked
					: this.inreviewOffers().length > 0
					? OfferStatusEnum.review
					: OfferStatusEnum.pending;
		}
	}

	/**
	 * Load request by id and load corresponding packages
	 * @param id the id of the request to load
	 */
	private loadAndSubscribeToRequest(id: string): Observable<void> {
		// Load the request and subscribe to its changes
		return from(this._requestService.getRequestAndSubscribeToChanges(id)).pipe(
			map(requestEventDispatcher => {
				this.subscribeToNewOfferEvents(requestEventDispatcher);
				return requestEventDispatcher.request;
			}),
			filter((request: ImporterRequest) => !!request),
			tap((request: ImporterRequest) => {
				this.sortRequestItems(request);
				this.request = request;
			}),
			switchMap(request => from(this._offersSvc.initRequest(request)))
		);
	}

	/**
	 * Subscribe to new offer events
	 * @param requestEventDispatcher The dispatcher to subscribe to
	 */
	private subscribeToNewOfferEvents(requestEventDispatcher: RequestEventDispatcher): void {
		requestEventDispatcher.newOfferEvent$.pipe(takeUntil(this.onDestroy$)).subscribe(async offer => {
			this.setNotificationBadgeOnButton(offer, true);

			// If the offer is valid, ask for confirmation
			this._offersSvc.onOfferUpdatedOrAdded(offer);
		});
	}

	/**
	 * Select the tab corresponding of the updated offer
	 */
	private setNotificationBadgeOnButton(offer: Offer, value: boolean): void {
		if (OfferExtension.isInPending(offer, this.userFunction)) this.setPing(OfferStatusEnum.pending, value);
		else if (OfferExtension.isInReview(offer, this.userFunction)) this.setPing(OfferStatusEnum.review, value);
		else if (OfferExtension.isInConfirmed(offer)) this.setPing(OfferStatusEnum.booked, value);
		else if (OfferExtension.isInShipping(offer)) this.setPing(OfferStatusEnum.ordered, value);
		else {
			throw new Error('Offer status not handled');
		}
	}

	/**
	 * Add notification on the button
	 */
	private setPing(status: OfferStatusEnum, value: boolean): void {
		const button = this.statusLabel.find(s => s.value === status);
		if (!button) throw Error(`Button ${status} not found`);
		button.ping = value;
	}

	/**
	 * Sort the items in a request by package name
	 * @param request The request whose items need sorting
	 */
	private sortRequestItems(request: ImporterRequest): void {
		request.items = request.items.sort((a: ImporterRequestItem, b: ImporterRequestItem) =>
			a.package.shortName!.localeCompare(b.package.shortName!)
		);
	}

	private onDestroy$ = new Subject<void>();

	/**
	 * Select the tab corresponding of the updated offer
	 */
	private goToTabOffer(offer: Offer, value: boolean): void {
		if (OfferExtension.isInPending(offer, this.userFunction)) this.gotoTab(OfferStatusEnum.pending, value);
		else if (OfferExtension.isInReview(offer, this.userFunction)) this.gotoTab(OfferStatusEnum.review, value);
		else if (OfferExtension.isInConfirmed(offer)) this.gotoTab(OfferStatusEnum.booked, value);
		else if (OfferExtension.isInShipping(offer)) this.gotoTab(OfferStatusEnum.ordered, value);
		else {
			throw new Error('Offer status not handled');
		}
	}

	/**
	 * Go to the tab corresponding to the offer status
	 * @param offerStatus the tab
	 * @param useRouter use the router to navigate
	 * @returns true if the tab has changed
	 */
	public gotoTab(offerStatus: OfferStatusEnum | undefined, useRouter = false): boolean {
		if (offerStatus == undefined || this.selectedStatus == offerStatus) return false;

		if (useRouter) this._router.navigate(['../' + OfferStatusEnum[offerStatus]], { relativeTo: this._activatedRoute });
		else this.selectedStatus = offerStatus;

		return true;
	}

	/**
	 * Close the prefill confirmation modal and swtich to review tab
	 */
	public onOffersSent(): void {
		this.sendOfferConfirmModal = false;
		this._offersSvc.sendValidNewOffers();
	}

	/**
	 * Prefill offers with stock
	 */
	public async prefillOffersWithStock(): Promise<void> {
		try {
			this.importingItems.set(0);
			this.isImporting.set(true);

			await this._offersSvc.prefillOffersWithStock(
				this.request.items!,
				(l: number) => this.totalItems.set(l),
				() => this.importingItems.set(this.importingItems() + 1)
			);
		} finally {
			this.isImporting.set(false);
		}
	}

	/**
	 * Export the request
	 */
	public async orderOffers() {
		await this._offersSvc.orderOffers();
	}

	/**
	 * Edit the offer's batches
	 */
	public async editBatches() {
		this._offersSvc.editOffers();
	}

	/**
	 * Validate offer's batches
	 */
	public async validateEditedOffers() {
		await this._offersSvc.validateEditedOffers();
	}

	/**
	 * Cancel edited batches
	 */
	public cancelEdit() {
		this._offersSvc.cancelEditingOffers();
	}

	/**
	 * Unsubscribe to the request changes
	 */
	ngOnDestroy() {
		if (this.request?.id) this._requestService.unsubscribeToRequestChanges(this.request.id);
		this.onDestroy$.next();
		this.onDestroy$.complete();
	}
}
