import { Component, inject, signal } from '@angular/core';
import { MhpButtonComponent } from "../../_common/components/mhp-button/mhp-button.component";
import { MhpFileUploaderComponent } from "../../_common/components/mhp-file-uploader/mhp-file-uploader.component";
import { MhpPackageComponent } from "../../_common/components/mhp-package/mhp-package.component";
import { NgIcon, provideIcons } from "@ng-icons/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ApiExporterService } from "../../_common/services/api/api-exporter/api-exporter.service";
import { Router, RouterLink } from "@angular/router";
import { TableHandlerService } from "../../_common/services/table-handler/table-handler.service";
import { ExporterStockEntry } from "../../_common/models/exporter-stock-entry.model";
import { SITEMAP } from "../../_common/sitemap";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { ExporterQuotaEntry } from "../../_common/models/exporter-quota-entry.model";
import { saxTrashOutline } from "@ng-icons/iconsax/outline";
import { ApiPackageService } from "../../_common/services/api/api-package/api-package.service";

interface UpdateQuotaProps {
	// TODO: Change step type from number to dedicated enum.
	step: number
}

@Component({
  selector: 'app-update-quota',
  standalone: true,
	imports: [
		MhpButtonComponent,
		MhpFileUploaderComponent,
		MhpPackageComponent,
		NgIcon,
		ReactiveFormsModule,
		FormsModule,
		RouterLink
	],
	providers: [
		provideIcons({
			saxTrashOutline,
		})
	],
  templateUrl: './update-quota.component.html',
	styleUrls: [
		'./update-quota.component.scss',
		'../update-stock-quota.component.scss'
	]
})
export class UpdateQuotaComponent {
	private readonly _apiExporterSvc = inject(ApiExporterService);
	private readonly _apiPackageSvc = inject(ApiPackageService);
	private readonly _router = inject(Router);

	protected readonly _tableSvc = new TableHandlerService<ExporterQuotaEntry, UpdateQuotaProps>('update-quota', (row) =>
		<ExporterQuotaEntry>{
			cip13: +row['CIP 13'],
			name: row['Product name'],
			quota: +row['Quota']
		});

	protected readonly sitemap = SITEMAP;

	protected readonly props = this._tableSvc.props;
	protected readonly entries = this._tableSvc.items;
	protected readonly validCip13s = signal<number[]>([]);

	protected readonly file = signal<File | null>(null);

	constructor() {
		dayjs.extend(customParseFormat);
	}

	/**
	 * Sets the current step to the provided value.
	 * @param step The value to set as step
	 */
	public setStep(step: number): void {
		this.props.update((props) => {
			if (!props) {
				props = <UpdateQuotaProps>{ };
			}

			props.step = step;
			return props;
		});
	}

	/**
	 * Moves to the next step.
	 * @remarks Shorthand for `setStep(step + 1)`.
	 */
	public nextStep(): void {
		if (this.props().step === 4) return;
		this.setStep((this.props().step || 0) + 1);
	}

	/**
	 * Moves to the previous step.
	 * @remarks Shorthand for `setStep(step - 1)`.
	 */
	public previousStep(): void {
		if (this.props().step === 0) return;
		this.setStep((this.props().step || 0) - 1);
	}

	/**
	 * Processes a file when uploaded.
	 * @param file The file to process
	 * @protected
	 */
	protected processFile(file: Blob | null) {
		if (file) {
			this._tableSvc.processFile(file, async () => {
				this.nextStep();
				this.validCip13s.set((await this._apiPackageSvc.validateCip13s(this.entries()
					.map(entry => entry.cip13)
					.filter(cip13 =>!!cip13)
				)).body ?? []);
			});
		}
	}

	/**
	 * Checks if the current cell is valid or not.
	 * @param entry The line to check
	 * @param index The index of the line
	 */
	public checkErrorCell(entry: ExporterQuotaEntry, index: number): boolean {
		const entries = [...this.entries()];

		// TODO: Add validation here

		return entries.splice(index, 1).includes(entry);
	}

	/**
	 * Deletes the item at the provided index.
	 * @param index The index of the item to delete
	 */
	public deleteItem(index: number): void {
		this.entries().splice(index, 1);
	}

	/**
	 * Submits the stock quotas to the API.
	 */
	public async submit() {
		const entries = this.entries();

		if (entries.length) {
			const res = await this._apiExporterSvc.putStockQuotas(entries);

			if (res.ok) {
				await this._router.navigate([this.sitemap.stockOffer.route]);
			}
		}
	}
}
