import {
	Component,
	ComponentFactoryResolver,
	ComponentRef,
	inject,
	OnInit,
	Type,
	ViewContainerRef,
} from '@angular/core';
import { RouterModule } from '@angular/router';

import { AppInsightsService } from './_common/services/app-insights/app-insights.service';
import { ThemeColorService } from './_common/services/theme-color/theme-color.service';
import { MhpLoaderComponent } from './_common/components/mhp-loader/mhp-loader.component';
import { ComponentInjectorService } from './_common/services/component-injector/component-injector.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingInterceptor } from './_common/interceptors/loading.interceptor';

@Component({
	selector: 'app-root',
	standalone: true,
	imports: [RouterModule],
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
	private readonly _appInsightsSvc = inject(AppInsightsService);
	private readonly _themeColorSvc = inject(ThemeColorService);
	private readonly _viewContainerRef = inject(ViewContainerRef);

	ngOnInit(): void {
		const componentRef = this._viewContainerRef.createComponent(MhpLoaderComponent);
		this.injectInternalLoaderComponent(componentRef);
	}

	/**
	 * Inject the component InternalLoaderComponent in the parent
	 * @param componentRef
	 */
	injectInternalLoaderComponent(componentRef: ComponentRef<unknown>): void {
		const injector = componentRef.injector;
		const componentInjector = injector.get(ComponentInjectorService as Type<ComponentInjectorService>);
		componentInjector.appendComponent(MhpLoaderComponent);
	}
}
