import { DatePipe } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';

import { ChatMessage } from 'src/app/_common/models/chat-message.model';
import { User } from 'src/app/_common/models/user.model';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { CompanyService } from 'src/app/_common/services/company/company.service';

@Component({
	selector: 'app-main-request-offer-history-discussion-message',
	standalone: true,
	imports: [DatePipe],
	templateUrl: './main-request-offer-history-discussion-message.component.html',
	styleUrl: './main-request-offer-history-discussion-message.component.scss',
})
export class MainRequestOfferHistoryDiscussionMessageComponent {
	private readonly _companySvc = inject(CompanyService);
	private readonly _accountSvc = inject(AccountService);

	public readonly user = computed<User | null>(this._accountSvc.user);

	public readonly message = input.required<ChatMessage>();
	public readonly isCompanySender = computed<boolean>(
		() => this.message().authorCompanyId === this._companySvc.currentCompany()!.id
	);
	public readonly isAccountSender = computed<boolean>(() => this.message().authorId === this.user()!.id);
}
