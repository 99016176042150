@if (this.user(); as user) {
  <div class="mhp-stock-offer">
    <div class="flex flex-col w-full mb-6">
      <div class="flex flex-row">
        <h2 class="w-full text-4xl font-semibold text-gray-900 dark:text-white">My product catalog</h2>
        <div class="inline-flex flex-row text-right shrink-0 space-x-4">
          <app-mhp-button prefix="iconoirRefreshDouble" text="Update stock" type="tertiary" [routerLink]="this.sitemap.updateStockQuota.route" />
          <app-mhp-button prefix="iconoirPlus" text="Create a stock offer" type="tertiary" [routerLink]="this.sitemap.newStockOffer.route" />
          <!-- <app-mhp-button prefix="saxEyeOutline" text="See as importer" type="tertiary" /> -->
        </div>
      </div>
    </div>

    <div class="flex flex-row pt-8 pb-6">
      <div class="flex flex-row items-center w-full">
        <app-mhp-tabs
          [tabs]="this.tabs"
          [(choice)]="this.tabSelected"
        />
      </div>
    </div>

    <!--

    Hidden: https://github.com/MedHubCompany/MedHubPlace/issues/136

    <div class="flex flex-row items-center space-x-4 mb-6">
      <app-mhp-button *ngIf="stocksVisible" suffix="saxArrowDown1Outline" text="Unit cost" type="tertiary" />
      <app-mhp-button *ngIf="stocksVisible" suffix="saxArrowDown1Outline" text="Stock" type="tertiary" />
      <app-mhp-button *ngIf="quotasVisible" suffix="saxArrowDown1Outline" text="Quota" type="tertiary" />
      <app-mhp-button *ngIf="stocksVisible" suffix="saxArrowDown1Outline" text="Expiration date" type="tertiary" />
      <app-mhp-button *ngIf="stocksVisible" suffix="saxArrowDown1Outline" text="Sections" type="tertiary" />
    </div> -->

    @if (this.mergedStockItems().length) {
      <div class="relative overflow-x-auto border border-gray-200 dark:border-gray-700 rounded-2xl">
        <table class="w-full text-sm text-left rtl:text-left text-gray-900 bg-white dark:bg-gray-800 dark:text-white">
          <thead class="text-xs text-gray-500">
            <tr>
              <th class="py-4 pl-4 font-medium">Product</th>
              <th *ngIf="quotasVisible()" class="py-4 pl-4 font-medium">Quota</th>
              <th *ngIf="stocksVisible()" class="py-4 pl-4 font-medium">Batch</th>
              <th *ngIf="stocksVisible()" class="py-4 pl-4 font-medium min-w-28">Expiration date</th>
              <th *ngIf="stocksVisible()" class="py-4 pl-4 font-medium">Stock</th>
              <th *ngIf="quotasVisible()" class="py-4 pl-4 font-medium">Ordered</th>
              <th *ngIf="stocksVisible()" class="py-4 pl-4 font-medium min-w-20">Unit cost</th>
              <!-- <th class="py-4 pl-4 font-medium">
                <div class="flex flex-row items-center">
                  <span class="mr-1">Appears in the sections</span>
                  <ng-icon name="saxInfoCircleOutline" size=".9rem" />
                </div>
              </th> -->
              <th class="p-4 font-medium"></th>
            </tr>
          </thead>
          <tbody class="font-medium">
            @for (item of this.viewItems(); track $index) {
              <tr>
                <td class="p-4 inline-flex flex-row items-center space-x-4 w-max max-w-[30rem] flex-shrink">
									<app-mhp-package [package]="{ cip13: item.packageCip13, shortName: item.packageShortName ?? null }" />
                </td>
                <td *ngIf="quotasVisible()">{{ item.quota }}</td>
                <td *ngIf="stocksVisible()">{{ item.batch?.id }}</td>
                <td *ngIf="stocksVisible()">{{ item.batch?.expiration | date : 'd MMM y' : '' : "en-US" }}</td>
                <td *ngIf="stocksVisible()">{{ item.quantity }}</td>
                <td *ngIf="quotasVisible()"></td>
                <td *ngIf="stocksVisible()">{{ item.price | currency : 'EUR' : 'symbol' : '.0-2' }}</td>
                <!-- <td class="py-4 pl-4 font-medium">
                  <div class="flex flex-row space-x-2">
                    <span *ngFor="let section of item.sections" class="text-xs bg-gray-200 dark:bg-gray-700 dark:text-gray-500 rounded-full px-2 py-1">
                      {{ section }}
                    </span>
                  </div>
                </td> -->
                <td class="p-4">
									<!-- Empty span prevents hitting the :empty selector (which displays dash) -->
									<span></span>

									<!-- Hidden: https://github.com/MedHubCompany/MedHubPlace/issues/136 -->
                  <!-- <div class="flex flex-row space-x-4">
                    <button>
                      <ng-icon name="bootstrapPencilSquare" />
                    </button>
                    <button>
                      <ng-icon name="saxTrashOutline" />
                    </button>
                  </div> -->

                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    }

    @else {
      <div class="flex flex-col justify-center items-center h-[50vh]">
        <app-mhp-svg-icon icon="empty-product" />
        <p class="mt-6 text-gray-500">You haven't imported any products in your catalog yet</p>

        @if ([
          this.userFunctions.Exporter,
          this.userFunctions.ImporterExporter
        ].includes(user.function)) {
          <app-mhp-button
            class="mt-8"
            prefix="saxAddOutline"
            text="Import my stock"
            type="primary"
            [routerLink]="[ this.sitemap.updateStockQuota.route ]"
          />

					<ul class="flex flew-row gap-8 list-none">
						<a class="mt-6 font-medium text-blue-600 hover:text-blue-700" href="/assets/download/update-stock.template.csv" download="stock.csv">Download our stock template</a>
						<a class="mt-6 font-medium text-blue-600 hover:text-blue-700" href="/assets/download/update-quota.template.csv" download="quota.csv">Download our quota template</a>
					</ul>
        }
      </div>
    }

  </div>
}
