import { effect, signal } from '@angular/core';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import csv from 'csvtojson';

/**
 * Service for handling CSV
 * @export
 * @class TableHandlerService
 * @template TItem Type of the items in the table
 * @template TProps Type of the properties stored alongside table information
 */
export class TableHandlerService<TItem, TProps> {
	private readonly csvDataKey = 'csv-data';
	private readonly csvFileKey = 'csv-file';
	private readonly propsKey = 'props';

	public readonly acceptedFiles: string[] = ['.csv'];

	public isFileInputFilled: boolean = false;

	public static readonly dateParsingFormats = [
		'DD/MM/YYYY',
		'DD/MM/YY',
		'D/M/YYYY',
		'D/M/YY',
		'MM/YYYY',
		'MM/YY',
		'M/YYYY',
		'M/YY',
		'YYYY-MM-DD',
	];

	public readonly file = signal<Blob | null>(null);
	public readonly items = signal<TItem[]>([]);
	public readonly props = signal<TProps>(<TProps>{});

	public getTypedKey(key: string): string {
		return `${this.tableType}-${key}`;
	}

	/**
	 * Creates an instance of TableHandlerService.
	 * @param tableType Sets the table type to be manipulated by the service.
	 * @param itemsMapFunc Maps the CSV row to the desired item type.
	 */
	constructor(
		public readonly tableType: string,
		public readonly itemsMapFunc: (row: any) => TItem = row => row as TItem
	) {
		dayjs.extend(customParseFormat);

		effect(async () => {
			localStorage.setItem(this.getTypedKey(this.csvDataKey), JSON.stringify(this.items()));
			localStorage.setItem(this.getTypedKey(this.propsKey), JSON.stringify(this.props()));
			localStorage.setItem(this.getTypedKey(this.csvFileKey), (await this.file()?.text()) ?? '');
		});
	}

	public initFromLocalStorage(): void {
		if (localStorage.getItem(this.getTypedKey(this.csvDataKey))) {
			const i = JSON.parse(localStorage.getItem(this.getTypedKey(this.csvDataKey))!);
			console.log('Items: ', i);
			this.items.set(i);
		}

		if (localStorage.getItem(this.getTypedKey(this.propsKey))) {
			const p = JSON.parse(localStorage.getItem(this.getTypedKey(this.propsKey))!);
			console.log('Props: ', p);
			this.props.set(p);
		}

		if (localStorage.getItem(this.getTypedKey(this.csvFileKey))) {
			const f = localStorage.getItem(this.getTypedKey(this.csvFileKey));
			console.log('File: ', f);
			this.file.set(new Blob([f!]));
		}
	}

	public processFile(file: Blob, onLoad: () => void | Promise<void> = () => {}): void {
		if (file) {
			this.file.set(file);

			let reader: FileReader = new FileReader();
			reader.readAsText(file);

			reader.onload = async () => {
				const csvStr = reader.result as string;

				const csvRow = await csv({
					noheader: false,
					output: 'json',
				}).fromString(csvStr.replaceAll(',', '.').replaceAll(';', ','));

				this.items.set(csvRow.map(this.itemsMapFunc));
				await onLoad();
			};
		}
	}

	public clearCsvFile(): void {
		this.file.set(null);
		this.isFileInputFilled = false;
		this.items.set([]);

		localStorage.removeItem(this.csvDataKey);
		localStorage.removeItem(this.csvFileKey);
	}
}
